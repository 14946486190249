import '../../service/domain/user.js'
import '../../service/domain/account.js'
(function () {
  'use strict';

  controller.$inject = ['user', 'account'];

  function controller(_user, _account) {
    function link($scope, $element, $attrs, $ctrl, $transclude) {
      if (_user.profile.activated_by_email === false) {
        $transclude($scope, (clone, scope) => {
          scope.preloader = false;

          $element[0].parentNode.insertBefore(clone[0], $element[0].nextSibling);

          scope.resend = () => {
            scope.preloader = true;

            _account
              .activationCode({})
              .then(() => {})
              .catch(() => {})
              .finally(() => (scope.preloader = false));
          };
        });
      }
    }

    return {
      restrict: 'A',
      transclude: 'element',
      link,
    };
  }

  app.directive('mgaUnverifiedEmailWidget', controller);
})();
