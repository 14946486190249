(function () {
  'use strict';

  app.directive('worldCupVideo', controller);

  function controller() {
    function link($scope) {
      $scope.screenWidth = window.innerWidth;
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
