import '../../service/domain/page.js';
(function () {
  'use strict';

  const component = { name: 'lindaInfoPage' };

  controller.$inject = ['$scope', '$state', 'page', '$sce'];

  function controller($scope, $state, _page, $sce) {
    $scope.page = '';
    $scope.preloader = false;

    function getInfoPage(name) {
      $scope.preloader = true;
      _page
        .item({ name })
        .then((answer) => {
          $scope.page = $sce.trustAsHtml(answer.result.content);
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = function () {
      const name = this.name || $state.params.name || '';
      $scope.pageName = name;

      if (name !== '') {
        getInfoPage(name);
      }
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      name: '<',
    },
  });
})();
