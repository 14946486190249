import { element } from 'angular';

(function () {
  'use strict';

  const directive = { name: 'allHrefTargetSystem' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs) => {
      setTimeout(function () {
        let tapping = false;
        const elementAngular = angular.element(element);
        elementAngular.find('a[href]').on('touchstart', function (e) {
          tapping = true;
        });

        elementAngular.find('a[href]').on('touchmove', function (e) {
          tapping = false;
        });

        elementAngular.find('a[href]').on('touchend', function (e) {
          e.preventDefault();
          if (tapping) {
            const href = this.getAttribute('href');
            window.open(href, '_system');
          }
        });

        elementAngular.find('a[href]').on('click', function (e) {
          e.preventDefault();
        });
      }, 0);
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
