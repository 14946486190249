(function () {
  'use strict';

  controller.$inject = ['$window'];

  app.factory('lottie', controller);

  function controller($window) {
    return $window.lottie;
  }
})();
