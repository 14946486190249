import '../../../service/periodicals/holiday.js';
import '../../../service/domain/state.js';
(function () {
  controller.$inject = ['$scope', 'holiday', 'state', '$state', 'config'];

  function controller($scope, _holiday, _state, $state, _config) {
    const currentDate = _holiday.getCurrentDate();
    $scope.preloader = false;
    $scope.holiday = {};
    $scope.currentType = _config.projectType ?? 'casino';
    $scope.holidayStatus = '';
    $scope.bonusTypes = [];
    $scope.currentIndex = null;
    $scope.timer = null;
    $scope.selectedBonus = null;
    const classOnBody = 'is-easter';
    const classOnBodyIsAnim = 'is-anim';
    const dayInMs = 24 * 60 * 60 * 1000;
    const promoAlias = $state.params.name;
    // let promoAlias = 'world-cup';
    const subscription = _holiday.selectedBonus$.subscribe((bonus) => {
      $scope.selectedBonus = bonus;
    });

    this.$onInit = function () {
      $scope.preloader = true;
      document.body.classList.add(classOnBody);
      _holiday
        .getItem(promoAlias)
        .then((result) => {
          $scope.holiday = result;
          $scope.bonusTypes = Object.keys($scope.holiday.events);
          $scope.selectedBonus = result.takenTodayBonus;
          $scope.holidayStatus = setHolidayStatus();
          setDataIfTodayBonusExists();
        })
        .catch(() => {
          // _state.goto('home');
        })
        .finally(() => {
          $scope.preloader = false;
        });
    };

    //todo fix it, can't be reusable
    this.$onDestroy = function () {
      document.body.classList.remove(classOnBody, classOnBodyIsAnim);
      subscription.unsubscribe();
    };

    function setHolidayStatus() {
      const start = $scope.holiday.active_date;
      const end = $scope.holiday.last_day + dayInMs;
      if (currentDate < start) {
        return 'soon';
      }
      if (currentDate >= end) {
        return 'over';
      }
      return 'progress';
    }

    function setDataIfTodayBonusExists() {
      $scope.holiday.events[$scope.currentType].forEach((bonus, index) => {
        if (bonus.dayStatus !== 'today') {
          return;
        }
        $scope.currentIndex = index;
        $scope.timer = bonus.date + dayInMs - currentDate;
      });
    }

    $scope.setCurrentType = (type) => ($scope.currentType = type);
    $scope.setSelectedBonus = (bonus) => ($scope.selectedBonus = bonus);
    $scope.unsetSelectedBonus = () => ($scope.selectedBonus = null);
    $scope.takeBonus = (id, type) => {
      _holiday.saveTakenBonus(id, type);
      $scope.selectedBonus = $scope.holiday.events[type].toObject('id')[id];
      document.body.classList.add(classOnBodyIsAnim);
    };
  }

  new Controller('holidaysCalendar', controller, {});
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanHolidaysCalendar${i}`, 'holidaysCalendar');
  });
})();
