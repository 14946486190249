(function () {
  'use strict';

  const directive = { name: 'scrollTo' };

  controller.$inject = ['$timeout'];

  function controller($timeout) {
    const link = (scope, element, attrs) => {
      const padding = 10;

      const container = element[0];

      $timeout(() => {
        const child = container.querySelector(attrs[directive.name]);

        if (child) {
          const childOffsetLeft = child.offsetLeft;
          const containerOffsetLeft = container.offsetLeft;
          const containerScrollLeft = container.scrollLeft;

          const position = childOffsetLeft - containerOffsetLeft + containerScrollLeft - padding;
          container.scrollLeft = position;
        }
      }, 200);
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
