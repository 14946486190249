const componentName = 'lindaFooter';

class Footer {
  static $inject = ['$scope'];

  constructor($scope) {}
}

app.component(componentName, {
  controller: Footer,
  template: app.getTU(componentName),
});
