(function () {
  'use strict';

  controller.$inject = ['$state', '$stateParams'];

  function controller($state, $stateParams) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs.lindaUiSrefInclude);

      function toggleClass(c) {
        let s = c.state.replace(/ /g, '').split(',');

        if (s.filter((i) => $state.includes(i)).length) {
          element.addClass(c.class);
        } else {
          element.removeClass(c.class);
        }
      }

      toggleClass(config);

      scope.$on('$stateChangeSuccess', () => {
        toggleClass(config);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('lindaUiSrefInclude', controller);
})();
