import '../../core-templates/footer-default/footer-default.scss';

const componentName = 'footerDefault';

class Footer {
  static $inject = [];
  constructor($scope) {}
}

app.component(componentName, {
  controller: Footer,
  template: app.getTU(componentName),
});
