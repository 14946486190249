import '../service/domain/account.js';
import '../service/configs/config.js';
(function () {
  closeRegistrationTrackerController.$inject = ['account', '$scope', 'config', '$window'];

  function closeRegistrationTrackerController(account, scope, appConfig, globalWindow) {
    this.sendData = function () {
      const email =
        scope.closedRegistrationTracker && scope.closedRegistrationTracker.email
          ? scope.closedRegistrationTracker.email
          : '';
      if (appConfig.currentLang && email && /[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]{2,5}/.test(email)) {
        account.collectRegistrationAnalytics({
          email,
          language: appConfig.currentLang,
        });
      }
    };

    this.listenTabClose = function () {
      globalWindow.addEventListener('beforeunload', this.sendData);
    };

    this.destroyTabCloseListener = function () {
      globalWindow.removeEventListener('beforeunload', this.sendData);
    };
  }

  app.directive('closedRegistrationTracker', function () {
    return {
      restrict: 'A',
      scope: {
        closedRegistrationTracker: '<',
      },
      controller: closeRegistrationTrackerController,
      link: function (scope, element, attrs, controller) {
        controller.listenTabClose();
        element.on('click', controller.sendData);

        scope.$on('$destroy', () => {
          controller.destroyTabCloseListener();
          element.off('click', controller.sendData);
        });
      },
    };
  });
})();
