import '../../service/rx/system$.js';
import '../../service/domain/system.js';
import { filter } from 'rxjs/operators'

(function () {
  controller.$inject = ['system', 'system$', '$q', '$timeout'];

  function controller(_system, _system$, $q, $timeout) {
    return {
      version() {
        const defer = $q.defer();
        if (Object.keys(_system.systemInfo).length) {
          const version = getVersion(_system.systemInfo.platform_version);
          $timeout(() => {
            defer.resolve(version);
          });
          return defer.promise;
        }
        _system$
          .pipe(filter((message) => message.action === 'systemInfo'))
          .subscribe(({ data }) => {
            const version = getVersion(data.platform_version);
            defer.resolve(version);
          });

        function getVersion(version) {
          return version === '3' ? 3 : 2;
        }
        return defer.promise;
      },
    };
  }

  app.factory('platform', controller);
})();
