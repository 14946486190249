import '../service/analytics/analytics.js';
(function () {
  'use strict';

  // this directive used for watching all inputs in the form and send formData to backabd after blur event

  const directive = { name: 'bindAnalytics' };

  controller.$inject = ['analytics'];

  function controller(analytics) {
    function link(scope, element, attrs) {
      scope.track = analytics.send.bind(analytics);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
