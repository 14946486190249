(function () {
  'use strict';

  app.directive('embedSrc', controller);

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      let current = element;

      scope.$watch(
        () => attrs.embedSrc,
        () => {
          const clone = element.clone().attr('src', attrs.embedSrc);
          current.replaceWith(clone);
          current = clone;
        }
      );
    }

    return {
      restrict: 'A',
      link: link,
    };
  }
})();
