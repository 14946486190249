import '../service/domain/system.js';

const directiveName = 'getSystemInfoToScope';

class FeatureFlag {
  static $inject = ['system'];

  constructor(system) {
    this.system = system;
  }

  link(scope, element, attrs, ctrl) {
    this.system.getSystemInfo().then((info) => {
      scope.systemInfo = info;
    });
  }
}

app.directive(directiveName, () => {
  return {
    restrict: 'A',
    controller: FeatureFlag,
    link: function (scope, element, attrs, ctrl) {
      ctrl.link(scope, element, attrs, ctrl);
    },
  };
});
