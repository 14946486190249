(function () {
  'use strict';

  function removeHashFromURL() {
    return function () {
      const noHashURL = window.location.href.replace(/#.*$/, '');
      window.history.replaceState('', '', noHashURL);
    };
  }

  app.factory('removeHashFromURL', removeHashFromURL);
})();
