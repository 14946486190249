import '../../../service/trustly/paynplay.js';
import '../../../service/domain/modal.js';
import '../../../service/rx/trustly$.js';
import '../../../service/domain/state.js';
//add to linda-app
/*
          _modal.open( 'trustly-registration', {
              profile:{
                  name:'Ulpu',
                  surname:'Koskela',
                  city:'Mustasaari',
                  postcode:'65610',
                  address:'Karkkimalantie 82',
                  gender:'F',
                  birthday:'1970-09-20',
                  email:'',
                  phone:''
              }
          } );
  */

(function () {
  'use strict';

  const directive = { name: 'lindaTrustlyFrame' };
  const analyticsPlace = 'trustly';

  factory.$inject = ['paynplay', 'modal', 'trustly$', 'state'];
  function factory(_paynplay, _modal, _trustly$, _state) {
    const link = (scope, element, attrs) => {
      scope.preloader = true;
      element[0].src = '/preloader.html';

      let order_id,
        auth_request = false;

      function success() {
        _state.goto('home');
      }

      window.onmessage = (m) => {
        if (m.origin === 'https://checkout.trustly.com') {
          const data = JSON.parse(m.data);

          if (data.method === 'OPEN_APP') {
            location.assign(data.appURL);
          }
        }

        switch (m.data) {
          case 'preloader:success':
            if (!auth_request) {
              auth_request = true;
              _paynplay
                .auth({ order_id })
                .then((a) => {
                  success();
                  /* The very first successful response (after iframe was closed and 'success' event was sent)
                   * means that the user was identified and has been registered already.
                   * */
                })
                .catch((e) => {
                  if (e.messages[0].code === 5054) {
                    _modal
                      .open('trustly-registration', {
                        profile: e.messages[0].data.profile,
                        is_registration_bonuses_shown: e.messages[0].data.is_registration_bonuses_shown,
                        token: order_id,
                      })
                      .then(() => {
                        success();
                      })
                      .catch(() => {})
                      .finally(() => {});
                  }
                })
                .finally(() => {
                  window.onmessage = null;
                });
            }
            break;

          case 'preloader:fail':
            _trustly$.next({ action: 'step', data: 0 });
            break;

          default:
            break;
        }
      };

      _paynplay
        .init()
        .then((a) => {
          order_id = a.result.order_id;
          element[0].sandbox = 'allow-forms allow-scripts allow-same-origin allow-popups';
          element[0].src = a.result.url;
        })
        .catch((e) => {})
        .finally(() => {
          scope.preloader = false;
        });
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
