import { fromEvent } from 'rxjs'

(function () {
  'use strict';

  const directive = { name: 'toggleClassOnScroll' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs[directive.name]),
        el = document.querySelector(config.selector) || element[0],
        top = config.top || 0,
        left = config.left || 0,
        target = document.querySelector(config.wrapperSelector) || window,
        scrollStream = fromEvent(target, 'scroll'),
        subscribtion = scrollStream.subscribe(() => {
          let wrapperOffset;
          if (left) {
            wrapperOffset = target.scrollLeft || target.pageXOffset;
            el.classList.toggle(config.class, wrapperOffset > left);
          } else if (top) {
            wrapperOffset = target.scrollTop || target.pageYOffset;
            el.classList.toggle(config.class, wrapperOffset > top);
          }
        });

      scope.$on('$destroy', () => {
        subscribtion.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
