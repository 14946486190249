import '../../service/domain/modal.js';
import '../../service/domain/game.js';
(function () {
  'use strict';

  const directive = { name: 'demoOpen' };

  controller.$inject = ['modal', 'game'];

  function controller(_modal, _game) {
    const link = (scope, element, attrs) => {
      let tapping = false;
      let what = attrs[directive.name];

      const doIt = () => {
        if (what === 'modal') {
          _modal.open('error', { code: 2205 });
        }
        if (what === 'notification') {
          _game.launch({ code: 'street_magic', mode: 'real' }).then(
            (answer) => {},
            (answer) => {}
          );
        }
      };

      element.bind('touchstart', function (e) {
        tapping = true;
      });

      element.bind('touchmove', function (e) {
        tapping = false;
      });

      element.bind('touchend', function (e) {
        if (tapping) {
          doIt();
        }
      });
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
