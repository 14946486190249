(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    const opt = { min: 9 };

    return {
      customValidators(scope, element, attrs, ngModel) {
        ngModel.$validators.min = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? value.length >= opt.min : false;
        };

        ngModel.$validators.number = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? value.match(new RegExp('[\\d]+')) : false;
        };

        ngModel.$validators.up = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? value.match(new RegExp('[A-Z]+')) : false;
        };

        ngModel.$validators.low = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? value.match(new RegExp('[a-z]+')) : false;
        };
      },
    };
  }

  app.factory('password', controller);
})();
