(function () {
  const directive = { name: 'balanceHistoryDateFormatter' };

  function controller() {
    function link(scope, element, attrs, ctrl) {
      ctrl.$parsers.push((value) => {
        return value.toISOString();
      });

      ctrl.$formatters.push((value) => {
        if (value) {
          const result = new Date(value);
          return result;
        }
        return null;
      });
    }

    return {
      require: 'ngModel',
      link,
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, controller);
})();
