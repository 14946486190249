(function () {
  'use strict';

  controller.$inject = ['$rootScope'];

  function controller($rootScope) {
    const $stateChangeSuccess = '$stateChangeSuccess';
    const $stateChangeStart = '$stateChangeStart';

    const scopeEvents = {
      [$stateChangeSuccess]: new Event($stateChangeSuccess),
      [$stateChangeStart]: new Event($stateChangeStart),
    };

    const create = (event, target) => {
      if (Object.keys(scopeEvents).includes(event)) {
        $rootScope.$on(event, () => {
          target.dispatchEvent(scopeEvents[event]);
        });
      }
    };

    return { create };
  }

  app.service('event', controller);
})();
