import '../../../service/domain/bonus.js';
import '../../../service/domain/payment.js';
import '../../../service/helper.js';
(function () {
  'use strict';

  const component = { name: 'lindaProfileBonusList' };

  controller.$inject = ['$scope', 'bonus', 'payment', 'helper'];

  function controller($scope, _bonus, _payment, _helper) {
    $scope.collection = [];

    $scope.formData = {
      code: '',
    };

    function listBonus() {
      $scope.preloader = true;
      _bonus
        .list()
        .then(({ result }) => {
          $scope.collection = result;
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    listBonus();

    $scope.submit = _helper.form.validator(() => {
      $scope.preloader = true;
      const o = {
        code: $scope.formData.code,
      };
      _payment.promoCode(o).then(
        (answer) => {
          listBonus();
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    });

    $scope.$on('renew_bonus_list', listBonus);
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
