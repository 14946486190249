(function () {
  'use strict';

  const directive = { name: 'titleSwitcher' };

  controller.$inject = ['$translate'];

  function controller($translate) {
    async function link(scope, element, attrs) {
      scope.interval = null;

      const title = document.querySelector('title');
      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          originalTitle = originalTitle || document.title;
          observer.disconnect();
        });
      });
      const config = {
        childList: true,
      };
      const {
        delimeter = '/',
        inactiveTitleKey = '',
        fallbackTitle = '',
        loop = false,
        delay = 500,
      } = scope.$eval(attrs[directive.name]) || {};
      const changeTitle = () => {
        if (document.hidden && inactiveTitle) {
          if (loop) {
            const inactiveTitleArr = inactiveTitle.split(delimeter);

            let index = 0;

            scope.interval = setInterval(() => {
              document.title = inactiveTitleArr[index];
              if (++index === inactiveTitleArr.length) {
                index = 0;
              }
            }, 300);
          } else {
            document.title = inactiveTitle;
          }
        } else {
          clearInterval(scope.interval);
          document.title = originalTitle;
        }
      };

      const changeCodesToEmoji = (str) => {
        const emojiDict = {
          ':face_with_cowboy_hat:': '🤠',
          ':bank:': '🏦',
          ':firecracker:': '🧨',
          ':moneybag:': '💰',
          ':slot_machine:': '🎰',
          ':rocket:': '🚀',
          ':soccer:️': '⚽',
          ':softball:': '🥎',
        };

        for (let e in emojiDict) {
          str = str.replaceAll(e, emojiDict[e]);
        }

        return str;
      };

      let originalTitle = document.querySelector('title').innerText || document.title;
      let inactiveTitle = changeCodesToEmoji(
        inactiveTitleKey ? await $translate(inactiveTitleKey) : fallbackTitle || originalTitle
      );

      document.addEventListener('visibilitychange', changeTitle);
      observer.observe(title, config);

      scope.$on('$destroy', () => {
        document.removeEventListener('visibilitychange', changeTitle);
        observer?.disconnect();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
