(function () {
  'use strict';

  app.directive('alfPaymentSliderWidget', [
    '$timeout',
    ($timeout) => {
      function link(scope, elem, attrs) {
        scope.alfPaymentSliderWidget = {
          range: scope.system.rules.range,

          default: scope.system.rules.default,

          sliderToSum(value) {
            const self = this;
            let sum;
            if (value <= 10) {
              sum = ((self.range[1] - self.range[0]) / 100) * ((100 / 10) * (value - 0)) + self.range[0];
            } else if (value <= 30) {
              sum = ((self.range[2] - self.range[1]) / 100) * ((100 / 20) * (value - 10)) + self.range[1];
            } else if (value <= 50) {
              sum = ((self.range[3] - self.range[2]) / 100) * ((100 / 20) * (value - 30)) + self.range[2];
            } else if (value <= 70) {
              sum = ((self.range[4] - self.range[3]) / 100) * ((100 / 20) * (value - 50)) + self.range[3];
            } else if (value <= 90) {
              sum = ((self.range[5] - self.range[4]) / 100) * ((100 / 20) * (value - 70)) + self.range[4];
            } else {
              sum = ((self.range[6] - self.range[5]) / 100) * ((100 / 10) * (value - 90)) + self.range[5];
            }
            return Math.round(sum);
          },

          sumToSlider(sum) {
            let sliderPosition;
            const self = this;
            self.range.some((item, index) => {
              if (sum <= item) {
                switch (index) {
                  case 1:
                    sliderPosition = (10 / 100) * ((100 / (self.range[1] - self.range[0])) * (sum - self.range[0])) + 0;
                    break;
                  case 2:
                    sliderPosition =
                      (20 / 100) * ((100 / (self.range[2] - self.range[1])) * (sum - self.range[1])) + 10;
                    break;
                  case 3:
                    sliderPosition =
                      (20 / 100) * ((100 / (self.range[3] - self.range[2])) * (sum - self.range[2])) + 30;
                    break;
                  case 4:
                    sliderPosition =
                      (20 / 100) * ((100 / (self.range[4] - self.range[3])) * (sum - self.range[3])) + 50;
                    break;
                  case 5:
                    sliderPosition =
                      (20 / 100) * ((100 / (self.range[5] - self.range[4])) * (sum - self.range[4])) + 70;
                    break;
                  case 6:
                    sliderPosition =
                      (10 / 100) * ((100 / (self.range[6] - self.range[5])) * (sum - self.range[5])) + 90;
                    break;
                  default:
                    sliderPosition = 50;
                    break;
                }
                return true;
              }
            });
            return sliderPosition;
          },

          renewSliderPosition(sum) {
            const self = this;
            const newPosition = self.sumToSlider(sum);
            $(elem).slider('value', newPosition);
          },
        };

        const sliderPosition = scope.alfPaymentSliderWidget.sumToSlider(scope.alfPaymentSliderWidget.default);

        scope.formData.amount = scope.alfPaymentSliderWidget.sliderToSum(sliderPosition);

        let config = {
          animate: true,
          orientation: 'horizontal',
          range: 'min',
          max: 100,
          step: 0.1,
          value: sliderPosition,
          slide(e, ui) {
            $timeout(() => {
              scope.formData.amount = scope.alfPaymentSliderWidget.sliderToSum(ui.value);
            });
          },
        };

        $(elem).slider(config);
      }

      return {
        restrict: 'A',
        link,
      };
    },
  ]);
})();
