import '../../service/configs/config.js';
(function () {
  class HotjarHeatmap {
    /**
     * @param {Function} engine - heatmap engine
     * */
    constructor(engine) {
      this.engine = engine;
    }

    /** heatmap data collection
     * @param {string} event - an event that triggers heatmap collection
     * */
    trigger(event) {
      this.engine('trigger', event);
    }

    /** hotjar analytics
     * @param {string} type - type 'event' or 'identify'
     * @param {string|number} value - value string 'event value' or number 'id'
     * */
    sendEvent(type, value) {
      this.engine(type, value);
    }
  }

  heatmapController.$inject = ['config'];

  /** @returns {HotjarHeatmap} */
  function heatmapController(appConfig) {
    if (!(appConfig.hotjar && appConfig.hotjar.customHeatMapEnabled)) {
      return new HotjarHeatmap(() => {});
    }

    const engine = (...args) => {
      try {
        return hj(...args);
      } catch (e) {}
    };

    return new HotjarHeatmap(engine);
  }

  app.factory('heatmap', heatmapController);
})();
