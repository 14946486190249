import angular from 'angular';
import '../../../service/domain/payment.js';
import '../../../service/domain/user.js';
import '../../../service/configs/config.js';
import '../../../service/helper.js';
import '../../../service/rx/system$.js';
import '../../../service/domain/modal.js';
(function () {
  'use strict';

  function makeForm(path, params, el) {
    document.getElementById('depositForm')?.remove();

    const form = $document.createElement('form');

    form.setAttribute('method', 'POST');
    form.setAttribute('action', path);
    form.setAttribute('id', 'depositForm');
    form.setAttribute('target', 'depositWindow');

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = $document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);
        form.appendChild(hiddenField);
      }
    }

    el.append(form);
    form.submit();
  }

  controller.$inject = ['$scope', 'payment', '$element', 'user', 'config', 'helper', 'system$', 'modal', '$window', '$interval'];

  function controller($scope, _payment, $element, _user, _config, _helper, _system$, _modal, $window, $interval) {
    let wnd = {};

    $scope.preloader = false;
    $scope.system = {};
    $scope.formData = {
      amount: 0,
      paymethod: '',
      paysystem_id: '',
      place: '',
    };
    $scope.checkPendingWithdrawals = false;
    $scope.showCryptoPreloader = false;
    $scope.showMinMaxWarning = false;

    const isAmountInRange = (amount, minAmount, maxAmount) => {
      return minAmount <= amount && amount <= maxAmount;
    };

    this.$onChanges = () => {
      if (angular.isObject(this.system) && this.system.name) {
        this.system.rules.showAmount = !this.system.hide_amount;
        $scope.formData.amount = this.system.amount || this.system.rules.default;
        $scope.system = this.system;
        $scope.formData.place = this.place || 'cashbox';

        $scope.formData.paymethod = this.system.rules.system;
        $scope.formData.paysystem_id = this.system.id;

        if (this.quick) {
          $scope.formData.tryQuick = true;
        }
        if (this.amount && !isAmountInRange(this.amount, this.system.min_amount, this.system.max_amount)) {
          $scope.showMinMaxWarning = true;
        }
      }
    };

    function close(message) {
      if (message.data === 'close') {
        wnd.close();
        wnd = {};
        $scope.showCryptoPreloader = false;
      }

      if (message.data === 'open-chat') {
        wnd.close();
        wnd = {};
        _system$.next({ action: 'open chat' });
      }
    }

    $window.onmessage = close;

    function submitForm() {
      $scope.showCryptoPreloader = true;

      if (wnd.focus) {
        if (wnd.closed) {
          wnd = {};
          return;
        }
        wnd.focus();
      }

      const w = 800;
      const h = 500;
      const l = ($window.innerWidth - w) / 2;
      const t = ($window.innerHeight - h) / 2;
      const checker = $interval(() => {
        if (wnd.closed) {
          wnd = {};
          clearInterval(checker);
          $scope.showCryptoPreloader = false;
        }
      }, 100);

      wnd = _config.browser.webView
        ? $window
        : $window.open(
            `/preloader.html?login=${_user.profile.login}`,
            'depositWindow',
            `height=${h},width=${w},left=${l},top=${t}`,
          );

      $scope.preloader = true;
      _payment
        .deposit($scope.formData)
        .then((answer) => {
          $scope.preloader = false;
          if (answer.result.method.toLowerCase() === 'post') {
            makeForm(answer.result.url, answer.result.post, $element);
          } else {
            wnd.document.location = answer.result.url;
          }
        })
        .catch(() => {
          $scope.preloader = false;
          wnd.document.location = '/' + _config.currentLang + `/payment/fail?login=${_user.profile.login}`;
        })
        .finally(() => {});
    }

    $scope.submit = _helper.form.validator(() => {
      if ($scope.checkPendingWithdrawals) {
        _modal
          .open('cancel-pending-withdrawals')
          .then(() => {
            submitForm();
          })
          .catch(() => {})
          .finally(() => {});
        return;
      }
      submitForm();
    });
  }

  new Controller('cashboxDepositForm', controller, {
    system: '<',
    place: '<',
    quick: '<',
    amount: '<',
  });
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanCashboxDepositForm${i}`, 'cashboxDepositForm');
  });
})();
