import '../domain/historyBalanceFlow.js';

(function () {
  'use strict';

  app.factory('balanceHistoryDatepicker', controller);

  controller.$inject = ['historyBalanceFlow'];

  function controller(_historyBalanceFlow) {
    const todayDate = new Date().toISOString().split('T')[0];
    const checkIfSingleDay = () => !_historyBalanceFlow.maxDays;

    return {
      todayDate,
      checkIfSingleDay,
    };
  }
})();
