import '../../service/rx/ws$.js';
import '../../service/configs/config.js';
import '../../service/configs/ws-events.js';
import '../../service/configs/notifications-events.js';
import '../../service/domain/modal.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  class Message {
    constructor(config) {
      this.type = config.type || 'info';
      this.code = config.code || null;
      this.text = config.text || '';
      this.delay = config.delay || 5000;
      this.index = config.index;
      this.show = true;
      this.data = config.data || {};
      this.event = config.event || '';
    }
  }

  const component = { name: 'lindaNotification' };

  controller.$inject = [
    '$scope',
    'notification$',
    '$timeout',
    'ws$',
    'config',
    'wsEvents',
    'notificationsEvents',
    'modal',
  ];

  function controller($scope, _notification$, $timeout, _ws$, _config, _wsEvents, _notificationsEvents, _modal) {
    $scope.filter = {
      show: true,
    };
    $scope.close = remove;
    $scope.list = [];

    function remove(index) {
      $scope.list[index].show = false;
    }

    function say(config) {
      config.index = $scope.list.length;
      const notification = new Message(config);

      $timeout(() => {
        remove(config.index);
      }, notification.delay);

      $scope.list.splice(config.index, 0, notification);
    }

    /* ws = {
                    event: 'message',
                    data: [
                        { type: '', code: 0, text: '', data: {} },
                        { type: '', code: 0, text: '', data: {} },
                        { type: '', code: 0, text: '', data: {} },
                        ...
                        { type: '', code: 0, text: '', data: {} }
                    ]
                }

                message.type -> 'error' || 'warning' || 'info' || 'success' || 'silence'

     **/

    _ws$.pipe(filter(({ event }) => event === _wsEvents.message)).subscribe((message) => {
      $scope.$apply(() => {
        _notification$.next(message.data[0]);
      });
    });

    _ws$.pipe(filter(({ event }) => event === _wsEvents.achieveComplete)).subscribe((message) => {
      $scope.$apply(() => {
        _notification$.next(message);
      });
    });

    _notification$.subscribe((data) => {
      if (_notificationsEvents.hasOwnProperty(data?.code)) {
        const notificationCode = _notificationsEvents[data?.code];
        if (notificationCode.type === 'default' && notificationCode.callback) {
          notificationCode.callback(data);
          return;
        }
        if (notificationCode.type === 'error') {
          _modal
            .open('error', data)
            .then(() => {
              if (notificationCode.resolveCallback) {
                notificationCode.resolveCallback();
              }
            })
            .catch(() => {
              if (notificationCode.rejectCallback) {
                notificationCode.rejectCallback();
              }
            });
        }
        return;
      }
      if (data.type !== 'silence' && !_config.ignoreErrorCodes.includes(data.code)) {
        say(data);
      }
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
