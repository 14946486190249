import '../../service/domain/sport.js';
import '../../service/configs/config.js';
import '../../service/domain/state.js';
import '../../service/configs/altenar.js';
import '../../service/domain/system.js';
(function () {
  'use strict';

  const name = 'lindaSportWidgetV3';

  //- TODO change this shit code Altenar widget versioning const version
  const versionWhenAltenarRanamedSkinNameFieldToIntegration = 0.014; // if version 0.14, example: if 0.14.0332 paste 0.0140332
  // !!! AltenarWidget initialization REMOVE all classes and id from element
  //
  // Use example
  // linda-sport-widget-v3="{
  //      type: 'WTabbedEventsByType',                          https://sb2wsdk-altenar2-stage.biahosted.com/docs/index.html?path=/docs/widgets-wtabbedeventsbytype--live-now
  //      topEventsType: 'liveNow',                             - Type of displayed events: "liveNow", "highlights", "upcoming"
  //      eventCount: 3,                                        - Number of displayed events: number
  //      scrollerControls: 2,                                  - 0 - side arrows are displayed, 1 - side arrows are not displayed, 2 - side arrows are displayed automatically
  //      pathToLogosDirectory: 'https://url.com/directory'     - path to directory with sport event logos
  // }"
  controller.$inject = ['state', 'config', 'altenar.config', 'sport', '$state', 'system'];

  function controller(_state, _config, _altenar_config, _sport, $state, _system) {
    const currentLang = $state.params.lang;
    const altenarConfig = _config.altenar || {
      widget: {
        integration: 'sportaza',
      },
    };

    //- TODO change this shit code Altenar widget versioning
    const parseVersionFloat = (versionString) => {
      let versionArray = ('' + versionString).replace(/[^0-9.]/g, '').split('.'),
        sum = 0;

      for (let i = 0; i < versionArray.length; ++i) {
        sum += Number(versionArray[i]) / Math.pow(10, i * 3);
      }

      return sum;
    };

    const setIntegrationParam = () => {
      const defaultIntegrationParam = altenarConfig.widget?.integration;
      const countryCode = _system.systemInfo.country.code.toUpperCase();
      let localIntegrationParam = $_CDN?.sportConfig?.localizationIds?.[countryCode];
      return localIntegrationParam || defaultIntegrationParam;
    };

    const ident = setInterval(() => {
      if (window.AltenarWidgetScriptLoaded === true) {
        let objectInitialization = {};
        const { langReplacements } = window.$_DICT.sportWidget;
        const integration = setIntegrationParam();
        const countryCode = _system.systemInfo.country.code.toUpperCase();

        parseVersionFloat(window.altenarWSDKVersion) < versionWhenAltenarRanamedSkinNameFieldToIntegration
          ? (objectInitialization = { skinName: integration })
          : (objectInitialization = { integration });

        objectInitialization.culture = langReplacements[currentLang] || 'en-GB';
        if (countryCode === 'CA') {
          objectInitialization.oddsFormat = 2;
        }

        window.altenarWSDK.init(objectInitialization);
        clearInterval(ident);
      }
    }, 20);

    const link = (scope, element, attrs) => {
      let instance = {
        remove() {},
      };

      const widgetOptions = scope.$eval(attrs.lindaSportWidgetV3);

      let props = {};

      function onEventSelect(event) {
        _state.goto('sport', { sportids: event.sportId, champids: event.champId, eventid: event.id });
      }

      const getOddsFromStorage = () => {
        const items = JSON.parse(localStorage.getItem(`WSDK_american_betSelections`));
        return items.state.oddIds;
      };

      function onOddSelect(event) {
        window.AltenarBetslipOddIds = getOddsFromStorage();
        _sport.betslipUpdate$.next(window.AltenarBetslipOddIds);
        _state.goto('sport', { champids: event.champId, eventid: event.eventId });
      }

      switch (widgetOptions.type) {
        case 'WTopEventsCarousel':
          props = {
            eventCount: widgetOptions.eventCount || 7,
            scrollerControls: widgetOptions.scrollerControls ?? 2,
            sportId: widgetOptions.sportId || 0,
            onEventSelect,
            onOddSelect,
            variants: {
              OddBox: 2,
            },
            competitorLogoURL: widgetOptions.pathToLogosDirectory
              ? `${widgetOptions.pathToLogosDirectory}{0}.png`
              : `${_config.apiUrl}/dimg/altenar_logo/{0}.png`,
          };
          break;
        case 'WLiveStreaming':
          props = {
            onOddSelect,
          };
          break;
        case 'WBannerBetReservation':
          break;
        case 'WEventCard':
          break;
        case 'WTabbedEventsByType':
        default:
          props = {
            topEventsType: widgetOptions.topEventsType || 'liveNow',
            eventCount: widgetOptions.eventCount || 7,
            sportId: widgetOptions.sportId || 0,
            onEventSelect,
            onOddSelect,
            variants: {
              EventList: 1,
            },
          };

          break;
      }

      function buildConfig() {
        const config = {
          widget: widgetOptions.type || 'WTabbedEventsByType',
          props,
          container: element[0],
        };

        switch (widgetOptions.type) {
          case 'WTopEventsCarousel':
            if (_altenar_config.widget.carousel) {
              config.tokens = _altenar_config.widget.carousel;
            }
            break;
          case 'WLiveStreaming':
            if (_altenar_config.widget.streaming) {
              config.tokens = _altenar_config.widget.streaming;
            }
            break;
          default:
            if (_altenar_config.widget.tokens) {
              config.tokens = _altenar_config.widget.tokens[widgetOptions.topEventsType];
            }
        }

        return config;
      }

      const ident = setInterval(() => {
        if (window.AltenarWidgetScriptLoaded === true) {
          const config = buildConfig();
          instance = window.WTEC = window.altenarWSDK.addWidget(config);
          clearInterval(ident);
        }
      }, 20);

      scope.$on('$destroy', () => {
        if (instance) instance.remove();
      });
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
