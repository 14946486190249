(function () {
  'use strict';

  const directive = { name: 'bodyScrollLock' };

  controller.$inject = ['$timeout', '$state'];

  function controller($timeout, $state) {
    const link = (scope, element, attrs) => {
      const selectors = attrs[directive.name];

      lock();

      scope.$on('$stateChangeStart', function () {
        unlock();
      });

      scope.$on('$stateChangeSuccess', function () {
        lock();
      });

      function lock() {
        $timeout(() => {
          let targetList = document.querySelectorAll(selectors);
          for (let i = 0; i < targetList.length; i++) {
            bodyScrollLock.disableBodyScroll(targetList[i]);
          }
        }, 0);
      }

      function unlock() {
        bodyScrollLock.clearAllBodyScrollLocks();
      }
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
