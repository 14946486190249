import '../../../service/domain/account.js';
import '../../../service/domain/user.js';
import '../../../service/domain/popup.js';
// linda-selfcontrol-deposit-item

(function () {
  'use strict';

  const component = { name: 'lindaSelfcontrolEditForm' };

  controller.$inject = ['$scope', 'account', 'user', 'popup'];

  function controller($scope, _account, _user, _popup) {
    $scope.limit = {};
    $scope.type = $scope.$parent.type;

    $scope.editmode = false;
    $scope.preloader = false;
    $scope.formData = {
      type: '31',
      amount: 0,
    };

    $scope.cancel = () => {
      $scope.preloader = true;

      const o = {
        group: $scope.type,
        id: $scope.limit.id,
      };

      _account
        .removeLimit(o)
        .then((a) => {
          _user.profile = a.data.result;
          _popup.close();
        })
        .finally(() => ($scope.preloader = false));
    };

    $scope.$watch('editmode', (newVal, oldVal) => {
      if (newVal === false) {
        $scope.formData.type = `${this.limit.type}`;
        $scope.formData.amount = this.limit.amount;
      }
    });

    $scope.submit = (form) => {
      if (form.$valid) {
        $scope.preloader = true;

        const o = {
          group: $scope.type,
          id: $scope.limit.id,
          type: $scope.formData.type,
          amount: $scope.formData.amount,
        };

        _account
          .limits(o)
          .then(() => {
            $scope.editmode = false;
            _popup.close();
          })
          .catch(() => {
            $scope.formData.type = `${this.limit.type}`;
            $scope.formData.amount = this.limit.amount;
          })
          .finally(() => {
            $scope.preloader = false;
          });
      } else {
        angular.forEach(form.$error, (type) => {
          angular.forEach(type, (field) => {
            field.$setTouched();
          });
        });
      }
    };

    this.$onChanges = () => {
      $scope.limit = this.limit;
      $scope.formData.type = `${this.limit.type}`;
      $scope.formData.amount = this.limit.amount;
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      limit: '<',
    },
  });
})();
