import { SpinePlayer } from '@esotericsoftware/spine-player';

(function () {
  const directive = {
    name: 'spinePlayer',
  };

  const TRANSPARENT_COLOR = '#00000000';

  factory.$inject = [];

  function factory() {
    function link(scope, element, attrs) {
      const params = scope.$eval(attrs[directive.name]);
      const containerElement = element[0];
      const callabcks = {
        onComplete: () => {},
        onSuccess: () => {},
      };
      const config = {
        jsonUrl: params.jsonUrl,
        atlasUrl: params.atlasUrl,
        preserveDrawingBuffer: params?.preserveDrawingBuffer || true,
        showControls: params?.showControls || false,
        premultipliedAlpha: params?.premultipliedAlpha || false,
        backgroundColor: params?.backgroundColor || TRANSPARENT_COLOR,
        alpha: params?.alpha || true,
        showLoading: params?.showLoading || false,

        success: (player) => {
          const { onComplete, onSuccess } = callabcks;
          if (params?.animationName) {
            player.addAnimation(params.animationName, params?.loop);

            if (params?.skipFirstFrame && !params?.autoplay) {
              player.play();

              setTimeout(() => {
                player.pause();
              });
            }

            if (params?.autoplay) {
              player.play();
            }
          }

          if (onComplete && typeof onComplete === 'function') {
            player.animationState.addListener({ complete: onComplete });
          }

          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(player);
          }
        },
        error: () => {},
      };

      const player = new SpinePlayer(containerElement, config);

      scope.$on('$destroy', function () {
        player.dispose();
        player.stopRendering();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
