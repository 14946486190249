import { timer } from 'rxjs';

(function () {
  // usage: someTag(time-passed={{TIMESTAMP}}) {{timePassed}}
  // TIMESTAMP - timestamp, from which count up will go
  // timePassed - variable, where displayed time

  'use strict';

  const directive = {
    name: 'timePassed',
  };

  factory.$inject = [];

  function factory() {
    function link(scope, element, attrs) {
      let timeFrom = Number(attrs[directive.name]) || new Date().getTime();

      const timer$ = timer(0, 1000).subscribe((val) => {
        const now = new Date().getTime();
        const duration = now - timeFrom;
        const hours = Math.floor(duration / (1000 * 60 * 60));
        const minutes = Math.floor(duration / (1000 * 60) - hours * 60);
        const seconds = Math.floor(duration / 1000 - hours * 60 * 60 - minutes * 60);
        const result = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        scope[directive.name] = result;
        scope.$apply();
      });

      attrs.$observe(`${directive.name}`, (newVal) => {
        if (newVal) timeFrom = Number(attrs[directive.name]);
      });

      scope.$on('$destroy', () => {
        timer$.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
