(function () {
  'use strict';

  app.factory('bankid.config', controller);

  controller.$inject = [];

  function controller() {
    // bankid works only with sweden
    return {
      enabled: true,
      min: 100,
      max: 5000,
      default: 0,
      range: [1000, 2000, 5000],
      currency: 'SEK',
      locale: 'sv_SE',
      errorCodes: [2205, 2281],
    }
  }
})();
