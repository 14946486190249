import '../../service/rx/keyup$.js';
import '../../service/domain/content-buffer.js';
import '../../service/domain/modal.js';
import '../../service/analytics/analytics.js';
import '../../service/configs/analytic-events.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = [
    '$templateCache',
    'keyup$',
    '$timeout',
    'contentBuffer',
    'modal',
    'analytics',
    'analyticsEvents',
  ];

  function controller($templateCache, _keyup$, $timeout, _contentBuffer, _modal, analytics, _analyticsEvents) {
    let _b = document.querySelector('body');
    let _h = document.querySelector('html');
    let _current = null;

    let popup = {
      changed() {},

      set current(value) {
        if (value !== null) {
          let template = `/${value}-popup.html`;
          if ($templateCache.get(template)) {
            $timeout(() => {
              _current = value;
              _h.classList.add('linda-has-popup');
              _b.style.overflow = 'hidden';
              _h.style.overflow = 'hidden';
            });
          } else {
            console.warn(`no such popup template: ${template}`);
          }
        } else {
          $timeout(() => {
            _current = null;
            _h.classList.remove('linda-has-popup');
            _b.style.overflow = '';
            _h.style.overflow = '';
          });
        }
      },

      get current() {
        return _current === null ? '' : `/${_current}-popup.html`;
      },

      cb: null,
    };

    popup.open = function (data) {
      const content = data.content || {};
      _contentBuffer.write('popup', JSON.parse(JSON.stringify(content)) || null);
      popup.cb = typeof data.cb === 'function' ? data.cb : null;

      popup.current = data.name;

      analytics.send(_analyticsEvents.popupOpen, data.name, 'No_Label', { nonInteraction: true });
    };

    popup.close = function () {
      _contentBuffer.clear('popup');
      popup.current = null;
    };

    popup.has = function (name) {
      const template = `/${name}-popup.html`;
      return $templateCache.get(template);
    };

    _keyup$
      .pipe(filter((e) => e.keyCode === 27))
      .subscribe((data) => {
        if (_modal.template === '') {
          $timeout(() => {
            popup.close();
          });
        }
      });

    return popup;
  }

  app.factory('popup', controller);
})();
