(function () {
  'use strict';

  app.directive('historyGoBack', controller);

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      element.on('click', function () {});
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
