import '../service/rx/cookie$.js'
(function () {
  'use strict';

  controller.$inject = ['cookie$'];

  function controller(cookie$) {
    let link = function link(scope, element, attrs) {
      element.on('click', function () {
        cookie$.next({ event: 'banner_open' });
      });
    };
    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('cookieSettings', controller);
})();
