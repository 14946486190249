(function () {
  'use strict';

  const directiveName = 'swipeSidebar';

  app.directive(directiveName, controller);

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const options = scope.$eval(attrs[directiveName]);
      const menuElement = angular.element(options.el);
      const limits = { max: options.limit[1], min: options.limit[0] };
      const className = 'has-open-' + (limits.max > 0 ? 'left' : 'right') + '-sidebar';

      const tapPosition = { start: 0, end: 0 };
      let distance = 0;
      let tapping = false;

      element.bind('touchstart', function (e) {
        tapping = true;
        tapPosition.start = e.changedTouches[0].pageX;
        menuElement.css('transition', 'none');
      });

      element.bind('touchmove', function (e) {
        tapping = false;
        tapPosition.end = e.changedTouches[0].pageX;
        distance = tapPosition.start - tapPosition.end;
        distance = Math.min(distance, limits.max);
        distance = Math.max(distance, limits.min);
        menuElement.css(
          'transform',
          'translateX(' + (limits.max > 0 ? limits.max - distance : limits.min - distance) + 'px)',
        );
        element.css('opacity', tapPosition.end / tapPosition.start);

        e.preventDefault();
      });

      element.bind('touchend', function (e) {
        tapPosition.end = e.changedTouches[0].pageX;
        menuElement.removeAttr('style');
        const isClosed = limits.max > 0 ? distance > limits.max * 0.4 : distance < limits.min * 0.4;
        if (isClosed || tapping) {
          menuElement.removeClass(className);
        } else {
          menuElement.addClass(className);
        }
        menuElement.css('transform', '');
        element.css('opacity', '1');

        e.preventDefault();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
