import '../../service/ee/ee-sport.js';
import { helper } from '../../service/helper.js';
import '../../service/domain/state.js';
import '../../service/rx/user$.js';
import '../../service/rx/system$.js';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

(function () {
  'use strict';

  const name = 'lindaTglabSportField';
  const id = 'sb_rooter';

  controller.$inject = ['eeSport', 'helper', '$location', 'state', '$window', 'system$', '$transitions'];

  function controller(_eeSport, helper, $location, state, $window, _system$, $transitions) {
    const tglabCode = 'sport-tglab';
    $window.__SB_INIT__ = { config: {} };
    function link($scope, $element, $attrs) {
      $scope.preloader = true;
      const destroy$ = new Subject();
      const config = $scope.$eval($attrs[name]);
      const livePath = `/${config.live}` || '/live';
      const prelivePath = `/${config.prelive}` || '/sport';

      $element[0].setAttribute('id', id);

      _eeSport
        .launch({ code: tglabCode })
        .then((answer) => {
          const { host, ...options } = answer.result.launch_options;

          $window.__SB_INIT__.config = Object.assign(
            {},
            {
              jquery: true,
              live_path: `${$_CONFIG.currentLang}${livePath}`,
              pre_path: `${$_CONFIG.currentLang}${prelivePath}`,
            },
            options,
          );

          helper.dom.addScript(host);
          $window.betSlipLoaded = () => {
            $window.ExternalBetSlipLogin = () => {
              state.goToAuthorization();
            };
            $window.ExternalDepositClick = () => {
              state.goto('deposit');
            };

            $transitions.onSuccess({}, function (transition) {
              const optionsTarget = transition.options().relative;
              if (optionsTarget !== null) {
                $window.externalSBReload();
              }
            });
          };
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => ($scope.preloader = false));

      _system$
        .pipe(
          takeUntil(destroy$),
          filter(({ action }) => action === 'login')
        )
        .subscribe(() => {
          _eeSport
            .launch({ code: tglabCode })
            .then((answer) => {
              const { token } = answer.result.launch_options;
              $window.__SB_INIT__.config.token = token;
              $window.betSlipLoginOnSuccess();
            })
            .catch((e) => console.error(e));
        });

      $scope.$on('$destroy', () => {
        destroy$.next();
        destroy$.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
      bindings: '<',
    };
  }

  app.directive(name, controller);
})();
