import '../service/rx/item-status$.js'
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['itemStatus$'];

  function controller(_itemStatus$) {
    function link(scope, element, attrs) {
      const { event, group, class: className } = scope.$eval(attrs.toggleGroupClassOnEvent);

      const subscription = _itemStatus$
        .pipe(filter((name) => name === group))
        .subscribe(() => element.removeClass(className));

      element.on(event, () => {
        _itemStatus$.next(group);
        element.addClass(className);
      });

      scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('toggleGroupClassOnEvent', controller);
})();
