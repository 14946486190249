(function () {
  const directive = { name: 'dvsFileUpload' };
  controller.$inject = [];

  function controller() {
    function link(scope, element) {
      const inputElement = angular.element('<input type="file" ng-model="files" hidden multiple />');
      element.append(inputElement);

      inputElement.bind('change', function () {
        scope.$emit('updateFile', this.files);
        this.value = '';
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
