import '../../../service/domain/favorites.js'
import '../../../service/domain/user.js'
import '../../../service/domain/state.js'
import '../../../service/rx/system$.js'
(function () {
  const classLib = {
    demo: 'linda-has-demo',
    label: 'linda-has-label',
    skeleton: 'linda-has-skeleton',
  };

  controller.$inject = ['$scope', '$element', 'favorites', 'user', 'state', 'system$'];

  function controller($scope, $element, _favorites, _user, _state, _system$) {
    let subscription;

    $scope.game = {};
    $scope.preloader = false;

    $scope.content = '';

    $scope.toggleFavourites = () => {
      _favorites.toggle($scope.game);
      _system$.next({ action: 'renew favourite' });
    };

    this.$onInit = () => {
      subscription = _favorites.collection().subscribe((games) => {
        if (_favorites.shouldSetFavorite(games, $scope.game)) {
          $scope.game.favorites = true;
        }

        if (_favorites.shouldRemoveFavorite(games, $scope.game)) {
          $scope.game.favorites = false;
        }
      });
    };

    this.$onChanges = function () {
      $scope.content = this.content || '';

      if (this.game) {
        $scope.game = this.game;

        $element[0].classList.toggle(classLib.skeleton, Boolean(this.game.skeleton));

        if (this.game.demo) $element[0].classList.add(classLib.demo);

        if (this.game.label && this.game.label.length) $element[0].classList.add(classLib.label);
      }
    };

    this.$onDestroy = () => {
      subscription?.unsubscribe();
    };
  }

  const bindings = {
    game: '<',
    content: '<',
  };

  new Controller('gameItem', controller, bindings);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanGameItem${i}`, 'gameItem');
  });
})();
