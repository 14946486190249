export let soundPlayer = 'soundPlayer';

class Sound {
  soundsArray = [];
  muted = false;
  createTrack(track) {
    return new Audio(track);
  }
  playTrack(track) {
    if (!this.muted) {
      if (!this[track]) {
        this[track] = this.createTrack(track);
        this.soundsArray.push(this[track]);
      }
      this.stopTrack(this[track]);
      this[track].play();
    }
  }
  stopTrack(track) {
    track.pause();
    track.currentTime = 0;
  }
  stopAllTracks() {
    this.soundsArray.forEach((item, i) => {
      this.stopTrack(item);
    });
  }
  toggleSoundMute() {
    this.muted = !this.muted;
    this.soundsArray.forEach((item, i) => {
      item.muted = this.muted;
    });
  }
}

app.service(soundPlayer, Sound);
