(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return {
      set(key, value) {
        localStorage.setItem(key, value);
      },
      get(key) {
        return localStorage.getItem(key);
      },
      remove(key) {
        localStorage.removeItem(key);
      },
      setObject(key, value) {
        this.set(key, JSON.stringify(value));
      },
      getObject(key) {
        return JSON.parse(this.get(key));
      },
      setMapObject(key, data) {
        const mappedObject = new Map(Object.entries(data));
        this.setObject(key, Object.fromEntries(mappedObject));
      },
      getAffiliateData() {
        const data = this.getObject('params');
        delete data.login;
        return data || {};
      },
    };
  }

  app.factory('localStorage', controller);
})();
