import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return {
      isEnabled$: window.deferredPrompt$.pipe(filter((a) => a.isEnabled)),

      answer$: window.deferredPrompt$.pipe(filter((a) => a.answer)),

      isEnabled: Boolean(window.deferredPrompt),

      open() {
        window.deferredPrompt.prompt();

        window.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            window.deferredPrompt = null;
            window.deferredPrompt$.next({ answer: true });
          } else {
            window.deferredPrompt$.next({ answer: false });
          }
        });
      },
    };
  }

  app.service('deferredPrompt', controller);
})();
