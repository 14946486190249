import { Subject } from 'rxjs';

(function () {
  'use strict';
  app.factory('rabbit$', () => new Subject());
})();

(function () {
  'use strict';

  controller.inject = ['$q'];

  function controller($q) {
    const Application = PIXI.Application;
    const Sprite = PIXI.Sprite;
    const AnimatedSprite = PIXI.AnimatedSprite;
    const Container = PIXI.Container;
    const loader = PIXI.Loader.shared;

    const shift = -2500;

    return {
      init(options) {
        return new Application(options);
      },

      load(app) {
        const defer = $q.defer();

        loader
          .add('bonus1', 'img/rabbit/rabbit1.json')
          .add('bonus2', 'img/rabbit/rabbit2.json')
          .add('bonus3', 'img/rabbit/rabbit3.json')
          .add('ball', 'img/rabbit/magic_ball.json')
          .add('gold', 'img/rabbit/gold.json')
          .add('explosion', 'img/rabbit/explosion.json')
          .add('smoke', 'img/rabbit/smoke.png')
          .add('stage', 'img/rabbit/stage.png')

          .load((load, res) => {
            defer.resolve({ loader: load, res });
          });

        return defer.promise;
      },

      /*
                  createObjects(res){
                      const smoke = createPicture(res.smoke);
                      const stage = createPicture(res.stage);
                      const bonus1 = createAnimation(res.bonus1); // money
                      const bonus2 = createAnimation(res.bonus2); // gold
                      const gold = createAnimation(res.gold);
                      const bonus3 = createAnimation(res.bonus3); // ball
                      const ball = createAnimation(res.ball);
                      const explosion = createAnimation(res.explosion, animation => {
                          animation.onComplete = () => { animation.visible = false };
                          animation.visible = false;
                          animation.loop = false;
                      });

                      return { smoke, stage, bonus1, bonus2, gold, bonus3, ball, explosion }
                  },
      */

      render(app, loader, res) {
        const smoke = createPicture(res.smoke);
        const stage = createPicture(res.stage);
        const bonus1 = createAnimation(res.bonus1); // money
        const bonus2 = createAnimation(res.bonus2); // gold
        const gold = createAnimation(res.gold);
        const bonus3 = createAnimation(res.bonus3); // ball
        const ball = createAnimation(res.ball);
        const explosion = createAnimation(res.explosion, (animation) => {
          animation.onComplete = () => {
            animation.visible = false;
          };
          animation.visible = false;
          animation.loop = false;
        });

        const rabbit1 = new Container();
        rabbit1.addChild(bonus1);

        const rabbit2 = new Container();
        rabbit2.addChild(bonus2);
        rabbit2.addChild(gold);

        const rabbit3 = new Container();
        rabbit3.addChild(bonus3);
        rabbit3.addChild(ball);

        const rabbitScene = new Container();
        rabbitScene.addChild(rabbit1);
        rabbitScene.addChild(rabbit2);
        rabbitScene.addChild(rabbit3);

        rabbit1.x = shift;
        rabbit2.x = shift;
        rabbit3.x = shift;

        app.stage.addChild(stage);
        app.stage.addChild(rabbitScene);
        app.stage.addChild(smoke);
        app.stage.addChild(explosion);

        function goto(nextInstance) {
          explosion.gotoAndPlay(0);
          explosion.visible = true;
          setTimeout(() => {
            hideBonus().selectBonus(nextInstance);
          }, 500);
        }

        let currentInstance = null;

        function createAnimation(res, cb) {
          const animation = new AnimatedSprite(Object.values(res.textures));
          animation.x = app.screen.width / 2;
          animation.y = app.screen.height / 2;
          animation.anchor.set(0.5);
          animation.animationSpeed = 0.3;
          animation.visible = true;
          animation.play();

          if (typeof cb === 'function') cb(animation);
          return animation;
        }

        function createPicture(res) {
          const picture = new Sprite(res.texture);
          picture.x = app.screen.width / 2;
          picture.y = app.screen.height / 2;
          picture.anchor.set(0.5);
          return picture;
        }

        function selectBonus(instance) {
          instance.x = 0;
          currentInstance = instance;
        }

        function hideBonus(instance) {
          (instance || currentInstance).x = shift;
          return { selectBonus };
        }

        return {
          rabbit1: {
            select() {
              selectBonus(rabbit1);
            },
            goto() {
              goto(rabbit1);
            },
          },
          rabbit2: {
            select() {
              selectBonus(rabbit2);
            },
            goto() {
              goto(rabbit2);
            },
          },
          rabbit3: {
            select() {
              selectBonus(rabbit3);
            },
            goto() {
              goto(rabbit3);
            },
          },
        };
      },
    };
  }

  app.factory('rabbit', controller);
})();

(function () {
  'use strict';
  controller.$inject = ['rabbit$', 'rabbit'];

  function controller(_rabbit$, _rabbit) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const id = attrs.selectRabbit;
        element.on('click', (e) => {
          _rabbit$.next(id);
        });
      },
    };
  }

  app.directive('selectRabbit', controller);
})();

(function () {
  'use strict';

  controller.$inject = ['rabbit$', 'rabbit'];

  function controller(_rabbit$, _rabbit) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const options = {
          width: 423,
          height: 600,
          transparent: true,
        };

        let subscription;

        const app = _rabbit.init(options);
        element[0].appendChild(app.view);

        _rabbit.load(app).then((o) => {
          const control = _rabbit.render(app, o.loader, o.res);

          app.stage.scale.set(0.65);

          //app.stage.anchor.set(0.6);

          app.stage.x = app.screen.width / 4;
          app.stage.y = app.screen.height / 4;

          control.rabbit2.select();

          subscription = _rabbit$.subscribe((message) => {
            switch (message) {
              case '1':
                control.rabbit1.goto();
                break;
              case '2':
                control.rabbit2.goto();
                break;
              case '3':
                control.rabbit3.goto();
                break;
            }
          });
        });
      },
    };
  }

  app.directive('renderSmallRabbit', controller);
})();

(function () {
  'use strict';

  controller.$inject = ['rabbit$', 'rabbit'];

  function controller(_rabbit$, _rabbit) {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const options = {
          width: 1080,
          height: 1050,
          transparent: true,
        };

        let subscription;

        const app = _rabbit.init(options);
        element[0].appendChild(app.view);

        _rabbit.load(app).then((o) => {
          const control = _rabbit.render(app, o.loader, o.res);

          control.rabbit1.select();

          subscription = _rabbit$.subscribe((message) => {
            switch (message) {
              case '1':
                control.rabbit1.goto();
                break;
              case '2':
                control.rabbit2.goto();
                break;
              case '3':
                control.rabbit3.goto();
                break;
            }
          });
        });
      },
    };
  }

  app.directive('renderRabbit', controller);
})();
