(function () {
  'use strict';

  const directive = { name: 'lindaFirstViewChange' };

  function controller() {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs.lindaFirstViewChange);

      const elementID = config.id;

      function changeItemStatus(elementID, itemStatus) {
        const obj = JSON.parse(localStorage.getItem('first-view')) || {};
        obj[elementID] = itemStatus;
        window.localStorage.setItem('first-view', JSON.stringify(obj));
      }

      const firstViewLayer = document.querySelector(`[linda-first-view*='${config.id}']`);

      element.on('click', () => {
        if (config.action === 'show') {
          firstViewLayer.classList.add('linda-shown');
          changeItemStatus(elementID, false);
        } else if (config.action === 'hide') {
          firstViewLayer.classList.remove('linda-shown');
          changeItemStatus(elementID, true);
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
