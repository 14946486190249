(function(){

    'use strict';

    app.directive( 'head', controller );

    controller.$inject = [  ];

    function controller(  ){

        function link( scope, element, attrs ){

            setTimeout( () => {

                console.log( $_CONFIG.customScripts );

                $_CONFIG.customScripts.forEach( item => {

                    let element = document.createElement( 'script' );
                    element.src = `${item}?build=${$_CONFIG.build}`;
                    element.async = false;
                    document.head.appendChild( element );

                } );




            }, 1000);

        }

        return {
            restrict: 'E',
            //scope: true,
            link:link
        };
    }



})();

