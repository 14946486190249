import '../../service/domain/user.js'
import '../../service/rx/system$.js'
import '../../service/domain/documentVerification.js'
import { Subject } from 'rxjs';
import { takeUntil, filter  } from 'rxjs/operators'

(function () {
  'use strict';

  const directive = { name: 'dvsNotificationVerified' };

  controller.$inject = ['user', 'system$', 'verificationInfo$'];

  function controller(_user, _system$, _verificationInfo$) {
    function link($scope, $element) {
      const destroy$ = new Subject();

      function changeVerificationStatus() {
        if (_user.profile.verificationStatus === 'VERIFIED' && window.localStorage.getItem('show-verification-bar')) {
          $element[0].classList.add('is-verification-changed');
          return;
        }
        $element[0].classList.remove('is-verification-changed');
      }

      $scope.verificationClose = () => {
        window.localStorage.removeItem('show-verification-bar');
        $element[0].classList.remove('is-verification-changed');
        angular.element(document.querySelector('.page-verification-alert')).remove();
      };

      changeVerificationStatus();

      _system$
        .pipe(
          takeUntil(destroy$),
          filter((message) => message.action === 'statusChanged')
        )
        .subscribe(() => {
          changeVerificationStatus();
        });

      _verificationInfo$
        .pipe(
          takeUntil(destroy$),
          filter((message) => message.action === 'verificationStatusUpdated')
        )
        .subscribe(() => {
          changeVerificationStatus();
        });

      $scope.$onDestroy = () => {
        destroy$.next();
        destroy$.unsubscribe();
      };
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
