(function (windowObject) {
  windowObject.Controller = function (type, controller, bindings) {
    if (typeof controller !== 'function') controller = function () {};
    if (typeof bindings === 'undefined') bindings = {};

    windowObject.tools.batman[type] = { bindings, controller };
  };

  windowObject.Batman = function (name, type) {
    const app = windowObject.app;

    app.component(name, {
      ...windowObject.tools.batman[type],
      template: app.getTU(name),
    });
  };

  windowObject.tools = {
    batman: {},
  };
})(window);
