import '../service/domain/promo.js'
import '../service/rx/system$.js'
import { filter } from 'rxjs/operators';

(function () {
  // .ui(get-promos-to-scope="promos" get-promos-to-scope-as-array="true" get-promos-to-scope-category="welcome_bonus")

  'use strict';

  const directive = {
    name: 'getPromosToScope',
    category: 'getPromosToScopeCategory',
    asArray: 'getPromosToScopeAsArray',
  };

  factory.$inject = ['promo', 'system$'];

  function factory(_promo, _system$) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'promos';
      const asArray = attrs[directive.asArray] === 'true';
      const category = attrs[directive.category] || null;
      const options = {};

      scope[variable] = asArray ? [] : {};

      if (category) {
        options.category = category;
      }

      _promo
        .list(options)
        .then((a) => setPromos())
        .catch((e) => {});

      let subscription = _system$
        .pipe(filter((m) => m.action === 'statusChanged'))
        .subscribe(() => {
          setPromos();
        });

      scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });

      function setPromos() {
        if (asArray) {
          scope[variable] = _promo.result;
        } else {
          scope[variable].__proto__ = _promo.object;
        }
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
