const showElementOnSuccess = 'showElementOnSuccess';
import { transcludeResolver } from '../service/transcludeResolver/transcludeResolver.js';

class VisibleElementOnSuccess {
  static $inject = ['transcludeResolver'];
  constructor(transcludeResolver) {
    this.transcludeResolver = transcludeResolver;
  }

  link(scope, element, attrs, ctrl, transclude) {
    scope.successResponse = false;
    scope.preloader = true;
    const config = scope.$eval(attrs[showElementOnSuccess]);
    this.transcludeResolver
      .get(config)
      .then((answer) => {
        transclude(scope, (clone) => {
          element[0].parentNode.insertBefore(clone[0], element[0].nextSibling);
        });
        scope.successResponse = true;
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        scope.preloader = false;
      });
  }
}

app.directive(showElementOnSuccess, () => {
  return {
    restrict: 'A',
    controller: VisibleElementOnSuccess,
    transclude: 'element',
    link: function (scope, element, attrs, ctrl, transclude) {
      ctrl.link(scope, element, attrs, ctrl, transclude);
    },
  };
});
