import '../../../service/domain/paysystem.js';
(function () {
  'use strict';

  const component = { name: 'lindaCashboxWithdraw' };

  controller.$inject = ['$scope', 'paysystem', '$timeout'];

  function controller($scope, _paysystem, $timeout) {
    $scope.preloader = false;
    $scope.collection = [];
    $scope.selected = undefined;
    $scope.selectedId = 0;
    $scope.changing = false;

    $scope.$watch('selected', (newVal) => {
      $scope.changing = true;
      $timeout(() => {
        $scope.changing = false;
      }, 0);
    });

    $scope.setSelected = (selected) => ($scope.selected = selected);
    $scope.preloader = true;
    _paysystem.collection({ type: 'withdraw' }).then(
      (answer) => {
        $scope.collection = answer.result;
        $scope.selected = $scope.collection[0];
        $scope.preloader = false;
      },
      (answer) => {
        $scope.preloader = false;
      }
    );
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
