(function () {
  function controller() {
    const addressCAPattern = new RegExp('^((?!po box).)*$');

    return {
      check(value) {
        return addressCAPattern.test(value.toLowerCase());
      },
    };
  }

  app.factory('addressCA', controller);
})();
