(function () {
  'use strict';

  controller.$inject = ['$compile'];

  function controller($compile) {
    return {
      restrict: 'E',
      link(scope, element, attrs, ctrl, transclude) {
        const expression = scope.$eval(attrs.expression);

        const template = Boolean(expression) ? attrs.template1 : attrs.template2;

        element.replaceWith($compile(template)(scope));
      },
    };
  }

  app.directive('selectTemplateGivenExpression', controller);
})();
