(function () {
  'use strict';

  const directive = { name: 'callNumber' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs) => {
      const number = attrs[directive.name];

      element.on('tap', () => (location.href = `tel: ${number}`));
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
