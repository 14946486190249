import { html, css, LitElement, CSSResult, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/qr-code/qr-code.js';

@customElement('funid-auth-qr')
export class FunidAuth extends LitElement {
  public static styles: CSSResult = css`
    sl-qr-code {
      display: block;
      width: 100%;
      height: 100%;
      fill: var(--fid-auth-dialog-qr-color);
    }

    sl-qr-code::part(base) {
      width: 100%;
      height: 100%;
    }
  `;

  public render(): TemplateResult {
    return html`
      <a href="${this.url}" target="_blank">
        <sl-qr-code
          .value="${this.url}"
          radius="0.2"
          error-correction="L"
          label="${this.description}"
        ></sl-qr-code>
      </a>
    `;
  }

  @property({ type: String }) url!: string;
  @property({ type: String }) description!: string;
}
