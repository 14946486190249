import '../../service/domain/http.js';
import '../../service/localStorage.js';

(function () {
  'use strict';

  controller.$inject = ['$q', 'http', 'localStorage'];

  function controller($q, _http, _localStorage) {
    return {
      banking(options) {
        const defer = $q.defer();
        const alias = 'pSystemCollection';

        const ls = _localStorage.getAffiliateData();
        options = Object.assign({}, ls, options);

        const config = {
          params: options,
          cache: true,
          meta: {
            type: 'api',
            alias,
          },
        };


        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },

      collection(options) {
        const defer = $q.defer();
        const alias = 'pSystemList';
        const config = {
          params: options,
          meta: {
            type: 'api',
            alias,
          },
        };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
    };
  }

  app.factory('paysystem', controller);
})();
