import '../../../service/domain/system.js'
import '../../../service/rx/user$.js'
import '../../../service/domain/user.js'
import '../../../service/rx/system$.js'
import { filter } from 'rxjs/operators';
(function () {
  'use strict';

  controller.$inject = ['$scope', 'system', 'user$', 'user', 'system$'];

  function controller($scope, _system, _user$, _user, _system$) {
    let subscription = null;

    $scope.preloader = false;

    $scope.withdrawType = 'withdraw';
    $scope.depositType = 'deposit';
    $scope.cryptoType = 'crypto';

    $scope.country = null;
    $scope.currency = null;
    $scope.type = $scope.depositType;

    $scope.countries = [];
    $scope.currencies = [];

    this.$onInit = () => {
      getCountries();
    };

    function setCountryAndCurrency(countryCode, currencyKey) {
      $scope.country = _user.profile.country || countryCode;
      $scope.currency = _user.profile.currency || currencyKey;
    }

    const prepareCurrencyList = (list) => list.map(({ code: key, name, symbol: icon }) => ({ key, name, icon }));

    function getCountries() {
      $scope.preloader = true;
      _system
        .countries()
        .then((answer) => {
          $scope.countries = answer.result;
          $scope.currencies = prepareCurrencyList(answer.result[0].currency);
          setCountryAndCurrency(answer.default, $scope.currencies[0].key);
        })
        .catch(() => {})
        .finally(() => ($scope.preloader = false));
    }

    $scope.$watch('country', (newVal) => {
      $scope.countries.filter((item) => {
        if (item.code === newVal) {
          $scope.currencies = prepareCurrencyList(item.currency);
          $scope.currency = _user.profile.currency || $scope.currencies[0].key;
        }
      });
    });
    subscription = _system$
      .pipe(filter((message) => message.action === 'login'))
      .subscribe(() => {
        setCountryAndCurrency();
      });

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  new Controller('bankingPage', controller);
})();

(function () {
  'use strict';
  new Batman('batmanBankingPage1', 'bankingPage');
})();
(function () {
  'use strict';
  new Batman('batmanBankingPage2', 'bankingPage');
})();
(function () {
  'use strict';
  new Batman('batmanBankingPage3', 'bankingPage');
})();
