import { achievement } from '../domain/achievement.js';
import { ReplaySubject } from 'rxjs';

export const cupService = 'cupService';

class Cup {
  static $inject = ['achievement'];
  constructor(achievement) {
    this.achievement = achievement;
  }
  markPoint = 0;
  progressCup = 0;
  currentItem$ = new ReplaySubject();

  getImage(imgUrl) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imgUrl;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        reject(img);
      };
    });
  }
  getCurrentIndex(arr) {
    let prevStatus = 0;
    const lastIndexInArr = arr.length - 1;
    this.currentIndex = 0;
    this.prevPointsAmount = 0;

    if (arr[lastIndexInArr].status === 3) {
      this.currentIndex = lastIndexInArr;
      if (lastIndexInArr !== 0) {
        this.prevPointsAmount = arr[lastIndexInArr - 1].progress[1];
      }
      return;
    }

    arr.forEach((item, index) => {
      if (item.status === 1 && prevStatus === 3) {
        this.currentIndex = index;
        this.prevPointsAmount = index - 1 >= 0 ? arr[index - 1].progress[1] : 0;
      }
      prevStatus = item.status;
    });
  }
  getAchievement(data) {
    this.config = data;
    return this.achievement.list(data).then(({ result }) => {
      const lastAchieve = result.slice(-1);
      this.getCurrentIndex(result);
      this.currentItem = Object.assign(result[this.currentIndex], { prevPointsAmount: this.prevPointsAmount });
      this.calcProgressAndMarks(result.length, lastAchieve);
      this.currentItem$.next({ item: this.currentItem });
      return result;
    });
  }

  calcProgressAndMarks(achievementAmount) {
    this.markPoint = 1 / achievementAmount;
    const minHeightForAnimation = 0.0000001;
    const startPosition = this.markPoint * this.currentIndex;
    const progressOnCurrentMarkPoint =
      this.markPoint *
      ((this.currentItem.progress[0] - this.currentItem.prevPointsAmount) /
        (this.currentItem.progress[1] - this.currentItem.prevPointsAmount));
    this.progressCup = startPosition + progressOnCurrentMarkPoint + minHeightForAnimation;
  }

  takeAchieve(id) {
    return this.achievement.take(id).then((data) => {
      this.getAchievement(this.config);
      return data;
    });
  }

  buildMarks(pointsList) {
    pointsList.forEach((el, index) => {
      const position = this.markPoint * (index + 1) * 100;
      el.style.bottom = `${position}%`;
    });
  }
}

app.service(cupService, Cup);
