import '../service/payment/cashbox-state.js'
(function () {
  'use strict';

  const directive = { name: 'cashboxState' };

  factory.$inject = ['cashboxState'];

  function factory(_cashboxState) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs[directive.name]);

      const variable = config.name || 'cashboxState';
      const additionalData = config.data || null;

      if (additionalData) {
        _cashboxState.setValue(additionalData);
      }

      scope[variable] = _cashboxState.getValue();

      const subscription = _cashboxState.currentState$.subscribe((newState) => {
        const fieldsToChange = Object.keys(newState);

        fieldsToChange.forEach((field) => {
          if (scope.hasOwnProperty(field)) {
            scope[field] = newState[field];
          }
        });

        scope[variable] = newState;
      });

      scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
