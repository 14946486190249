(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    const depositObj = {
      mask: Number,
      min: 0,
      max: 999999999,
      scale: 0,
      signed: false,
    };

    const startsWithZero = (value) => value?.toString().startsWith('0');
    const hasInvalidSymbols = (value) => isNaN(+value);

    return {
      init($element, ngModel) {
        const element = $element[0];
        const inputMask = new IMask(element, depositObj);

        inputMask.on('accept', () => {
          const valueToSet = inputMask.unmaskedValue.length ? inputMask.typedValue : null;
          ngModel.$setViewValue(valueToSet);
        });

        ngModel.$formatters.push((modelValue) => {
          if (modelValue) {
            inputMask.typedValue = parseInt(modelValue);
          }
          return inputMask.typedValue;
        });

        ngModel.$parsers.push(() => {
          return inputMask.typedValue;
        });

        return inputMask;
      },

      customValidators(scope, element, attrs, ngModel) {
        ngModel.$validators.min = (modelValue, viewValue) => {
          const params = scope?.system?.rules;
          if (angular.isDefined(params)) {
            const value = modelValue || viewValue;
            const minValue = params.range[0];
            if ((hasInvalidSymbols(value) || !value) && !startsWithZero(value)) return true;
            if (startsWithZero(value)) return false;
            return +value >= minValue;
          }
          return true;
        };

        ngModel.$validators.max = (modelValue, viewValue) => {
          const params = scope?.system?.rules;
          if (angular.isDefined(params)) {
            const value = modelValue || viewValue;
            const maxValue = params.range[params.range.length - 1];
            return hasInvalidSymbols(value) || !value || startsWithZero(value) ? true : +value <= maxValue;
          }
          return true;
        };

        ngModel.$validators.required = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return !((hasInvalidSymbols(value) || !value) && !startsWithZero(value));
        };
      },

      validator(instance) {
        return instance.masked.isComplete;
      },
    };
  }

  app.factory('depositAmount', controller);
})();
