import '../../service/periodicals/worldCup.js'
(function () {
  'use strict';
  const directive = { name: 'worldCupCalendar' };
  controller.$inject = ['worldCup'];

  function controller(_worldCup) {
    function link($scope) {
      $scope.preloader = false;
      $scope.worldCupCollection = {
        rounds: [],
      };
      const holidayType = 'casino';
      let roundsCollection = {};
      let bonusCollection = {};

      function getData() {
        $scope.preloader = true;

        _worldCup.getCollection().then((answer) => {
          roundsCollection = answer.result;

          roundsCollection.forEach((round) => {
            $scope.worldCupCollection.rounds.push({ groups: round.groups });
          });
          getHolidays();
        });
      }

      function getHolidays() {
        if ($scope.holiday?.events?.[holidayType]?.length) {
          bonusCollection = $scope.holiday;
          createBonusStructure();
        } else {
          $scope.$watch('holiday', (holiday) => {
            if (holiday?.events?.[holidayType]?.length) {
              bonusCollection = holiday;
              createBonusStructure();
            }
          });
        }
      }

      function createBonusStructure() {
        roundsCollection.forEach(function (round, index) {
          const eventsCalendar = {};
          const bonusesOfRound = [];
          const groupsPoints = {};

          round.events.forEach(function (event) {
            let worldCupBonus = bonusCollection.events.casino.find(({ id }) => id === event.calendar_id);

            if (!eventsCalendar.hasOwnProperty(worldCupBonus.id)) {
              eventsCalendar[worldCupBonus.id] = { ...worldCupBonus, sportEvents: [] };
            }
            if (event.teams[0].score < 0) {
              event.restDay = true;
            }
            eventsCalendar[worldCupBonus.id].sportEvents.push(event);

            event.teams.forEach(function (team) {
              if (!groupsPoints.hasOwnProperty(team.long_name)) {
                groupsPoints[team.long_name] = 0;
              }
              groupsPoints[team.long_name] += Number(team.points);
            });
          });

          for (let property in eventsCalendar) {
            bonusesOfRound.push(eventsCalendar[property]);
          }
          $scope.worldCupCollection.rounds[index].bonus = bonusesOfRound;
          $scope.worldCupCollection.rounds[index].groups.forEach((group) =>
            group.teams.forEach(function (team) {
              team.totalPoints = groupsPoints[team.long_name] || 0;
            })
          );

          sortTeamsInGroups(index);
          setDate(index);
          setRoundStatus(index);
          setRoundName(round, index);
        });
      }

      function setDate(index) {
        $scope.worldCupCollection.rounds[index].bonus.forEach(function (bonus) {
          const date = new Date(bonus.date);
          bonus.day = date.getDay();
          bonus.month = date.getMonth();
        });
      }

      function setRoundStatus(index) {
        $scope.worldCupCollection.rounds[index].bonus.forEach(function (bonus) {
          if (bonus.dayStatus === 'today') {
            $scope.worldCupCollection.rounds[index].status = 'active';
          }
        });
      }

      function setRoundName(round, index) {
        $scope.worldCupCollection.rounds[index].name = round.name;
      }

      function sortTeamsInGroups(index) {
        $scope.worldCupCollection.rounds[index].groups.forEach((group) =>
          group.teams.sort(function (prev, next) {
            return next.totalPoints - prev.totalPoints;
          })
        );
      }

      getData();
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
