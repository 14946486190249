import '../service/domain/favorites.js'
import '../service/domain/recentlyPlayed.js'
(function () {
  'use strict';

  const directive = { name: 'getPrivateCategories' };

  factory.$inject = ['favorites', 'recentlyPlayed'];

  function factory(_favorites, _recentlyPlayed) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'privateCategories';
      let favSubscription;
      let recentlyPlayedSubscription;

      scope[variable] = {
        favourites: false,
        recentlyPlayed: false,
      };

      favSubscription = _favorites.collection().subscribe((games) => {
        scope[variable].favourites = games.length > 0;
      });

      recentlyPlayedSubscription = _recentlyPlayed.recentlyPlayed$.subscribe((hasGame) => {
        scope[variable].recentlyPlayed = hasGame;
      });

      _recentlyPlayed.checkRecentlyPlayed();

      scope.$on('$destroy', () => {
        recentlyPlayedSubscription.unsubscribe();
        favSubscription.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
