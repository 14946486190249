class SeoRanking {
  static $inject = ['$state'];

  constructor($state) {
    this.$state = $state;
  }

  check() {
    const route = this.$state.current.name.split('.');
    const state = [];
    let needsSEO = true;

    route.forEach((i) => {
      state.push(i);
      const routerPart = this.$state.get(state.join('.'));
      if (routerPart && routerPart.private) {
        needsSEO = false;
      }
    });

    return needsSEO;
  }
}

app.service('seoRanking', SeoRanking);
