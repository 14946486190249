import '../../service/domain/http.js'
(function () {
  const paymentsWithoutAmount = ['siru_fi', 'cbc_bitcoin_channel', 'siru_se', 'siru_no'];

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    return {
      hasAmount(system) {
        return !paymentsWithoutAmount.includes(system);
      },

      auth(options) {
        const defer = $q.defer(),
          alias = 'pAuth',
          data = options,
          config = {
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      deposit(options) {
        const defer = $q.defer(),
          alias = 'pDeposit',
          data = options,
          config = {
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      withdraw(options) {
        const defer = $q.defer(),
          alias = 'pWithdraw',
          data = options,
          config = {
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      check(options) {
        const defer = $q.defer(),
          alias = 'checkPayment',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      details(options) {
        const defer = $q.defer(),
          alias = 'paymentDetails',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      // using only for interac
      getDetails(options) {
        const defer = $q.defer(),
          alias = 'pInterac',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      promoCode(f) {
        const defer = $q.defer(),
          alias = 'pPromoCode',
          config = {
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, f, config).then(
          function (answer) {
            defer.resolve(answer.result);
          },
          function (answer) {
            defer.reject(answer.message);
          }
        );

        return defer.promise;
      },

      lastDep(options) {
        const defer = $q.defer(),
          alias = 'historyLastDep',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      makeLastDevcodeDep(f) {
        const defer = $q.defer(),
          alias = 'pLastDevcodeDep',
          config = {
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, f, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data.message);
          }
        );

        return defer.promise;
      },

      makeForm(path, params, el) {
        document.querySelector('#depositForm')?.remove();

        const form = document.createElement('form');

        form.setAttribute('method', 'POST');
        form.setAttribute('action', path);
        form.setAttribute('id', 'depositForm');
        form.setAttribute('target', 'depositWindow');

        for (let key in params) {
          if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', params[key]);
            form.appendChild(hiddenField);
          }
        }

        el.append(form);
        form.submit();
      },
    };
  }

  app.factory('payment', controller);
})();
