import '../../service/rx/ws$.js';
import '../../service/configs/ws-events.js';
import '../domain/popup.js';

import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export const cancelWithdrawal = 'cancelWithdrawal';

class CancelWithdrawal {
  static $inject = ['ws$', 'wsEvents', 'popup'];

  constructor(ws$, wsEvents, popup) {
    this.ws$ = ws$;
    this.wsEvents = wsEvents;
    this.popup = popup;

    this.destroy$ = new Subject();

    this.$onDestroy = () => {
      this.destroy$.next();
      this.destroy$.unsubscribe();
    };
  }

  init() {
    this.ws$
      .pipe(
        filter(({ event }) => event === this.wsEvents.balanceThresholdReached),
        takeUntil(this.destroy$),
      )
      .subscribe(({ data }) => {
        this.popup.open({ name: 'balance-alert', content: data });
      });
  }
}

app.service(cancelWithdrawal, CancelWithdrawal)
