import '../../../service/domain/promo.js';
import '../../../service/rx/system$.js';
import '../../../service/rx/ws$.js';
import '../../../service/domain/platform.js';
import '../../../service/configs/ws-events.js';
import { filter } from 'rxjs/operators';

(function () {
  controller.$inject = ['$scope', 'promo', 'system$', 'platform', 'ws$', 'wsEvents'];

  function controller($scope, _promo, _system$, _platform, _ws$, _wsEvents) {
    $scope.promo = {};

    function getPromoItem(params) {
      $scope.preloader = true;
      _promo
        .item(params)
        .then(({ result }) => {
          $scope.promo = result;
          _platform.version().then((version) => {
            if (version === 2) {
              $scope.promo.bonusSteps = removeNoActionAlias(result.bonusSteps);
            }
            if (version === 3) {
              $scope.promo.bonus_steps = removeNoActionAlias(result.bonus_steps);
            }
          });
          getOptInData(result);
        })
        .finally(() => {
          $scope.preloader = false;
        });
    }
    const getOptInData = (promo) => {
      $scope.isDepositDone = promo.requiredActions?.some((item) => item.type === 'DEPOSIT' && item.isActionDone);
      const requiredAction = promo.requiredActions?.find((item) => item.type !== 'OPT_IN' && !item.isActionDone);
      if (requiredAction) {
        $scope.startDate = requiredAction.startDate || null;
        $scope.timerCounter = new Date(requiredAction.startDate).getTime() - Date.now();
        $scope.isPromoStartSoon = new Date(requiredAction.startDate) >= Date.now();
      }
    };

    const removeNoActionAlias = (steps) =>
      steps.map((step) => {
        if (step.alias !== 'no_action') return step;
        return {
          ...step,
          alias: null,
        };
      });

    const subscription = _system$
      .pipe(filter((message) => message.action === 'login'))
      .subscribe(() => {
        if (this.item?.requiredActions) {
          getPromoItem({ name: this.item.name });
        }
      });

    const wsPromoSubscription = _ws$
      .pipe(filter(({ event }) => event === _wsEvents.bonusIssue))
      .subscribe(() => {
        if (this.name && this.name.length) {
          getPromoItem({ name: this.name });
        }
      });

    this.$onDestroy = () => {
      wsPromoSubscription.unsubscribe();
      subscription.unsubscribe();
    };

    this.$onChanges = () => {
      if (this.name && this.name.length) {
        getPromoItem({ name: this.name });
        return;
      }
      $scope.promo = this.item || {};
    };
  }

  new Controller('promoItem', controller, { item: '<', name: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanPromoItem${i}`, 'promoItem');
  });
})();
