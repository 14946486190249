import '../../../service/domain/account.js';
import '../../../service/domain/state.js';
import '../../../service/domain/popup.js';
import '../../../service/domain/modal.js';
(function () {
  'use strict';

  const component = { name: 'lindaSelfcontrolAccountclosure' };

  controller.$inject = ['$scope', 'account', 'state', 'popup', 'modal'];

  function controller($scope, _account, _state, _popup, _modal) {
    $scope.preloader = false;
    $scope.formData = {
      reason: null,
      password: '',
    };

    $scope.submit = (form) => {
      if (form.$valid) {
        _modal.open('accountclosure').then((answer) => {
          $scope.preloader = true;
          _account
            .close({
              reason: $scope.formData.reason,
              password: $scope.formData.password,
            })
            .then(
              (answer) => {
                // FET-3702
                // _state.goto('home');
                _popup.close(); /* FED-2167 */
                $scope.preloader = false;
              },
              (answer) => {
                $scope.preloader = false;
              }
            );
        });
      } else {
        angular.forEach(form.$error, (type) => {
          angular.forEach(type, (field) => {
            field.$setTouched();
          });
        });
      }
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
