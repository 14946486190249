(function () {
  'use strict';

  app.filter('timerange', controller);

  controller.$inject = [];

  function controller() {
    const dateObj = new Date();

    return function (string, delimeter) {
      const array = dateObj.sToDHMS(string);

      return delimeter ? array.join(delimeter) : array;
    };
  }
})();
