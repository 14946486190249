import '../../service/configs/config.js';
import '../seo/utils/href-lang.service.js';

class AlternateService {
  static $inject = ['$document', '$location', 'config', 'hrefLangService'];
  constructor($document, $location, config, hrefLangService) {
    this.$document = $document[0];
    this.$location = $location;
    this.config = config;
    this.hrefLangService = hrefLangService;
    this.languages = config.languages;
    this.targetNode = this.$document.querySelector('meta[name="keywords"]');
  }

  alternateLinks = {};

  update() {
    this.languages.forEach((language) => {
      const href = this.$location.absUrl().replace(`/${this.config.currentLang}/`, `/${language}/`);

      if (this.alternateLinks.hasOwnProperty(language)) {
        this.alternateLinks[language].setAttribute('href', href);
        return;
      }
      const hreflang = this.hrefLangService.check(language);
      const alternateLink = this.$document.createElement('link');
      alternateLink.setAttribute('rel', 'alternate');
      alternateLink.setAttribute('hreflang', hreflang);
      alternateLink.setAttribute('href', href);

      this.alternateLinks[language] = this.$document.head.insertBefore(alternateLink, this.targetNode);
    });
  }
}

app.service('alternateService', AlternateService);
