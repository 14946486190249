(function () {
  'use strict';

  controller.$inject = ['$q', '$http'];

  app.factory('zipcodeMask', controller);

  function controller($q, $http) {
    return {
      list() {
        const defer = $q.defer();

        $http.get('/joxi/js/boot/data/zipcode-mask.json').then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }
})();
