(function () {
  'use strict';

  controller.$inject = ['$timeout'];

  function controller($timeout) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs.settings);
      function slickStart($) {
        const slickness = $(element);
        $timeout(() => {
          scope.formData.avatar = `${config.initialSlide}` || '0';
          slickness.slick(config);
          slickness.css('visibility', 'visible');

          slickness.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            $timeout(() => {
              scope.$apply(() => {
                scope.formData.avatar = `${nextSlide}`;
              });
            });
          });
        });
      }
      Promise.all([import('jquery'), import('slick-carousel')]).then(([$]) => {
        slickStart($);
      });
    }

    return {
      restrict: 'E',
      link: link,
    };
  }

  app.directive('lindaAvatarSlick', controller);
})();
