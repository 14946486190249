(function () {
  'use strict';

  const component = { name: 'notFound' };

  function controller() {
    const seoPrerenderMeta = document.createElement('meta');
    seoPrerenderMeta.setAttribute('name', 'prerender-status-code');
    seoPrerenderMeta.setAttribute('content', '404');

    this.$onInit = () => {
      document.head.appendChild(seoPrerenderMeta);
    };
    this.$onDestroy = () => {
      if (seoPrerenderMeta.parentNode instanceof Node) {
        seoPrerenderMeta.parentNode.removeChild(seoPrerenderMeta);
      }
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
