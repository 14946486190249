class TwitterSiteService {
  static $inject = ['$document', '$location'];

  constructor($document, $location) {
    this.$document = $document[0];
    this.$location = $location;
    this.twitterSiteRef = this.$document.head.querySelector('meta[name="twitter:site"]');
    this.targetNode = this.$document.querySelector('meta[property="og:url"]');
    if (this.twitterSiteRef === null) {
      this.create();
    }
  }
  create() {
    this.twitterSiteRef = this.$document.createElement('meta');
    this.twitterSiteRef.setAttribute('name', 'twitter:site');
    return this.targetNode.after(this.twitterSiteRef);
  }

  update() {
    this.twitterSiteRef.setAttribute('content', `${this.$location.absUrl()}`);
  }
}

app.service('twitterSiteService', TwitterSiteService);
