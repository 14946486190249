import '../../../service/zipmler/zimpler.paynplay.js';
import '../../../service/configs/config.js';
import '../../../service/domain/user.js';
import '../../../service/domain/state.js';
import '../../../service/helper.js';
import '../../../service/rx/trustly$.js';
import '../../../service/configs/zimpler.js';

(function () {
  'use strict';

  controller.$inject = [
    '$scope',
    'zimpler.paynplay',
    'config',
    'user',
    'state',
    'helper',
    'trustly$',
    '$timeout',
    'zimpler.config',
  ];

  function controller(
    $scope,
    _zimpler_paynplay,
    _config,
    _user,
    _state,
    _helper,
    _trustly$,
    $timeout,
    _zimpler_config
  ) {
    $scope.amount = _zimpler_paynplay.data.amount;
    $scope.paysystem = _zimpler_config;

    $scope.submit = _helper.form.validator((form) => {
      if ($scope.amount > 0) {
        _zimpler_paynplay.data.amount = $scope.amount;
        _zimpler_paynplay.data.step = 1;
        _state.goto('paynplay');
      }
    });
  }

  new Controller('zimplerPaynplayWidget', controller, {});
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanZimplerPaynplayWidget${i}`, 'zimplerPaynplayWidget');
  });
})();
