import '../../service/rx/user$.js';
import '../../service/domain/user.js';
import '../../service/rx/system$.js';
import '../../service/configs/config.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'lindaVision' };

  controller.$inject = ['user$', 'user', 'system$', 'config'];

  function controller(_user$, _user, _system$, _config) {
    function link(scope, element, attrs) {
      if (typeof vision !== 'object') {
        return;
      }

      vision.setLang(_config.currentLang);

      _system$
        .pipe(filter((m) => m.action === 'systemInfo'))
        .subscribe((m) => {
          vision.setSession(m.data);
        });

      _user$.subscribe((user) => {
        vision.setUser(_user);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
