import '../../service/domain/popup.js';
import '../../service/domain/state.js';
(function () {
  'use strict';

  const directive = { name: 'virtualScroll' };

  controller.$inject = ['$state', 'popup', 'state', '$compile'];

  function controller($state, _popup, _state, $compile) {
    let container = [];
    let height = 0;
    const items = [];
    let options = {};
    let size = 100;
    const cols = 2;
    const bench = 10;

    let scrollTop = 0;
    const ve = { start: 0, end: 0 };

    const link = (scope, element, attrs) => {
      scope.limit = [0, 99999];

      options = scope.$eval(attrs[directive.name]);

      container = options.container ? $document[0].querySelector(options.container) : element[0];
      container[0].onscroll = (e) => doIt(e, scope);
      container[0].ontouchstart = (e) => doIt(e, scope);
      container[0].ontouchmove = (e) => doIt(e, scope);
      container[0].ontouchend = (e) => doIt(e, scope);
      container[0].ontouchcancel = (e) => doIt(e, scope);
    };

    const doIt = function (e, scope) {
      if (!items.length) {
        size = 110 + 12;
      }

      if (!height) {
        height = container.outerHeight();
      }

      const top = container.scrollTop();
      const bottom = top + height;
      let start = Math.round(top / size) * cols - bench;
      if (start < 0) {
        start = 0;
      }
      const end = start + Math.round((height / size) * cols) + bench * 2 + 1;

      // let end = Math.round( ( top + bench * size )/size - 1 ) * cols - 1;

      const direction = scrollTop > top ? 'up' : 'down';
      scrollTop = top;

      const currentStart = Math.round(top / size) * cols;
      const currentEnd = Math.round(bottom / size) * cols;

      const ifDown = currentEnd >= ve.end && direction === 'down';
      const ifUp = currentStart < ve.start + bench / 2 + 1 && ve.start > 0 && direction === 'up';

      if (ifDown || ifUp) {
        ve.start = start;
        ve.end = end;
        scope.$apply(function () {
          scope.limit = [start, end];
        });

        $('.game-box__container').css('paddingTop', (ve.start / cols) * size + 'px');
      }
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
