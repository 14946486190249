(function () {
  'use strict';

  const component = { name: 'lindaViewLayerThree' };

  controller.$inject = ['$scope'];

  function controller($scope) {
    this.$onChanges = () => {
      $scope.content = this.content;
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      content: '<',
    },
  });
})();
