import '../../../service/domain/history.js';
import '../../../service/rx/history$.js';
import '../../../service/domain/historyBalanceFlow.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';
  const directive = { name: 'lindaBalanceFlowStatusFilter' };

  factory.$inject = ['history', 'history$', 'historyBalanceFlow'];

  function factory(_history, _history$, _historyBalanceFlow) {
    //function compile( tElement, tAttr ){

    function link($scope, $element, $attrs, $ctrl, $transclude) {
      let previousNode = $element[0];

      if (!$scope.ngModel) {
        $scope.ngModel = [];
      }

      _history
        .getFilter()
        .then((a) => {
          const statuses = a.result.status;

          let previousType = '';

          for (let i = 0; i <= statuses.length - 1; i++) {
            $transclude($scope.$new(true), ($clone, scope) => {
              scope.index = i;
              scope.status = statuses[i];

              const clone = $clone[0];

              let hash = Date.now().toString(36);
              let checkbox = clone.querySelector('input[type="checkbox"]');
              let label = clone.querySelector('label');

              checkbox.setAttribute('value', `${statuses[i].name}`);
              checkbox.setAttribute('id', `${hash}${i}`);
              checkbox.checked = $scope.ngModel.includes(statuses[i].name);
              label.setAttribute('for', `${hash}${i}`);

              checkbox.addEventListener('change', (e) => {
                $scope.$apply(() => {
                  $scope.ngModel.toggle(e.target.value, e.target.checked);
                });
              });

              $ctrl.$formatters.push((modelValue) => {
                checkbox.checked = modelValue.includes(checkbox.value);
                return modelValue;
              });

              previousNode.after(clone);

              if (_historyBalanceFlow.selectedTypes.includes(statuses[i].type)) {
                clone.classList.add('linda-is-active');
              }

              if (previousType !== statuses[i].type) {
                clone.classList.add('linda-first-in-group');
                previousType = statuses[i].type;

                if (i >= 1) {
                  const emptyElement = document.createElement('div');
                  emptyElement.classList.add('linda-empty-element');
                  previousNode.after(emptyElement);
                }
              }

              previousNode = clone;

              const subscribtion = _history$
                .pipe(filter((i) => i.type === scope.status.type))
                .subscribe(() => {
                  clone.classList.toggle('linda-is-active');
                });
              scope.$on('$destroy', () => subscribtion.unsubscribe());
            });
          }
        })
        .catch((e) => {})
        .finally(() => {});
    }

    //}

    return {
      require: 'ngModel',
      restrict: 'A',
      transclude: 'element',
      link,
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, factory);
})();
