export interface FunIdAuthMedia {
  alt: string;
  ext: string;
  mime: string;
  url: string;
}

export interface FunIdAuthInteractiveItem {
  text: string;
  link: string;
  icon: FunIdAuthMedia;
}

export interface FunIdAuthInfoItem {
  title: string;
  description: string;
  media: FunIdAuthMedia;
}

export interface FunIdAuthDataModel {
  title: string;
  description: string;
  text: string;
  infoButtonText: string;
  background: FunIdAuthMedia;
  logo: FunIdAuthMedia;
  info: {
    title: string;
    button: string;
    guide: FunIdAuthInfoItem[];
  };
  steps: {
    title: string;
    items: FunIdAuthInteractiveItem[];
  };
  downloads: {
    title: string;
    items: FunIdAuthInteractiveItem[];
  };
}

export interface FunIdDialogParams {
  qrcUrl: string;
  langCode: string;
  viewType?: FunidViewType;
}

export enum FunidAuthEvent {
  Success = 'funid-auth-success',
  Error = 'funid-auth-error',
  AuthOpen = 'funid-auth-open',
  AuthClose = 'funid-auth-close',
}

export enum FunidAuthError {
  EmailAlreadyInUse = 'EMAIL_ALREADY_IN_USE',
  Fail = 'FAIL',
}

export enum FunidViewType {
  QrCode = 'qrcode',
  Info = 'info',
}
