import '../service/domain/content-buffer.js';
(function () {
  'use strict';

  controller.$inject = ['contentBuffer', '$timeout'];

  function controller(_contentBuffer, $timeout) {
    function link(scope, element, attrs) {
      const variable = attrs.setPopupContentTo;

      _contentBuffer.write('popup', variable);
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('setPopupContentTo', controller);
})();
