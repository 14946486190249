(function () {
  'use strict';

  const directive = { name: 'gameItem' };

  controller.$inject = ['$compile'];

  function controller($compile) {
    const link = (scope, element, attrs) => {
      let tapping = false;

      element.bind('touchstart', function (e) {
        tapping = true;
      });

      element.bind('touchmove', function (e) {
        tapping = false;
      });

      element.bind('touchend', function (e) {
        if (tapping) {
          $document[0].querySelector('.game-item__buttons-hit-box').remove();
          const game = scope.$eval(attrs[directive.name]);
          const el = angular.element('<div class="game-item__buttons-hit-box"></div>');
          let content =
            '<button class="g-button g-button_game g-button_green" translate="Play" ng-tap="app.root.mobilegame" ng-tap-state="{name: game.alias, mode: \'real\'}"></button>';
          if (game.demo) {
            content +=
              '<button class="g-button g-button_demo g-button_transparent" translate="Demo" ng-tap="app.root.mobilegame" ng-tap-state="{name: game.alias,mode: \'demo\'}"></button>';
          }
          el.html(content);
          $compile(el)(scope);
          element.append(el);
        }
      });
    };
    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
