import '../../service/domain/vip.js';
(function () {
  'use strict';

  const component = { name: 'lindaVip' };

  controller.$inject = ['$scope', 'vip', '$timeout'];

  function controller($scope, _vip, $timeout) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.loaded = false;

    $scope.preloader = true;
    _vip
      .collection()
      .then((answer) => {
        $scope.collection = answer.result;
        $scope.preloader = false;
        $scope.loaded = true;
      })
      .catch((e) => {})
      .finally(() => {
        $scope.preloader = false;
      });

    $scope.getCurrent = (event, slick, currentSlide, nextSlide) => {
      $scope.current = nextSlide;
      $scope.transition = true;
      $timeout(() => {
        $scope.transition = false;
      }, 600);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
