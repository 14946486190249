import '../../../service/domain/achievement.js';
import '../../../service/rx/achievement$.js';
import '../../../service/rx/system$.js';
import { filter } from 'rxjs/operators';

(function () {
  controller.$inject = ['$scope', 'achievement', 'achievement$', 'system$'];

  function controller($scope, _achievement, _achievement$, _system$) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.isHideShowMore = false;

    let achievementsCollection;
    let method = 'list';
    let page = 1;
    const options = {
      count: undefined,
    };

    $scope.showMore = () => {
      if (!$scope.isHideShowMore) {
        page += 1;
        getCollection(options.category)
          .then((data) => {
            $scope.collection = $scope.collection.concat(data.result);
            $scope.isHideShowMore = $scope.collection.length >= data.total_count;
          })
          .catch(() => {});
      }
    };

    const subscription = _system$.pipe(filter((message) => message.action === 'login')).subscribe(() =>
      getCollection(options.category)
        .then((data) => {
          $scope.collection = data.result;
          page = 1;
          $scope.isHideShowMore = $scope.collection.length >= data.total_count;
        })
        .catch(() => {}),
    );

    function getCollection(category) {
      if (achievementsCollection) {
        $scope.collection = achievementsCollection;
        return;
      }
      $scope.preloader = true;

      options.page = page;
      options.category = category;

      return _achievement[method](options)
        .then((data) => {
          const types = options.type.split(',').filter((i) => i !== '') || [];
          if (types.length) {
            const result = data.result.filter((item) => types.includes(item.type));
            return Object.assign(data, { result: result });
          }
          return data;
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    _achievement$.pipe(filter((item) => item.action === 'take')).subscribe((item) => {
      if ($scope.collection.find((a) => a.id === item.achievement.id))
        getCollection(options.category)
          .then((data) => {
            $scope.collection = data.result;
            page = 1;
            $scope.isHideShowMore = $scope.collection.length >= data.total_count;
          })
          .catch(() => {});
    });

    this.$onChanges = () => {
      options.count = this.count || 25;
      options.sort = this.sort || '';
      options.order = this.order || '';
      options.status = this.status || '';
      options.type = this.type || '';
      options.content_category = this.contentCategory || '';
      achievementsCollection = this.item;

      switch (this.category) {
        case 'lastEarned':
          method = 'lastEarned';
          break;

        case 'inProgress':
          method = 'inProgress';
          break;

        default:
          options.category = this.category;
          break;
      }

      $scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });

      getCollection(options.category)
        .then((data) => {
          $scope.collection = data.result;
          page = 1;
          $scope.isHideShowMore = $scope.collection.length >= data.total_count;
        })
        .catch(() => {});
    };
  }

  new Controller('achievementBox', controller, {
    item: '<',
    category: '<',
    contentCategory: '<',
    count: '<',
    sort: '<',
    order: '<',
    status: '<',
    type: '<', //  строка. Список типів розділених комою
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanAchievementBox${i}`, 'achievementBox');
  });
})();
