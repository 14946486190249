import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cupService } from '../../service/cup/cupService.js';

const componentName = 'cupProgress';

class Cup {
  static $inject = ['$scope', 'cupService'];
  constructor($scope, cupService) {
    $scope.preloader = false;
    $scope.progress = 0;
    const destroy$ = new Subject();
    $scope.take = (item) => {
      $scope.preloader = true;
      cupService
        .takeAchieve({ id: item.id })
        .catch((error) => error)
        .finally(() => {
          $scope.preloader = false;
        });
    };
    cupService.currentItem$.pipe(takeUntil(destroy$)).subscribe(({ item }) => {
      $scope.item = item;
      $scope.progress = ((item.progress[0] - item.prevPointsAmount) / (item.progress[1] - item.prevPointsAmount)) * 100;
      $scope.isComplete = item.progress[1] - item.progress[0] === 0;
    });
    $scope.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }
}

app.component(componentName, {
  controller: Cup,
  template: app.getTU(componentName),
});
