(function () {
  'use strict';

  const directive = { name: 'getHolidaysToScope' };

  factory.$inject = ['holiday'];

  function factory(_holiday) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'holidaysList';
      scope[variable] = [];
      _holiday
        .list()
        .then((answer) => {
          scope[variable] = answer;
        })
        .catch((e) => {});
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
