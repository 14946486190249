import {Subject} from 'rxjs'

(function () {

  'use strict';

  function controller() {
    return new Subject();
  }

  app.factory('postMessage$', controller);
})();

