import '../service/utils/phone-mask.js';
(function () {
  const directive = { name: 'maskPhone' };

  controller.$inject = ['phoneMask'];

  function controller(_phoneMask) {
    const link = (scope, element, attrs, ngModel) => {
      _phoneMask
        .list()
        .then((phoneMasks) => {
          run(phoneMasks);
        })
        .catch((answer) => {})
        .finally(() => {});

      function run(phoneMasks) {
        phoneMasks.sort((a, b) => {
          if (a.length < b.length || a.additional) {
            return -1;
          } else {
            return 1;
          }
        });

        let oldPrefix = '';
        let maskIndex = phoneMasks.length - 1;

        const inputMask = new IMask(element[0], {
          mask: phoneMasks,
          dispatch: function (appended, dynamicMasked) {
            let number = (dynamicMasked.value + appended).replace(/\D/g, '');

            let index = dynamicMasked.compiledMasks.findIndex((m) => {
              let a = true;
              if (m.additional) {
                a = number.indexOf(m.additional.str) === m.additional.pos;
              }
              return number.indexOf(m.startWith) === 0 && number.length <= m.length && a;
            });

            maskIndex = index !== -1 ? index : maskIndex;
            return dynamicMasked.compiledMasks[maskIndex];
          },
        });

        inputMask.on('accept', () => {
          ngModel.$setViewValue(parseInt(inputMask.unmaskedValue, 10) || '');
        });

        attrs.$set('type', 'tel');

        setPrefix(attrs[directive.name]);
        attrs.$observe(directive.name, (value) => {
          setPrefix(value);
        });

        ngModel.$formatters.push((modelValue, viewValue) => {
          let value = modelValue || viewValue;
          if (value) {
            inputMask.unmaskedValue = value;
          }
          return inputMask.value;
        });

        ngModel.$parsers.push((modelValue, viewValue) => {
          return inputMask.unmaskedValue;
        });

        ngModel.$validators['phone'] = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? inputMask.masked.isComplete : true;
        };

        ngModel.$validate();

        function setPrefix(value) {
          let newPrefix = value.replace(/\-|\+/g, '');
          if (!inputMask.unmaskedValue || inputMask.unmaskedValue === oldPrefix) {
            inputMask.unmaskedValue = newPrefix;
          }
          oldPrefix = newPrefix;
        }
      }
    };

    return { restrict: 'A', require: '?ngModel', link };
  }

  app.directive(directive.name, controller);
})();
