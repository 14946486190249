(function () {
  'use strict';

  app.directive('inputFilterCreditcard', function () {
    return {
      require: 'ngModel',

      link(scope, element, attrs, ctrl) {
        let buffer = [];

        element.bind(' keydown', (e) => {
          let key = e.key.replace(/[^0-9\.]+/g, '').toString();

          if (key !== '') {
            if (buffer.length >= 4 && buffer.length <= 11) {
              key = 'X';
            }

            if (buffer.length === 4 || buffer.length === 9 || buffer.length === 14) {
              buffer.push(' ');
            }

            if (buffer.length <= 15) {
              buffer.push(key);
            }
          }
        });

        ctrl.$parsers.unshift((inputValue) => {
          const res = [];
          inputValue = inputValue.replace(/[^0-9\.]+/g, '').toString();

          return inputValue;
        });

        ctrl.$formatters.unshift((val) => {
          return val;
        });
      },
    };
  });
})();
