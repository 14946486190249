import { funIdAuthDataMock } from '../constants/funid-auth-data-mock';
import { FunIdAuthDataModel, FunIdAuthMedia } from '../model/funid-auth-model';
import {
  FunIdAuthResponse,
  FunIdLocaleResponse,
  MediaResponse,
} from '../model/funid-auth-response-model';

const transformMedia = (response: MediaResponse): FunIdAuthMedia => ({
  alt: response?.data?.attributes?.alternativeText || '',
  ext: response?.data?.attributes?.ext || '',
  mime: response?.data?.attributes?.mime || '',
  url: response?.data?.attributes?.url || '',
});

const mapResponse = ({
  data: { attributes },
}: FunIdAuthResponse): FunIdAuthDataModel => ({
  title: attributes.title,
  description: attributes.description,
  text: attributes.text,
  infoButtonText: attributes.infoButtonText,
  background: transformMedia(attributes.background),
  logo: transformMedia(attributes.logo),
  info: {
    title: attributes.info.title,
    button: attributes.info.button,
    guide: attributes.info.guide.map(infoItem => ({
      title: infoItem.title,
      description: infoItem.description,
      media: transformMedia(infoItem.media),
    })),
  },
  steps: {
    title: attributes.steps.title,
    items: attributes.steps.items.map(stepItem => ({
      text: stepItem.text,
      link: stepItem.link,
      icon: transformMedia(stepItem.icon),
    })),
  },
  downloads: {
    title: attributes.downloads.title,
    items: attributes.downloads.items.map(downloadItem => ({
      text: downloadItem.text,
      link: downloadItem.link,
      icon: transformMedia(downloadItem.icon),
    })),
  },
});

export class FunIdAuthService {
  public static getWidget(
    apiUrl: string,
    lang: string = 'en'
  ): Promise<FunIdAuthDataModel> {
    return fetch(
      `${apiUrl}/auth-widget?populate=steps,steps.items,steps.items.icon,downloads,downloads.items,downloads.items.icon,info,info.guide,info.guide.media,background,logo&locale=${lang}`
    )
      .then((res: Response) => res.json())
      .then(mapResponse)
      .catch(() => mapResponse(funIdAuthDataMock));
  }

  public static getAvailableLangs(apiUrl: string): Promise<string[]> {
    return fetch(
      `${apiUrl}/auth-widget?populate[localizations][fields]=locale&fields=locale`
    )
      .then(res => res.json())
      .then((response: FunIdLocaleResponse) =>
        response?.data?.attributes?.localizations?.data?.map(
          data => data?.attributes?.locale
        )
      );
  }
}
