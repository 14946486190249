(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const variable = attrs.getTimestampToScope || 'timestamp';

      scope[variable] = Date.now();
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('getTimestampToScope', controller);
})();
