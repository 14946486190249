import '../../../service/domain/history.js'
import '../../../service/domain/withdraw.js'
(function () {
  'use strict';

  controller.$inject = ['$rootScope', '$scope', 'history', 'withdraw'];

  function controller($rootScope, $scope, _history, _withdraw) {
    $scope.preloader = false;
    $scope.collection = [];

    $scope.filter = {
      type: 'withdraw',
      status: 'pending',
    };

    $scope.cancel = (id) => {
      $scope.preloader = true;
      _withdraw
        .cancel({ alias: id })
        .then(() => {
          $scope.collection = $scope.collection.filter((item) => {
            return item.id !== id;
          });
          _withdraw.cancelWithdrawal$.next();
        })
        .catch(() => {})
        .finally(() => ($scope.preloader = false));
    };

    $scope.renew = () => {
      const opt = {
        params: $scope.filter,
        endpoint: 'payment',
      };

      $scope.preloader = true;
      _history
        .collection(opt)
        .then(({ result }) => {
          $scope.collection = result;
        })
        .catch(() => {})
        .finally(() => ($scope.preloader = false));
    };

    $scope.renew();

    $rootScope.$on('withdraw-new', $scope.renew);
  }

  new Controller('cashboxWithdrawCancel', controller);
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanCashboxWithdrawCancel${i}`, 'cashboxWithdrawCancel');
  });
})();
