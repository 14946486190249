import '../../service/domain/http.js';
import { Subject } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = ['http', '$q'];

  function controller(_http, $q) {
    const events$ = new Subject();

    return {
      events$,
      launch,
    };

    function launch(options) {
      const defer = $q.defer(),
        alias = 'sportLaunch',
        config = {
          params: options,
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
        };

      _http.get(alias, config).then(
        (answer) => {
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        }
      );

      return defer.promise;
    }
  }

  app.factory('eeSport', controller);
})();
