(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return {
      check(value) {
        return typeof IBAN === 'object' ? IBAN.isValid(value) : true;
      },
    };
  }

  app.factory('iban', controller);
})();
