import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  const name = 'lindaLivechatinc';

  controller.$inject = ['system$', 'user', '$location', 'splash$', 'config'];

  function controller(_system$, _user, $location, _splash$, _config) {
    // window.__lc = window.__lc || {};
    // window.__lc.license = 13199751;
    // (function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))

    const link = (scope, element, attrs) => {
      const destroy$ = new Subject();

      const config = scope.$eval(attrs[name]) || _config.liveAgent;

      function onload() {
        if (_user.status) {
          setProfileData(_user.profile);
        } else {
          _system$
            .pipe(filter((message) => message.action === 'login'))
            .subscribe((message) => {
              setProfileData(_user.profile);
            });
        }
        _system$
          .pipe(filter((message) => message.action === 'open chat'))
          .subscribe((message) => {
            open();
          });

        if ($location.hash() === 'chat') {
          $location.hash('');
          open();
        }
      }

      function open() {
        window.LiveChatWidget.call('maximize');
      }

      function close() {
        window.LiveChatWidget.call('minimize');
        setTimeout(() => window.LiveChatWidget.call('hide'), 300);
      }

      _splash$
        .pipe(
          filter((m) => m === 'loaded'),
          take(1),
          takeUntil(destroy$)
        )
        .subscribe((i) => {
          window.LiveChatWidget.on('visibility_changed', ({ visibility }) => {
            if ('minimized' === visibility) {
              close();
            }
          });

          window.LiveChatWidget.on('ready', () => {
            onload();
          });
        });

      function setProfileData(user) {
        debugger;
        const email = user.email_guid || user.email;
        // window.LiveChatWidget.call('set_customer_name', user.profile.name);
        window.LiveChatWidget.call('set_customer_email', email);
      }

      scope.$onDestroy = () => {
        destroy$.next();
        destroy$.unsubscribe();
      };
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
