import '../../../service/domain/account.js';
import '../../../service/domain/state.js';
import '../../../service/domain/popup.js';
import '../../../service/domain/modal.js';
import '../../../service/helper.js';
// 1 - minutes
// 2 - hours
// 3 - days
// 4 - weeks
// 5 - months
// 6 - years
// 7 - permanently
(function () {
  'use strict';

  const component = { name: 'lindaSelfcontrolSelfexclusion' };

  controller.$inject = ['$scope', 'account', 'state', 'popup', 'modal', 'helper'];

  function controller($scope, _account, _state, _popup, _modal, _helper) {
    $scope.preloader = false;
    $scope.period = '5';
    $scope.count = 1;
    $scope.formData = {
      frozen_to: `${$scope.period}${$scope.count}`,
    };

    $scope.$watchGroup(['period', 'count'], (newValues, oldValues, scope) => {
      if (scope.period === '7') {
        $scope.formData.frozen_to = '71';
        return;
      }
      $scope.formData.frozen_to = `${scope.period}${scope.count}`;
    });

    $scope.submit = _helper.form.validator((form) => {
      _modal.open('selfexclude').then((answer) => {
        $scope.preloader = true;
        _account.exclude({ ...$scope.formData }).then(
          (answer) => {
            _popup.close();
            _state.goto('home');
            $scope.preloader = false;
          },
          (answer) => {
            $scope.preloader = false;
          }
        );
      });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
