// - For working with altenar proxy scripts needed to add in nginx config:
// - https://wiki.gosystem.io/display/MD/B2C+Altenar+proxifying+scripts+for+selected+localizations

(function () {
  'use strict';

  if (!$_CONFIG.sport) return;

  let proxySportScripts = false;
  let countryCode = '';
  const isDevEnv = checkIfDevEnv();

  getSystemCountryCode()
    .then(({ result }) => {
      countryCode = result.country.code.toUpperCase();
      init();
    })
    .catch(() => {});

  async function getSystemCountryCode() {
    const apiDomain = window.location.host;
    const currentLang = window.location.pathname.split('/')[1];
    const response = await fetch(`//${apiDomain}/${currentLang}/api/v2/system/info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .catch(() => {});
    return response;
  }

  function init() {
    setProxy();
    const sportSDK = setSportSDK();
    const widgetSDK = setWidgetSDK();
    appendAltenarWidgetScript(widgetSDK);
    appendSportsdk(sportSDK);
  }

  function checkIfDevEnv() {
    const envs = ['.m0l0', '.local', 'vesto.xyz', 'stage.', 'dev.'];
    const { host } = window.location;
    return window.$_DRAGON?.env === 'stage' ? true : !!envs.find((mask) => host.includes(mask));
  }

  function setProxy() {
    const countriesToProxy = window.$_DICT?.sportProxying?.countries || [];
    proxySportScripts = countriesToProxy.includes(countryCode);
    if (!proxySportScripts) return;
    ['altenarOrigins', 'altenarOrigin', 'altenarPath'].forEach((item) => altenarProxify(item));
  }

  function altenarProxify(name) {
    Object.defineProperty(window, name, {
      get: function () {
        return window[`_${name}`];
      },
      set: function (data) {
        if (typeof data === 'object') {
          const origins = {};
          Object.entries(data).map((item) => {
            if (!item[0].includes('betradar') && item[1].includes('http')) {
              const value = item[1].replace('https://', '');
              origins[item[0]] = '//sport-atr.serversplatforms.com/altenarScripts/' + value;
              return;
            }
            origins[item[0]] = item[1];
          });
          window[`_${name}`] = origins;
          return;
        }
        window[`_${name}`] = '//sport-atr.serversplatforms.com/altenarScripts/' + data.replace(/https:\/\/|\/\//gi, '');
      },
    });
  }

  function setWidgetSDK() {
    const proxyWidgetSDK = `//sport-atr.serversplatforms.com/widgetsdk/altenarWSDK.js`;
    const prodCdnWidgetSDK = `https://sb2wsdk-altenar2.biahosted.com/altenarWSDK.js`;
    const devCdnWidgetSDK = `https://sb2wsdk-altenar2-stage.biahosted.com/altenarWSDK.js`;

    if (isDevEnv) {
      return devCdnWidgetSDK;
    }

    return proxySportScripts ? proxyWidgetSDK : prodCdnWidgetSDK;
  }

  function setSportSDK() {
    const defaultSportId = $_CONFIG.sportId || 'sport_id_is_not_configured';
    const localSportId = $_CDN.sportConfig?.localizationIds?.[countryCode];
    const sportId = localSportId || defaultSportId;

    const proxySportSDK = `//sport-atr.serversplatforms.com/sportsdk/api/Integration/${sportId}`;
    const prodCdnSportSDK = `https://sb2integration-altenar2.biahosted.com/api/Integration/${sportId}`;
    const devCdnSportSDK = `https://sb2integration-altenar2-stage.biahosted.com/api/Integration/${sportId}`;

    if (isDevEnv) {
      return devCdnSportSDK;
    }

    return proxySportScripts ? proxySportSDK : prodCdnSportSDK;
  }

  function appendAltenarWidgetScript(widgetSDK) {
    window.AltenarWidgetScriptLoaded = false;
    const element = document.createElement('script');
    element.src = `${widgetSDK}?build=${$_CONFIG.build}`;
    element.async = false;
    element.onload = () => {
      window.AltenarWidgetScriptLoaded = true;
    };
    document.head.appendChild(element);
  }

  function appendSportsdk(sportSDK) {
    const element = document.createElement('script');
    element.src = `${sportSDK}?build=${$_CONFIG.build}`;
    element.async = false;
    element.onload = () => {};
    document.head.appendChild(element);
  }
})();
