(function () {
  function controller() {
    const cpfLength = 11;
    const cpfPattern = new RegExp('^[0-9]+$');

    return {
      check(value) {
        return cpfPattern.test(value) && value.length === cpfLength;
      },
    };
  }

  app.factory('cpf', controller);
})();
