(function () {
  controller.$inject = ['$scope', 'holiday'];

  function controller($scope, _holiday) {
    const subscription = _holiday.selectedBonus$.subscribe((bonus) => {
      $scope.isTaken = bonus.isTaken;
    });

    $scope.takeBonus = (id, type) => {
      _holiday.saveTakenBonus(id, type);
    };

    this.$onDestroy = function () {
      subscription.unsubscribe();
    };
  }

  new Controller('holidaysPopup', controller, {});
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanHolidaysPopup${i}`, 'holidaysPopup');
  });
})();
