import '../../service/domain/seo.js';
import '../../service/configs/config.js';
import '../../service/domain/state.js';
import '../../service/seo/title.service.js';
import '../../service/seo/description.service.js';
import '../../service/seo/keywords.service.js';
import '../../service/seo/og-title.service.js';
import '../../service/seo/og-description.service.js';
import '../../service/seo/alternate.service.js';
import '../../service/seo/utils/href-lang.service.js';
import '../../service/seo/og-url.service.js';
import '../../service/seo/twitter-site.service.js';
import '../../service/seo/utils/charset.service.js';
import '../../service/seo/linked-data.service.js';
import '../../service/seo/robots.service.js';
import '../../service/seo/canonical.service.js';

class LindaSeoDirective {
  static $inject = [
    'seo',
    'config',
    '$location',
    '$state',
    '$transitions',
    'state',
    'titleService',
    'descriptionService',
    'keywordsService',
    'ogTitleService',
    'ogDescriptionService',
    'alternateService',
    'hrefLangService',
    'ogUrlService',
    'twitterSiteService',
    'metaCharsetService',
    'linkedDataService',
    'robotsService',
    'canonicalService',
  ];
  constructor(
    seo,
    config,
    $location,
    $state,
    $transitions,
    state,
    titleService,
    descriptionService,
    keywordsService,
    ogTitleService,
    ogDescriptionService,
    alternateService,
    hrefLangService,
    ogUrlService,
    twitterSiteService,
    metaCharsetService,
    linkedDataService,
    robotsService,
    canonicalService,
  ) {
    this.seo = seo;
    this.config = config;
    this.$location = $location;
    this.$state = $state;
    this.$transitions = $transitions;
    this.state = state;
    this.titleService = titleService;
    this.descriptionService = descriptionService;
    this.keywordsService = keywordsService;
    this.ogTitleService = ogTitleService;
    this.ogDescriptionService = ogDescriptionService;
    this.alternateService = alternateService;
    this.hrefLangService = hrefLangService;
    this.ogUrlService = ogUrlService;
    this.twitterSiteService = twitterSiteService;
    this.metaCharsetService = metaCharsetService;
    this.linkedDataService = linkedDataService;
    this.robotsService = robotsService;
    this.canonicalService = canonicalService;
  }
  link(scope) {
    this.hrefLangService.render();
    const clearName = () => {
      return this.$location.path().substring(4).replace(/\//g, '__') || 'home';
    };

    this.$transitions.onSuccess({}, () => {
      this.robotsService.update();
      this.canonicalService.update();
      this.alternateService.update();
      this.ogUrlService.update();
      this.twitterSiteService.update();

      this.seo
        .item({ id: clearName() })
        .then(({ result }) => {
          const { title, description, keywords, jsonLd } = result;
          this.seo.meta$.next({ event: 'title updated', title });

          this.titleService.update(title);
          this.descriptionService.update(description);
          this.keywordsService.update(keywords);
          this.ogTitleService.update(title);
          this.ogDescriptionService.update(description);
          this.linkedDataService.update(jsonLd);
        })
        .catch((error) => {
          if (this.config.redirectToNotFound === true && error.status === 404) {
            this.state.goto('notFound');
          }
        });
    });
  }
}

app.directive('lindaSeo', () => {
  return {
    restrict: 'A',
    controller: LindaSeoDirective,
    link: function (scope, element, attrs, ctrl) {
      ctrl.link(scope);
    },
  };
});
