(function () {
  window.$_GET = {};
  const urlParams = getUrlParams();
  const localStorageParams = JSON.parse(window.localStorage.getItem('params')) || {};
  const dateNow = new Date().toISOString();
  const additionalParams = {};


  if (
    (urlParams.btag && urlParams.btag !== localStorageParams.btag) ||
    (urlParams.affid && urlParams.affid !== localStorageParams.affid)
  ) {
    additionalParams.btag_id_timestamp = dateNow;
  }

  if (document.referrer) {
    const referrerURL = new URL(document.referrer);
    const hostname = referrerURL.hostname;
    if (hostname !== location.host) {
      additionalParams.referrer = document.referrer;
      additionalParams.referrer_timestamp = dateNow;
    }
  }

  window.localStorage.setItem(
    'params',
    JSON.stringify(Object.assign({}, localStorageParams, urlParams, additionalParams))
  );

  function getUrlParams() {
    const queryString = window.location.search;
    const urlParams = Object.fromEntries(new URLSearchParams(queryString));
    window.$_GET = urlParams;
    return urlParams;
  }
})();
