(function () {
  'use strict';
  luckyWinnerController.$inject = ['promoLuckyWinner'];

  function luckyWinnerController(luckyApi) {
    function link(scope, element, attrs) {
      const namespace = attrs.luckyWinnerToScope || 'luckyWinner';
      scope[namespace] = {
        isLoaded: false,
        login: '',
        payout: {
          current: 0,
          next: 0,
          max: 0,
          currency: '',
        },
        isTaken: false,
      };

      luckyApi.getLast(1).then((payload) => {
        if (!(Array.isArray(payload.result) && payload.result.length)) {
          return;
        }

        const [winner] = payload.result;
        const next = parseInt(winner.payout_rule.daily, 10) + parseInt(winner.amount, 10);
        const max = parseInt(winner.payout_rule.max);

        scope[namespace] = {
          ...scope[namespace],
          login: winner.login,
          payout: {
            current: parseInt(winner.amount, 10),
            next: next > max ? max : next,
            max,
            daily: parseInt(winner.payout_rule.daily),
            currency: winner.currency,
          },
          isTaken: winner.status === 'taken',
          isLoaded: true,
        };
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('luckyWinnerToScope', luckyWinnerController);
})();
