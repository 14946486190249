import {
  CAPI_URL,
  CAPI_URL_DEV,
  CDN_URL,
  CDN_URL_DEV,
  FAPI_URL,
  FAPI_URL_DEV
} from '../funid-env';
import {
  FundEnvService,
  FunIdEnvType
} from './funid-env-service';

export class FunIdConfigService {
  private envService: FundEnvService = new FundEnvService();

  private url: string | undefined;
  private transactionId: string | undefined;

  public set(url: string, transactionId: string) {
    this.url = url;
    this.transactionId = transactionId;
  }

  public getTransactionId(): string | undefined {
    return this.transactionId;
  }

  public getQrUrl(): string {
    return (
      this.url ||
      `https://pay.funid.com/process/?transaction_id=${this.transactionId}&identify=true`
    );
  }

  public getApiUrl(): string {
    return this.isDev() ? CAPI_URL_DEV : CAPI_URL;
  }

  public getWsApiUrl(): string {
    return this.isDev() ? FAPI_URL_DEV : FAPI_URL;
  }

  private isDev(): boolean {
    if (this.envService.hasSpecifiedEnv()) {
      const savedEnv: string | null = this.envService.getEnvType();

      if (this.envService.isValidEnv(savedEnv)) {
        return savedEnv === FunIdEnvType.Development;
      }
    }

    return Boolean(this.url?.includes('-dev.funid'));
  }

  public getCdnUrl(): string {
    return this.isDev() ? CDN_URL_DEV : CDN_URL;
  }
}
