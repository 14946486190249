import { FunIdAuthResponse } from '../model/funid-auth-response-model';

export const funIdAuthDataMock: FunIdAuthResponse = {
  data: {
    id: 1,
    attributes: {
      title: 'Join with FunID',
      description: 'Scan QR-code to login',
      text: 'Get FunID app on your phone',
      infoButtonText: 'More about FunID',
      steps: {
        id: 38,
        title: 'title',
        items: [
          {
            id: 122,
            text: 'Download the app',
            icon: {
              data: {
                attributes: {
                  alternativeText: null,
                  ext: '.svg',
                  mime: 'image/svg+xml',
                  url: '',
                },
              },
            },
            link: '',
          },
          {
            id: 123,
            text: 'Verify your identity',
            icon: {
              data: {
                attributes: {
                  alternativeText: null,
                  ext: '.svg',
                  mime: 'image/svg+xml',
                  url: '',
                },
              },
            },
            link: '',
          },
          {
            id: 124,
            text: 'Get your FunID wallet',
            icon: {
              data: {
                attributes: {
                  alternativeText: null,
                  ext: '.svg',
                  mime: 'image/svg+xml',
                  url: '',
                },
              },
            },
            link: '',
          },
        ],
      },
      downloads: {
        id: 39,
        title: 'store-link',
        items: [
          {
            id: 125,
            text: 'ios',
            link: 'https://apps.apple.com/tr/app/funid/id1517272804',
            icon: {
              data: {
                attributes: {
                  alternativeText: '',
                  ext: '.svg',
                  mime: 'image/svg+xml',
                  url: '',
                },
              },
            },
          },
          {
            id: 126,
            text: 'android',
            link: 'https://play.google.com/store/apps/details?id=com.unteleported.casinoidApp',
            icon: {
              data: {
                attributes: {
                  alternativeText: null,
                  ext: '.svg',
                  mime: 'image/svg+xml',
                  url: '',
                },
              },
            },
          },
        ],
      },
      info: {
        id: 1,
        title: 'FunID',
        button: 'Join with FunID',
        guide: [
          {
            id: 9,
            title: 'One wallet - all casinos',
            description:
              'FunID is your digital wallet wherever you go. Our fast, easy-to-use and secure app lets you pay or play online, send and receive money fast and make secure online payments whenever you need to.',
            media: {
              data: {
                attributes: {
                  alternativeText: null,
                  ext: '.png',
                  mime: 'image/png',
                  url: '',
                },
              },
            },
          },
          {
            id: 10,
            title: 'Sign up to casino in one click',
            description:
              'Fun ID can be used for deposits and withdrawals - and instant casino sign-up as well. You can either choose an online casino from the list found on the Fun ID app or scan the QR code found on all Fun ID casinos. It does not really get easier than this!',
            media: {
              data: {
                attributes: {
                  alternativeText: null,
                  ext: '.webm',
                  mime: 'video/webm',
                  url: '',
                },
              },
            },
          },
          {
            id: 11,
            title: 'Fast deposits and withdrawals',
            description:
              'You can combine all your casino accounts to the app which makes deposits even easier. The system is quite genius and extremely easy to use. The app even shows all Fun ID casinos in one list (with bonus details of course). So we are definitely talking about a true casino payment application.',
            media: {
              data: {
                attributes: {
                  alternativeText: null,
                  ext: '.png',
                  mime: 'image/png',
                  url: '',
                },
              },
            },
          },
          {
            id: 12,
            title: 'Choose the best games and casinos',
            description:
              'At this point a lot of you might wonder ”what the heck is Fun ID”. No worries, we are here to answer that question and any other you might have about the interesting payment service. Fun ID is a completely new e-wallet that combines account registration and money transfers. This means that you only need one application for online gaming - instead of having to download multiple ones.',
            media: {
              data: {
                attributes: {
                  alternativeText: null,
                  ext: '.png',
                  mime: 'image/png',
                  url: '',
                },
              },
            },
          },
        ],
      },
      background: {
        data: {
          attributes: {
            alternativeText: null,
            ext: '.png',
            mime: 'image/png',
            url: '',
          },
        },
      },
      logo: {
        data: {
          attributes: {
            alternativeText: null,
            ext: '.png',
            mime: 'image/png',
            url: '',
          },
        },
      },
    },
  },
};
