type NodeOptions = {
  className?: string;
  attrs?: Record<string, string>;
}

export class FunidElementsCreator {
  public static createNode(tagName: string, options?: NodeOptions): HTMLElement {
    const node: HTMLElement = document.createElement(tagName);

    if (options?.className) {
      node.className = options.className;
    }

    if (options?.attrs) {
      Object.entries(options.attrs).forEach(([key, value]) => {
        node.setAttribute(key, value);
      });
    }

    return document.createElement(tagName);
  }
}
