(function () {
  'use strict';

  controller.$inject = ['$compile'];

  function controller($compile) {
    function link(scope, element, attrs, ctrl, transclude) {
      const template = $compile(attrs.template1)(scope);

      element.replaceWith(template);
    }

    function compile(templateElement, templateAttrs) {}

    function template(elem, attr) {
      return $compile(attr.template1)({});
    }

    return {
      restrict: 'E',
      link,
    };
  }

  app.directive('selectTemplateGivenUserStatus', controller);
})();
