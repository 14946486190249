import '../../service/domain/promo.js';
import '../../service/rx/system$.js';
import '../../service/rx/promo$.js';
import '../../service/rx/ws$.js';
import '../../service/configs/ws-events.js';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaPromos' };

  controller.$inject = ['$scope', 'promo', 'system$', '$state', 'promo$', 'ws$', 'wsEvents'];

  function controller($scope, _promo, _system$, $state, _promo$, _ws$, _wsEvents) {
    $scope.promos = [];
    $scope.preloader = true;
    $scope.filter = $state.params.filter;
    $scope.name = $state.params.name;

    function getPromo() {
      _promo
        .list()
        .then(() => {
          $scope.promos = _promo.result;
        })
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onInit = () => {
      getPromo();
    };

    const subscription = merge(
      _promo$,
      _system$.pipe(filter((message) => message.action === 'statusChanged')),
      _ws$.pipe(filter(({ event }) => event === _wsEvents.bonusIssue)),
    ).subscribe(() => {
      getPromo();
    });

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
