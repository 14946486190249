(function () {
    'use strict';
    const directive = { name: 'getSocialLinks' };
    controller.$inject = ['user$', 'user'];
  
    function controller(_user$, _user) {
      function link(scope, element, attrs) {
        const parametr = scope.$eval(attrs[directive.name]);
        const getConfig = () => {
          scope.socials =
            $_CDN.socialLinks[_user?.status ? _user?.profile?.country : parametr.system?.code] ||
            $_CDN.socialLinks['GLOBAL'];
        };
  
        getConfig();
  
        _user$.subscribe(() => {
          getConfig();
        });
      }
  
      return {
        restrict: 'A',
        link: link,
      };
    }
  
    app.directive(directive.name, controller);
})(); 