import {
  SlButton
} from "./chunk.MR6SHCJO.js";

// src/components/button/button.ts
var button_default = SlButton;
SlButton.define("sl-button");

export {
  button_default
};
