(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    const opt = {
      min: 2,
      max: 15,
    };

    return {
      customValidators(scope, element, attrs, ngModel) {
        ngModel.$validators.min = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? value.length >= opt.min : true;
        };

        ngModel.$validators.max = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? value.length <= opt.max : true;
        };
      },
    };
  }

  app.factory('postcode', controller);
})();
