import '../../service/configs/config.js'
(function () {
  'use strict';

  controller.$inject = ['config'];

  function controller(_config) {
    return {
      request(config) {
        if (config.meta && config.meta.type === 'api') {
          config.headers['S2B-DEVICE-Type'] = _config.device.mobile ? 'mobile' : 'desktop';
        }
        return config;
      },
    };
  }

  app.factory('device', controller);
})();
