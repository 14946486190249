import '../../service/rx/promo-list-updated$.js';

(function () {
  controller.$inject = ['$timeout', 'promoListUpdated$'];

  function controller($timeout, _promoListUpdated$) {
    function link(scope, element, attrs) {
      let subscription = null;
      let promoListUpdateSubscription = null;

      const config = scope.$eval(attrs.settings);
      const onBeforeChange = scope.$eval(attrs.onBeforeChange);
      const sliderState = attrs['sliderState'] === 'slider-state' ? 'sliderState' : attrs['sliderState'];
      function startSlick($) {
        const slickness = $(element);
        let iAmSender = false;

        promoListUpdateSubscription = _promoListUpdated$.subscribe(() => {
          slickness.slick('refresh');
        });

        if (typeof window.select$ !== 'undefined') {
          select$.next(config.initialSlide || 0);

          subscription = window.select$.subscribe((index) => {
            if (iAmSender) {
              iAmSender = false;
            } else {
              slickness.slick('slickGoTo', index);
            }
          });
        }

        $timeout(() => {
          if (sliderState && !scope[sliderState]) {
            scope[sliderState] = { currentSlide: config.initialSlide || 0 };
          }

          slickness.slick(config);

          slickness.css('visibility', 'visible');

          slickness.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            $timeout(() => {
              scope.$apply(() => {
                if (typeof onBeforeChange === 'function') {
                  onBeforeChange(event, slick, currentSlide, nextSlide);
                }

                if (sliderState) {
                  scope[sliderState].currentSlide = currentSlide;
                  scope[sliderState].beforeChange = { currentSlide, nextSlide };
                }

                if (typeof window.select$ !== 'undefined') {
                  iAmSender = true;
                  select$.next(nextSlide);
                }
              });
            });
          });

          slickness.on('afterChange', (event, slick, currentSlide) => {
            $timeout(() => {
              scope.$apply(() => {
                if (sliderState) {
                  const prevSlide = scope[sliderState].beforeChange.currentSlide;
                  scope[sliderState].currentSlide = currentSlide;
                  scope[sliderState].afterChange = { currentSlide, prevSlide };
                }
              });
            });
          });

          scope.$parent['lindaSlick'] = {
            slickGoTo(index) {
              slickness.slick('slickGoTo', index);
            },
            get currentSlide() {
              return slickness.slick('slickCurrentSlide') || 0;
            },
          };
        });
      }

      Promise.all([import('jquery'), import('slick-carousel')]).then(([$]) => {
        startSlick($);
      });

      scope.$on('$destroy', () => {
        subscription?.unsubscribe();
        promoListUpdateSubscription.unsubscribe();
      });
    }

    return {
      restrict: 'E',
      link: link,
    };
  }

  app.directive('lindaSlick', controller);
})();
