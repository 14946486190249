import '../../../service/domain/account.js';
import '../../../service/helper.js';
import '../../../service/domain/popup.js';
(function () {
  'use strict';

  const component = { name: 'lindaProfileChangePasswordForm' };

  controller.$inject = ['$scope', 'account', 'helper', 'popup'];

  function controller($scope, _account, _helper, _popup) {
    $scope.preloader = false;
    $scope.formData = {};

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      _account
        .changePassword($scope.formData)
        .then(() => {
          form.$setSuccessful();
          _popup.close();
        })
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
