(function() {

  'use strict';
  const directive = {name: 'rabonaTrackPhoneError'};
  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const inputConfig = scope.$eval(attrs[directive.name]);
      element.bind('blur', () => {
        if(!scope.regFour[inputConfig.name].$valid){
          scope.track('Register_Popup','Phone_Fail', `Error ${inputConfig.error}`, {nonInteraction: true})
        }
      })
    }
    return {
      restrict: 'A',
      link:link
    };
  }

  app.directive( directive.name, controller );
})();
