(function () {
  'use strict';

  function controller() {
    function link(scope, element, attrs) {
      attrs.$observe('lazyBg', (data) => {
        const conf = scope.$eval(data);

        if (conf && conf.src) {
          let img = document.createElement('img');
          img.src = conf.src;

          img.onload = () => {
            element.addClass(conf.class).css('background-image', 'url(' + conf.src + ')');
            img.remove();
            img = null;
          };

          img.onerror = () => {
            if (conf.errorClass) {
              element.addClass(conf.errorClass);
            }
            img.remove();
            img = null;
          };
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('lazyBg', controller);
  controller.$inject = [];
})();
