(function () {
  'use strict';

  const directive = { name: 'mDatepicker' };

  controller.$inject = ['$state'];

  function controller($state) {
    const link = (scope, element, attrs) => {
      const config = scope.$eval(attrs[directive.name]);

      let defaultDate = scope.$eval(attrs.ngModel);
      let tapping = false;

      element.bind('touchstart', function (e) {
        tapping = true;
      });

      element.bind('touchmove', function (e) {
        tapping = false;
      });

      element.bind('touchend', function (e) {
        if (tapping && window.datePicker) {
          window.datePicker.show(
            {
              date: defaultDate ? new Date(defaultDate) : new Date(),
              mode: config.mode || 'date',
              minDate: config.minDate === 'today' ? new Date() : new Date(config.minDate) || '',
              maxDate: config.maxDate === 'today' ? new Date() : new Date(config.maxDate) || '',
              androidTheme:
                device.manufacturer === 'samsung'
                  ? window.datePicker.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT
                  : window.datePicker.ANDROID_THEMES.THEME_HOLO_LIGHT,
            },
            (date) => {
              if (date) {
                let newDate = formatDate(date);

                element[0].value = newDate;
                defaultDate = newDate;

                if (typeof config.onChange === 'function') {
                  config.onChange();
                }
              }
            }
          );
        }
      });

      function formatDate(date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }

        return [year, month, day].join('-');
      }
    };
    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
