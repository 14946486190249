import '../service/domain/user.js';
import '../service/domain/promo.js';
import '../service/domain/state.js';
import '../service/configs/config.js';
import '../service/rx/system$.js';
import '../service/domain/modal.js';
import '../service/rx/promo$.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'promoButton' };

  controller.$inject = ['$translate', '$window', 'user', 'promo', 'state', 'config', 'system$', 'modal', 'promo$'];

  function controller($translate, $window, _user, _promo, _state, _config, _system$, _modal, _promo$) {
    const link = (scope, element, attrs) => {
      $translate.onReady(() => {
        let subscription = null;
        let { promo, defaultKey, defaultAction } = scope.$eval(attrs[directive.name]);
        let requiredActionsData = transformArrayInHashTable(promo.requiredActions) || {};

        promo.hasOptIn = promo.requiredActions?.some((action) => action.type === 'OPT_IN');
        promo.isOptIn = promo.requiredActions?.some((action) => action.type === 'OPT_IN' && action.isActionDone);

        function setRequestOptions(isOptedIn) {
          return {
            targetId: promo.targetId,
            isOptedIn: isOptedIn,
            type: promo.subType,
            language: _config.currentLang,
          };
        }

        function updatePromoData(data) {
          requiredActionsData.OPT_IN.isActionDone = data.result.isOptedIn;
          promo.isOptIn = data.result.isOptedIn;
          scope.button = getButton();
        }

        const subscribeOnPromotion = function () {
          scope.isPromoButtonDisabled = true;
          const optInRequestOptions = setRequestOptions(true);
          _promo.subscription(optInRequestOptions).then((data) => {
            updatePromoData(data);
            _promo$.next(true);
            scope.isPromoButtonDisabled = false;
          });
        };

        scope.optOut = function () {
          const optOutRequestOptions = setRequestOptions(false);
          _modal
            .open('promotion-optout', promo)
            .then(() => {
              _promo.subscription(optOutRequestOptions).then((data) => {
                updatePromoData(data);
                _promo$.next(true);
              });
            })
            .catch((error) => {
              console.error(error);
            });
        };

        function getButton() {
          let buttonKey = defaultKey;
          let buttonAction = null;

          if (_user.status) {
            // 1 step - isOptIn
            if (requiredActionsData?.OPT_IN && !requiredActionsData?.OPT_IN.isActionDone) {
              buttonKey = `promo.action.${requiredActionsData.OPT_IN.type}`;
              buttonAction = subscribeOnPromotion;
              return configureButton(buttonKey, buttonAction);
            }
            // 2 step - other actions in requiredActions[]
            const requiredAction = promo.requiredActions?.find((item) => item.type !== 'OPT_IN' && !item.isActionDone);
            if (requiredAction) {
              buttonKey = `promo.action.${requiredAction.type}`;
              buttonAction = function () {
                _state.goto(requiredAction.type.toLowerCase());
              };
              return configureButton(buttonKey, buttonAction);
            }
            // todo change this code
            // 2.5 step - hardcode for successful deposit
            if (requiredActionsData?.DEPOSIT && requiredActionsData?.DEPOSIT.isActionDone) {
              buttonKey = `promo.action.deposit`;
              buttonAction = function () {
                _state.goto('deposit');
              };
              return configureButton(buttonKey, buttonAction);
            }
            // 3 step - value from CMS
            if (promo.private_button?.url) {
              buttonKey = `promo.action.${promo.private_button.url}`;
              buttonAction = function () {
                _state.goto(promo.private_button.url);
              };
              return configureButton(buttonKey, buttonAction);
            }
            // 4 step - default value
            buttonAction = function () {
              _state.goto(defaultAction);
            };
            return configureButton(defaultKey, buttonAction);
          }

          if (requiredActionsData?.OPT_IN) {
            buttonKey = `promo.action.${requiredActionsData.OPT_IN.type}`;
          }

          buttonAction = function () {
            _state.goToAuthorization();
          };

          return configureButton(buttonKey, buttonAction);
        }

        scope.button = getButton();

        const isQueryParamExist = new URLSearchParams($window.location.search).get('opt-in');
        if (isQueryParamExist && !_user.status) {
          _state.goto('login');
        }
        function isOptInByExternalLink() {
          return isQueryParamExist && _user.status && promo.subType && !promo.isOptIn;
        }

        scope.$watch('promo', (newValue) => {
          if (newValue) {
            promo = newValue;
            promo.hasOptIn = promo.requiredActions?.some((action) => action.type === 'OPT_IN');
            promo.isOptIn = promo.requiredActions?.some((action) => action.type === 'OPT_IN' && action.isActionDone);
            requiredActionsData = transformArrayInHashTable(promo.requiredActions);

            if (isOptInByExternalLink()) {
              subscribeOnPromotion();
              return;
            }
            scope.button = getButton();
          }
        });

        function configureButton(key, action) {
          return {
            text: $translate.instant(key),
            action: action,
          };
        }

        function transformArrayInHashTable(array) {
          return array?.reduce((acc, obj) => {
            acc[obj.type] = obj;
            return acc;
          }, {});
        }

        subscription = _system$
          .pipe(
            filter((message) => message.action === 'statusChanged')
          )
          .subscribe(() => {
            scope.button = getButton();
          });

        scope.$on('$destroy', () => {
          subscription.unsubscribe();
        });
      });
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
