import '../../../service/domain/promo.js';
import '../../../service/rx/system$.js';
import '../../../service/rx/promo$.js';
import '../../../service/rx/ws$.js';
import '../../../service/configs/ws-events.js';
import '../../../service/rx/promo-list-updated$.js';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';

(function () {
  controller.$inject = ['$scope', 'promo', 'system$', 'promo$', 'promoListUpdated$', 'ws$', 'wsEvents'];

  function controller($scope, _promo, _system$, _promo$, _promoListUpdated$, _ws$, _wsEvents) {
    $scope.collection = [];
    const options = {};

    function getPromo() {
      $scope.preloader = true;
      _promo
        .list(options)
        .then((answer) => {
          $scope.collection = answer.result;
        })
        .finally(() => {
          $scope.preloader = false;
          _promoListUpdated$.next();
        });
    }

    this.$onChanges = function () {
      $scope.preloader = true;
      if (this.category && this.category.length) options.category = this.category;
      if (this.codes && this.codes.length) options.trigger_codes = this.codes;
      if (this.count) options.count = this.count;
      if (this.status) options.status = this.status;
      getPromo();
    };

    const subscription = merge(
      _promo$,
      _system$.pipe(filter((message) => message.action === 'statusChanged')),
      _ws$.pipe(filter(({ event }) => event === _wsEvents.bonusIssue)),
    ).subscribe(() => {
      getPromo();
    });

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  new Controller('promoList', controller, {
    category: '<',
    count: '<',
    codes: '<',
    status: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanPromoList${i}`, 'promoList');
  });
})();
