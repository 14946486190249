(function () {
  'use strict';

  app.filter('daysFromHours', controller);
  controller.$inject = ['$translate'];

  function controller($translate) {
    return (input, daysKey, hoursKey) => {
      const days = Math.floor(input / 24);
      const hours = input - days * 24;

      let str = '';
      if (days) {
        str += `${days}${$translate.instant(daysKey || 'g.days.short')} `;
      }
      if (hours) {
        str += `${hours}${$translate.instant(hoursKey || 'g.hours.short')}`;
      }
      return str;
    };
  }
})();
