(function () {
  'use strict';

  class RenderTemplateObserver {
    #intersectionObserver = null;
    #subscriptions = new Map();
    #config = { rootMargin: '300px' };

    constructor() {
      this.#intersectionObserver = new IntersectionObserver(this.#intersectionCallBack.bind(this), this.#config);
    }

    subscribe(element, callBack) {
      this.#subscriptions.set(element, callBack);
      this.#intersectionObserver.observe(element);

      return this.#unsubscribe.bind(this, element);
    }

    #unsubscribe(element) {
      this.#intersectionObserver.unobserve(element);
      this.#subscriptions.delete(element);
    }

    #intersectionCallBack(entries) {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting) this.#callCallBack(target);
      });
    }

    #callCallBack(element) {
      const callBack = this.#subscriptions.get(element);

      if (callBack) callBack();
    }
  }

  function RenderTemplateObserverFactory() {
    return new RenderTemplateObserver();
  }

  app.factory('renderTemplateObserver', RenderTemplateObserverFactory);
})();
