import '../../../service/domain/documentVerification.js';
(function () {
  controller.$inject = ['$scope', 'verificationInfo$', '$timeout'];

  function controller($scope, _verificationInfo$, $timeout) {
    $scope.item = {};
    $scope.itemid = null;
    $scope.itemError = false;
    $scope.documentInfo = {
      size: null,
      type: '',
    };
    let byteInKb = 1024;
    let byteInMb = Math.pow(byteInKb, 2);

    $scope.remove = () => {
      _verificationInfo$.next({
        event: 'removeItem',
        data: $scope.itemid,
      });
    };

    const checkFileSize = (sizeInBite) => {
      if (sizeInBite >= byteInMb) {
        $scope.documentInfo.size = (sizeInBite / byteInMb).toFixed(1);
        $scope.documentInfo.type = 'mb';
        return;
      }
      if (sizeInBite >= byteInKb) {
        $scope.documentInfo.size = (sizeInBite / byteInKb).toFixed(1);
        $scope.documentInfo.type = 'kb';
        return;
      }
      $scope.documentInfo.size = sizeInBite;
      $scope.documentInfo.type = 'b';
    };

    this.$onChanges = () => {
      $scope.item = this.item;
      checkFileSize(this.item.size);
      $scope.itemid = this.itemid;
      $scope.itemError = Object.keys(this.item.errors).length !== 0;
    };
  }

  new Controller('documentVerificationItem', controller, {
    item: '<',
    itemid: '<',
  });
})();
