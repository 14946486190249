class MetaCharsetService {
  static $inject = ['$document', '$location'];
  constructor($document, $location) {
    this.$document = $document[0];
    this.$location = $location;
    this.charsetRef =
      this.$document.querySelector('meta[charset="UTF-8"]') || this.$document.querySelector('meta[charset="utf-8"]');
    if (this.charsetRef === null) {
      this.create();
    }
  }

  create() {
    this.charsetRef = this.$document.createElement('meta');
    this.charsetRef.setAttribute('charset', 'UTF-8');
    return this.$document.head.insertBefore(this.charsetRef, this.$document.head.firstElementChild);
  }
}

app.service('metaCharsetService', MetaCharsetService);
