import $ from 'jquery';
(function () {
  'use strict';

  const directive = { name: 'hideAfterScroll' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs) => {
      const el = $(element);
      const options = scope.$eval(attrs[directive.name]);
      const elements = $(options.elements.join(','));
      const className = options.className || 'is-hidden';
      const height = options.minHeight || 48;

      let currentPosition = 0;
      let startPosition = 0;
      let scrollUp = false;
      let touchStart = false;
      let touchEnd = false;
      let isScrolling = undefined;

      el.scroll(function (e) {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(function () {
          scrollWork();
          resetParams();
        }, 100);

        scrollWork();
      });

      el.on('touchstart', function () {
        resetParams();
        touchStart = true;
      });

      el.on('touchend', function () {
        touchEnd = true;
      });

      function resetParams() {
        window.clearTimeout(isScrolling);
        currentPosition = 0;
        startPosition = 0;
        scrollUp = false;
        touchEnd = false;
        touchStart = false;
      }

      function hide() {
        elements.addClass(className);
      }

      function show() {
        elements.removeClass(className);
      }

      function scrollWork() {
        currentPosition = el.scrollTop();

        scrollUp = currentPosition < startPosition;

        if (!startPosition || scrollUp != currentPosition < startPosition) {
          startPosition = currentPosition;
        }

        if (currentPosition > startPosition + 10 || currentPosition < startPosition - 10) {
          if (currentPosition >= height && !scrollUp) {
            hide();
          }

          if (currentPosition < height || (scrollUp && !touchStart)) {
            show();
          }

          if (scrollUp && touchStart && touchEnd) {
            show();
          }
        }
      }
    };
    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
