(function () {

  'use strict';

  const directive = {name: 'addFocusOnClick'};

  controller.$inject = ['state', 'config', '$state'];

  function controller(state, _config, $state) {

    const link = (scope, element, attrs) => {

      const el = document.querySelectorAll('.focus-input');

      for (let i = 0; i < el.length; i++) {
        el[i].addEventListener('click', () => {
          let inputEl = el[i].querySelector('input[name=promocode]');
          inputEl.focus();
        });
      }
    };

    return {
      restrict: 'A',
      link
    };
  }

  app.directive(directive.name, controller);

})();
