import '../../../service/zipmler/zimpler.paynplay.js';
import '../../../service/configs/config.js';
import '../../../service/domain/user.js';
import '../../../service/domain/state.js';
import '../../../service/helper.js';
import '../../../service/rx/trustly$.js';
import '../../../service/configs/zimpler.js';
import '../../../service/rx/system$.js';

import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaZimplerPaynplayPage' };

  controller.$inject = [
    '$scope',
    'zimpler.paynplay',
    'config',
    'user',
    'state',
    'helper',
    'trustly$',
    '$timeout',
    'zimpler.config',
    '$window',
    'system$',
  ];

  function controller(
    $scope,
    _zimpler_paynplay,
    _config,
    _user,
    _state,
    _helper,
    _trustly$,
    $timeout,
    _zimpler_config,
    $window,
    _system$
  ) {
    let loginSubscription;
    const redirectToHomepage = () => _state.goto('home');

    if (_zimpler_paynplay.isDefaultJurisdiction) {
      if (_user.status || !_zimpler_config.enabled) {
        redirectToHomepage();
        return;
      }
      loginSubscription = _system$.pipe(filter(({ action }) => action === 'login')).subscribe(redirectToHomepage);
    }

    $scope.amount = _zimpler_paynplay.data.amount;
    $scope.step = _zimpler_paynplay.data.step;
    $scope.flow = _zimpler_paynplay.isDefaultJurisdiction ? 'resume_play' : _zimpler_paynplay.data.flow;
    $scope.paysystem = _zimpler_config;

    let subscription;

    $scope.submit = _helper.form.validator((form) => {
      if ($scope.amount > 0) {
        _zimpler_paynplay.data.amount = $scope.amount;
        _zimpler_paynplay.data.step = 1;
      }
    });

    function handler(event) {
      if (event.origin !== 'https://trustly.com') {
        return;
      }

      const data = JSON.parse(event.data);

      if (data.method === 'OPEN_APP') {
        $window.location.assign(data.appURL);
      }
    }

    this.$onInit = () => {
      subscription = _trustly$
        .pipe(filter((item) => item.action === 'step'))
        .subscribe((item) => {
          $timeout(() => {
            if (item.data === -1) {
              redirectToHomepage();
              return;
            }
            $scope.step = item.data;
          });
        });
      $window.addEventListener('message', handler, false);
    };

    this.$onDestroy = () => {
      _zimpler_paynplay.data.reset();
      subscription?.unsubscribe();
      loginSubscription?.unsubscribe();
      $window.removeEventListener('message', handler, false);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
