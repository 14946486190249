import '../../service/trustly/paynplay.js';
import '../../service/domain/popup.js';
import '@funid/auth';

const componentName = 'funidAuthFlow';

class Funid {
  static $inject = ['$scope', '$window', 'paynplay', 'contentBuffer', 'popup', '$element'];

  constructor($scope, $window, paynplay, contentBuffer, popup, $element) {
    this.$scope = $scope;
    this.$window = $window;
    this.paynplay = paynplay;
    this.contentBuffer = contentBuffer;
    this.popup = popup;
    this.$element = $element;
    $scope.openCode = this.openCode;
  }

  config = {
    paymethod: 'funid',
    amount: 0,
    currency: 'EUR',
    trigger_codes: null,
    locale: 'en_GB',
  };

  initCodeParams = () => {
    return this.paynplay.init(this.config).then(({ result }) => {
      this.orderData = result;
    });
  };

  callback = () => {
    const authParams = {
      order_id: this.orderData?.order_id,
      paymethod: 'funid',
      pep: false, // it’s hardcode because of the required fields of the endpoint
      gdpr_data: false, // it’s hardcode because of the required fields of the endpoint
      accept: true, // it’s hardcode because of the required fields of the endpoint
      gdpr_marketing: false, // it’s hardcode because of the required fields of the endpoint
    };
    this.paynplay.auth(authParams).then((data) => {
      this.popup.close();
    });
  };

  openCode = () => {
    this.initCodeParams()
      .then(() => {
        this.funIdElement.openDialog({
          qrcUrl: this.orderData.url,
          transactionId: this.orderData.order_id,
          langCode: $_CONFIG.currentLang,
        });
      })
      .then(() => {
        this.funIdElement.addEventListener('funid-auth-success', this.callback);
      });
  };

  $onInit = () => {
    const wrapper = this.$element[0];
    this.funIdElement = wrapper.querySelector('funid-auth');
  };
  $onChanges = () => {
    this.config.trigger_codes = this.triggerCode;
    if (this.avatar) {
      this.config.avatar = this.avatar;
    }
  };
  $onDestroy = () => {
    this.funIdElement.removeEventListener('funid-auth-success', this.callback);
  };
}

app.component(componentName, {
  controller: Funid,
  template: app.getTU(componentName),
  bindings: {
    triggerCode: '<',
    avatar: '<',
  },
});
