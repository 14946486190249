import '../../service/domain/modal.js';

(function () {
  'use strict';

  controller.$inject = ['modal'];

  function controller(_modal) {
    function link(scope, element, attrs) {
      element.on('tap', () => (_modal.answer = false));
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('lindaModalRejectOnTap', controller);
})();
