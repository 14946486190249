(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    const recaptchaUrl = $_CDN.recaptcha?.sdk;
    const recaptchaKey = $_CDN.recaptcha?.key;

    if (recaptchaUrl && recaptchaKey) {
      const scriptRecaptcha = document.createElement('script');
      const target = document.scripts[document.scripts.length - 1];

      scriptRecaptcha.src = `${recaptchaUrl}?render=${recaptchaKey}`;
      target.parentElement.insertBefore(scriptRecaptcha, target.nextSibling);

      const styleElement = document.createElement('style');
      styleElement.innerHTML = '.grecaptcha-badge{ visibility: hidden;}';
      document.body.append(styleElement);
    }

    return {
      execute() {
        const result = new Promise(function (resolve, reject) {
          if (window.grecaptcha) {
            grecaptcha.ready(function () {
              grecaptcha
                .execute(recaptchaKey, { action: 'submit' })
                .then((token) => {
                  resolve(token);
                })
                .catch(reject);
            });
          } else {
            reject();
          }
        });
        return result;
      },
    };
  }

  app.factory('recaptcha', controller);
})();
