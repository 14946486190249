import { fromEvent } from 'rxjs';
import { tap, delay, switchMap, filter } from 'rxjs/operators';

(function () {
  'use strict';
  const directive = { name: 'wazambaQuickDepositBehavior' };
  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      let isChanged = false;
      const config = scope.$eval(attrs[directive.name]);
      let isHovered = false;

      const mouseenter$ = fromEvent(element, 'mouseenter');
      const mouseleave$ = fromEvent(element, 'mouseleave');
      const click$ = fromEvent(element, 'click');
      const clickOutside$ = fromEvent(window, 'click');

      mouseenter$
        .pipe(
          tap(() => {isHovered = true;}),
          delay(400),
          filter(() => isHovered)
        )
        .subscribe((e) => {
          element.addClass(config.class);
        });

      mouseleave$
        .pipe(
          tap(() => {isHovered = false;}),
          delay(600)
        )
        .subscribe((e) => {
          if (!isChanged) {
            element.removeClass(config.class);
          }
        });

      click$
        .pipe(
          tap((e) => {isChanged = true; e.stopPropagation();}),
          switchMap((e) => clickOutside$)
        )
        .subscribe((e) => {
          isChanged = false;
          element.removeClass(config.class);
        });

      scope.$on('$destroy', function () {
        mouseenter$.unsubscribe();
        mouseleave$.unsubscribe();
        click$.unsubscribe();
        clickOutside$.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
