(function () {
  'use strict';
  const externalAppKey = 'mbapps'; // GET param string from mobile apps side
  const localParams = JSON.parse(window.localStorage.getItem('params'));
  const checkNavigator =  navigator.userAgent;

  const webView =
    checkNavigator.match(/FBAV/i) ||
    checkNavigator.match(/FBAN/i) ||
    checkNavigator.match(/Instagram/i) ||
    (document.referrer.match(/\/\/t\.co/i) && checkNavigator.match(/iPhone|iPad|iPod|Android/i)) ||
    (checkNavigator.match(/Android/i) && checkNavigator.includes('; wv')) ||
    (checkNavigator.match(/Android/i) && !navigator.userAgentData && !checkNavigator.match(/Firefox/i)) ||
    localParams.var1 === externalAppKey;

  $_CONFIG.browser = {
    webView: !!webView,
  };
})();
