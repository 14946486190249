import '../domain/http.js';
import '../../service/domain/user.js';

export const bonusApi = 'bonusApi';

class Api {
  static $inject = ['http', 'user'];

  constructor(http, _user) {
    this.http = http;
    this.user = _user;
  }

  getList() {
    return this.http.getData('userBonus')
      .then((answer) => {
        this.user.bonus = answer.result;
        return answer.result;
      }).catch(e => e);
  };

  cancel(bonusId) {
    return this.http.postData('userResetBonus', null, {bonus_id: bonusId})
      .then((answer) => {
        this.user.profile = answer.result;
        return this.getList();
      })
  };

  activate(bonusId) {
    return this.http.postData('userBonusActivate', null, {bonus_id: bonusId})
      .then((answer) => {
        this.user.profile = answer.result;
        return this.getList();
      })
  };
}

app.service(bonusApi, Api);
