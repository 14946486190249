import '../../service/domain/promo.js';
import '../../service/analytics/analytics.js';
import '../../service/configs/analytic-events.js';
import '../../service/rx/system$.js';
import '../../service/rx/ws$.js';
import '../../service/domain/platform.js';
import '../../service/configs/ws-events.js';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';

(function () {
  const component = { name: 'lindaPromoPage' };

  controller.$inject = [
    '$scope',
    'promo',
    '$state',
    'analytics',
    'analyticsEvents',
    'system$',
    'platform',
    'ws$',
    'wsEvents',
  ];

  function controller($scope, _promo, $state, analytics, _analyticsEvents, _system$, _platform, _ws$, _wsEvents) {
    $scope.promo = {};
    $scope.preloader = true;

    $scope.sendOpenReg = (area) => {
      analytics.send(_analyticsEvents.createAccountButton, `promo_page_${area}`);
    };

    const getPromo = () => {
      _promo
        .item({ name: $state.params.name })
        .then(({ result }) => {
          $scope.promo = result;
          _platform.version().then((version) => {
            if (version === 2) {
              $scope.promo.bonusSteps = removeNoActionAlias(result.bonusSteps);
            }
            if (version === 3) {
              $scope.promo.bonus_steps = removeNoActionAlias(result.bonus_steps);
            }
          });
          getOptInData(result);
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    getPromo();

    const removeNoActionAlias = (steps) =>
      steps.map((step) => {
        if (step.alias !== 'no_action') return step;
        return {
          ...step,
          alias: null,
        };
      });

    const getOptInData = (promo) => {
      $scope.isDepositDone = promo.requiredActions?.some((item) => item.type === 'DEPOSIT' && item.isActionDone);
      const requiredAction = promo.requiredActions?.find((item) => item.type !== 'OPT_IN' && !item.isActionDone);
      if (requiredAction) {
        $scope.startDate = requiredAction.startDate || null;
        $scope.timerCounter = new Date(requiredAction.startDate).getTime() - Date.now();
        $scope.isPromoStartSoon = new Date(requiredAction.startDate) >= Date.now();
      }
    };

    const subscription = merge(
      _system$.pipe(filter((message) => message.action === 'statusChanged')),
      _ws$.pipe(filter(({ event }) => event === _wsEvents.bonusIssue))
    ).subscribe(() => {
      getPromo();
    });

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
