import '../../service/domain/bonus.js';
(function () {
  'use strict';

  app.directive('lindaChangeBonusStatus', controller);

  controller.$inject = ['bonus', '$rootScope'];

  function controller(_bonus, $rootScope) {
    function link(scope, element, attrs) {
      scope.lindaChangeBonusStatus = {
        setStatus(options) {
          // options = { bonus_id: id, status: 'active' || 'canceled' }
          scope.preloader = true;
          _bonus.status(options).then(
            (answer) => {
              $rootScope.$broadcast('renew_bonus_list');
              scope.preloader = false;
            },
            (answer) => {
              scope.preloader = false;
            }
          );
        },
      };
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
