import '../../service/rx/resize$.js';
(function () {
  'use strict';

  app.directive('lindaSaveBlockRatio', controller);

  controller.$inject = ['resize$'];

  function controller(_resize$) {
    function link(scope, element, attrs) {
      scope.saveRatio = {};

      const dX = parseInt(attrs.deltax) || 0;
      const dY = parseInt(attrs.deltay) || 0;

      const elem = element[0];

      var ratio = 16 / 9;

      var watcher = setInterval(() => {
        if (attrs.lindaSaveBlockRatio) {
          var ratioAttr = attrs.lindaSaveBlockRatio.split(':');
          ratio = (ratioAttr[0] * 1) / (ratioAttr[1] * 1);
          resize();
          clearInterval(watcher);
        }
      }, 100);

      function resize() {
        var docWidth = window.innerWidth + dX;
        var docHeight = window.innerHeight + dY;
        var docRatio = docWidth / docHeight;
        var w, h;

        if (docRatio < ratio) {
          w = docWidth;
          h = Math.round(docWidth / ratio);
        } else if (docRatio > ratio) {
          w = Math.round(docHeight * ratio);
          h = docHeight;
        } else {
          w = docWidth;
          h = docHeight;
        }

        elem.style.width = w + 'px';
        elem.style.height = h + 'px';

        scope.saveRatio.width = w;
        scope.saveRatio.height = h;
        scope.$apply();
      }

      _resize$.subscribe(resize);
    }

    return {
      restrict: 'A',
      link: link,
    };
  }
})();
