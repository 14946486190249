(function(){
  'use strict';

  const directive = { name: 'slickRtl' };
  controller.$inject = ['$timeout'];

  function controller($timeout) {
    function link(scope, element, attrs){
      const curLang = window.$_CONFIG.currentLang;
      Promise.all([import('jquery'), import('slick-carousel')]).then(([$]) => {
        $timeout(() => {
          $(element).slick('slickSetOption', 'rtl', curLang === 'ar')
          if(curLang === 'ar'){
            $(element).addClass('slick-rtl')
          }
        }, 100)
      });
    }

    return {
      restrict: 'A',
      link: link,
    }
  }

  app.directive(directive.name, controller)
})();
