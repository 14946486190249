import '../../../service/configs/registration.js';
import '../../../service/domain/system.js';
import '../../../service/configs/config.js';
import '../../../service/helper.js';
import '../../../service/domain/modal.js';
import '../../../service/domain/account.js';
import '../../../service/domain/state.js';
import '../../../service/domain/popup.js';
import '../../../service/datepicker/birthday-datepicker';

(function () {
  'use strict';

  controller.$inject = [
    '$scope',
    'registration',
    'system',
    'config',
    'helper',
    'modal',
    'account',
    'state',
    'popup',
    'birthdayDatepicker',
  ];

  function controller(
    $scope,
    _registration,
    _system,
    _config,
    _helper,
    _modal,
    _account,
    _state,
    _popup,
    _birthdayDatepicker,
  ) {
    const vm = this;
    const shouldFetchCountries = _registration.config.countries;
    const cred = {};

    let formHasOwnRetype = false;

    $scope.preloader = false;

    $scope.page = 0;

    $scope.currencyList = [];
    $scope.countries = [];
    $scope.country = {};

    $scope.firstSubmit = true;

    $scope.formData = {
      currency: '',
      country: '',
      gender: 'M',
      accept: false,
      gdpr_data: false,
      gdpr_marketing: false,
      enable_trigger_codes: true,
      phone: '',
    };

    $scope.datepickerOptions = {
      minDate: _birthdayDatepicker.minDateValue,
      maxDate: _birthdayDatepicker.maxDateValue,
    };

    vm.$onInit = () => {
      _registration.addPromotionsToScope($scope);

      _registration.addAvatarsToScope($scope);

      if (shouldFetchCountries !== false) {
        prepareCountriesAndCurrencies();
      }

      if (!_config.production) {
        Object.assign($scope.formData, _registration.getFakeFormData());
      }

      if (typeof $_GET.email !== 'undefined') {
        $scope.formData.email = $_GET.email;
      }
    };

    $scope.nextPage = _helper.form.validator(() => {
      if (shouldAskGDPR()) {
        askGDPR();
      }

      Object.assign(cred, $scope.formData);
      $scope.page++;
    });

    $scope.lastPage = _helper.form.validator((form) => {
      $scope.preloader = true;

      setRetype();

      Object.assign(cred, $scope.formData);

      if (cred.enable_trigger_codes !== true) {
        delete cred.trigger_codes;
      }
      delete cred.enable_trigger_codes;

      _account
        .register(cred)
        .then(({ result: { login, prefix } }) => {
          if (login) {
            _registration.marketing(login, prefix);
          }

          _state.readyToRedirectAfterAuth$.next();

          if (angular.isFunction(_popup.cb)) {
            _popup.cb();
            _popup.cb = null;
          }

          setTimeout(() => {
            form.$setSuccessful();
          });
        })
        .catch(() => {
          $scope.page = 1;
          clearRetype();
        })
        .finally(() => ($scope.preloader = false));
    });

    const askGDPR = () => {
      $scope.firstSubmit = false;

      _modal
        .open('gdpr-registration')
        .then(() => ($scope.formData.gdpr_marketing = true))
        .catch(() => {});
    };

    const setRetype = () => {
      if (!$scope.formData.hasOwnProperty('retype')) {
        $scope.formData.retype = $scope.formData.password;
        return;
      }

      formHasOwnRetype = true;
    };

    const clearRetype = () => {
      if (!formHasOwnRetype) {
        delete $scope.formData.retype;
      }
    };

    const prepareCountriesAndCurrencies = () => {
      $scope.preloader = true;

      _system
        .countries()
        .then((answer) => {
          watchCountryToSetCurrenciesAndPhone();

          $scope.countries = answer.result;

          if (!$scope.formData.country) {
            $scope.formData.country = answer.default;
          }
        })
        .catch((error) => console.error(error))
        .finally(() => ($scope.preloader = false));
    };

    const watchCountryToSetCurrenciesAndPhone = () => {
      $scope.$watch('formData.country', (newVal) => {
        $scope.country = $scope.countries.filter((item) => item.code === newVal)[0] || {};
        $scope.currencyList = _registration.prepareCurrencyList($scope.country.currency) || [];
        $scope.formData.currency = $scope.currencyList[0].key;

        if ($scope.formData.phone.length <= 6) {
          $scope.formData.phone = `+${parseInt($scope.country.prefix.replace(/\-/g, ''), 10)}`;
        }
      });
    };

    const shouldAskGDPR = () => {
      const GDPRPage = _registration.config.GDPRPage || 1;
      return $scope.firstSubmit === true && $scope.formData.gdpr_marketing === false && GDPRPage === $scope.page;
    };
  }

  new Controller('batman-registration', controller, {});
})();

(function () {
  'use strict';
  new Batman('batmanRegistration1', 'batman-registration');
})();
