(function () {
  'use strict';

  const directive = { name: 'toggleLoginFormMode' };

  function controller() {
    function link(scope, element, attrs) {
      scope.mode = scope.$eval(attrs[directive.name]);

      element.on('click', () => {
        if (scope.mode === 'login') {
          scope.mode = 'email';
          return;
        }
        scope.mode = 'login';
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
