import { isValidPhoneNumber } from 'libphonenumber-js';
(function () {
  const directive = { name: 'maskPhoneV2' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs, ngModel) => {
      attrs.$set('type', 'tel');
      ngModel.$validators['phone'] = (modelValue, viewValue) => {
        const value = modelValue || viewValue;
        return value ? isValidPhoneNumber(value, scope.formData.country) : false;
      };

      ngModel.$validate();
    };

    return { restrict: 'A', require: '?ngModel', link };
  }

  app.directive(directive.name, controller);
})();
