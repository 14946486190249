import '../../../service/domain/withdraw.js';
import '../../../service/domain/popup.js';
import '../../../service/domain/modal.js';
(function () {
  'use strict';

  const component = { name: 'lindaCancelPendingWithdrawalsModal' };

  controller.$inject = ['withdraw', 'popup', 'modal'];

  function controller(_withdraw, _popup, _modal) {
    const subscription = _withdraw.cancelWithdrawal$.subscribe(() => {
      _popup.close();
      _modal.answer = false;
    });

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
