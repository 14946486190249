import '../../service/rx/timer$.js';
(function () {
  'use strict';

  controller.$inject = ['timer$', '$filter'];

  function controller(_timer$, $filter) {
    const link = (scope, element, attrs) => {
      const format = 'HH:mm:ss';
      let timer = 0;

      element[0].innerHTML = $filter('date')(new Date(0, 0, 0).setSeconds(timer), format);

      _timer$.subscribe((item) => {
        element[0].innerHTML = $filter('date')(new Date(0, 0, 0).setSeconds(timer++), format);
      });

      const stopObserving = attrs.$observe('lindaTimer', (newVal) => {
        timer = newVal || 0;
      });
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('lindaTimer', controller);
})();
