(function (t, e) {
  'function' == typeof define && define.amd
    ? define([], function () {
        return e();
      })
    : 'object' == typeof exports
    ? (module.exports = e())
    : e();
})(0, function () {
  function t() {
    'use strict';
    function t(t, e) {
      (this.name = t), (this.isActive = !0), (this.tables = {}), (this.priority = e || 0);
    }
    function e(t) {
      return Object.prototype.hasOwnProperty.call(a, t);
    }
    function n(t) {
      return angular.isString(t) && '' !== t;
    }
    function r(t) {
      if (!n(t)) throw new TypeError('Invalid type of a first argument, a non-empty string expected.');
      return e(t) && a[t].isActive;
    }
    function i() {
      var t = [];
      for (var e in a) a[e].isActive && t.push(a[e]);
      return (
        t.sort(function (t, e) {
          return t.priority - e.priority;
        }),
        t
      );
    }
    (t.prototype.parseUrl = function (t, e) {
      return angular.isFunction(t) ? t(this.name, e) : t.replace(/\{part\}/g, this.name).replace(/\{lang\}/g, e);
    }),
      (t.prototype.getTable = function (t, e, n, r, i, a) {
        if (this.tables[t]) return e.when(this.tables[t]);
        var o = this;
        return n(
          angular.extend(
            {
              method: 'GET',
              url: this.parseUrl(i, t),
            },
            r
          )
        ).then(
          function (e) {
            return (o.tables[t] = e.data), e.data;
          },
          function (n) {
            return a
              ? a(o.name, t, n).then(
                  function (e) {
                    return (o.tables[t] = e), e;
                  },
                  function () {
                    return e.reject(o.name);
                  }
                )
              : e.reject(o.name);
          }
        );
      });
    var a = {};
    (this.addPart = function (r, i) {
      if (!n(r)) throw new TypeError("Couldn't add part, part name has to be a string!");
      return e(r) || (a[r] = new t(r, i)), (a[r].isActive = !0), this;
    }),
      (this.setPart = function (r, i, o) {
        if (!n(r)) throw new TypeError("Couldn't set part.`lang` parameter has to be a string!");
        if (!n(i)) throw new TypeError("Couldn't set part.`part` parameter has to be a string!");
        if ('object' != typeof o || null === o)
          throw new TypeError("Couldn't set part. `table` parameter has to be an object!");
        return e(i) || ((a[i] = new t(i)), (a[i].isActive = !1)), (a[i].tables[r] = o), this;
      }),
      (this.deletePart = function (t) {
        if (!n(t)) throw new TypeError("Couldn't delete part, first arg has to be string.");
        return e(t) && (a[t].isActive = !1), this;
      }),
      (this.isPartAvailable = r),
      (this.$get = [
        '$rootScope',
        '$injector',
        '$q',
        '$http',
        function (o, s, u, l) {
          var c = function (t) {
            if (!n(t.key)) throw new TypeError('Unable to load data, a key is not a non-empty string.');
            if (!n(t.urlTemplate) && !angular.isFunction(t.urlTemplate))
              throw new TypeError('Unable to load data, a urlTemplate is not a non-empty string or not a function.');
            var e = t.loadFailureHandler;
            if (void 0 !== e) {
              if (!angular.isString(e)) throw new Error('Unable to load data, a loadFailureHandler is not a string.');
              e = s.get(e);
            }
            var r = [],
              a = i();
            return (
              angular.forEach(a, function (n) {
                r.push(n.getTable(t.key, u, l, t.$http, t.urlTemplate, e)), (n.urlTemplate = t.urlTemplate);
              }),
              u.all(r).then(
                function () {
                  var e = {};
                  return (
                    (a = i()),
                    angular.forEach(a, function (n) {
                      !(function t(e, n) {
                        for (var r in n)
                          n[r] && n[r].constructor && n[r].constructor === Object
                            ? ((e[r] = e[r] || {}), t(e[r], n[r]))
                            : (e[r] = n[r]);
                        return e;
                      })(e, n.tables[t.key]);
                    }),
                    e
                  );
                },
                function () {
                  return u.reject(t.key);
                }
              )
            );
          };
          return (
            (c.addPart = function (r, i) {
              if (!n(r)) throw new TypeError("Couldn't add part, first arg has to be a string");
              return (
                e(r)
                  ? a[r].isActive || ((a[r].isActive = !0), o.$emit('$translatePartialLoaderStructureChanged', r))
                  : ((a[r] = new t(r, i)), o.$emit('$translatePartialLoaderStructureChanged', r)),
                c
              );
            }),
            (c.deletePart = function (t, r) {
              if (!n(t)) throw new TypeError("Couldn't delete part, first arg has to be string");
              if (void 0 === r) r = !1;
              else if ('boolean' != typeof r)
                throw new TypeError('Invalid type of a second argument, a boolean expected.');
              if (e(t)) {
                var i = a[t].isActive;
                if (r) {
                  var u = s.get('$translate').loaderCache();
                  'string' == typeof u && (u = s.get(u)),
                    'object' == typeof u &&
                      angular.forEach(a[t].tables, function (e, n) {
                        u.remove(a[t].parseUrl(a[t].urlTemplate, n));
                      }),
                    delete a[t];
                } else a[t].isActive = !1;
                i && o.$emit('$translatePartialLoaderStructureChanged', t);
              }
              return c;
            }),
            (c.isPartLoaded = function (t, e) {
              return angular.isDefined(a[t]) && angular.isDefined(a[t].tables[e]);
            }),
            (c.getRegisteredParts = function () {
              var t = [];
              return (
                angular.forEach(a, function (e) {
                  e.isActive && t.push(e.name);
                }),
                t
              );
            }),
            (c.isPartAvailable = r),
            c
          );
        },
      ]);
  }
  return (
    angular.module('pascalprecht.translate').provider('$translatePartialLoader', t),
    (t.displayName = '$translatePartialLoader'),
    'pascalprecht.translate'
  );
});
