import { Subject } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return new Subject();
  }

  app.factory('system$', controller);
})();
