import '../../service/configs/config.js';
(function () {
  'use strict';

  ctrl.$inject = ['config'];

  function ctrl(_config) {
    function clear() {
      this.ref = undefined;
      this.onCloseCb = undefined;
      this.onLoadCb = undefined;
    }
    function onCloseDecoration(event) {
      if (typeof this.onCloseCb === 'function') this.onCloseCb(event);
      clear.call(this);
    }
    function onLoadDecoration(event) {
      if (typeof this.onLoadCb === 'function') this.onLoadCb(event);
    }

    let AppBrowser = {
      ref: undefined,
      open(url) {
        this.ref = cordova.ThemeableBrowser.open(url, '_blank', _config.mobileConfig.inAppBrowserConfig);
        this.ref.addEventListener('exit', onCloseDecoration.bind(this));
        this.ref.addEventListener('loadstart', onLoadDecoration.bind(this));
        return this;
      },
      close(time) {
        if (typeof time === 'number' && !_config.production)
          setTimeout(() => {
            this.ref.close();
          }, time);
        else this.ref.close();
      },
      onExit(cb) {
        this.onCloseCb = cb;
        return this;
      },
      onLoad(cb) {
        this.onLoadCb = cb;
        return this;
      },
    };

    Object.defineProperty(AppBrowser, 'location', {
      set: function (url) {
        if (typeof url === 'string') {
          this.ref.executeScript({ code: `document.location = "${url}"` });
        }
      },
    });

    return AppBrowser;
  }

  app.factory('appBrowser', ctrl);
})();
