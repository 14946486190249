import '../../configs/config.js';

class HrefLangService {
  static $inject = ['$document', 'config'];

  constructor($document, config) {
    this.$document = $document[0];
    this.config = config;
  }

  check(language) {
    if (this.config.alternates.hasOwnProperty(language)) {
      return this.config.alternates[language].hreflang;
    }
    return language;
  }

  render() {
    const html = this.$document.documentElement;
    html.setAttribute('lang', this.check(this.config.currentLang));
  }
}

app.service('hrefLangService', HrefLangService);
