class OgDescriptionService {
  static $inject = ['$document'];

  constructor($document) {
    this.$document = $document[0];
    this.descriptionRef = this.$document.head.querySelector('meta[property="og:description"]');
    this.targetNode = this.$document.querySelector('meta[property="og:title"]');
    if (this.descriptionRef === null) {
      this.create();
    }
  }

  create() {
    this.descriptionRef = this.$document.createElement('meta');
    this.descriptionRef.setAttribute('property', 'og:description');
    return this.targetNode.after(this.descriptionRef);
  }

  update(description) {
    if (angular.isString(description)) this.descriptionRef.setAttribute('content', description);
  }
}

app.service('ogDescriptionService', OgDescriptionService);
