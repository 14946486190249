import '../../../service/domain/http.js';
import '../../../service/rx/sport-cards$.js';
(function () {
  'use strict';

  controller.$inject = ['http', '$q', 'sportCards$'];

  function controller(_http, $q, _sportCards$) {
    const card = {
      list(options) {
        const defer = $q.defer(),
          alias = 'sportCard',
          config = {
            params: options,
            meta: {
              type: 'api',
              alias,
            },
          };
        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
      open(options) {
        const defer = $q.defer(),
          alias = 'sportCard',
          config = {
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, options, config).then(
          (answer) => {
            _sportCards$.next({
              target: 'card',
              event: 'open',
              data: answer.data.result,
            });
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
    };

    return card;
  }

  app.factory('card', controller);
})();
