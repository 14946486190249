import '../../service/configs/config.js'
(function () {
  'use strict';

  controller.$inject = ['config'];

  app.factory('jurisdictions', controller);

  function controller({ jurisdiction, jurisdictions }) {
    return {
      sw(cb) {
        if (jurisdiction === jurisdictions['sw']) cb();
      },
      de(cb) {
        if (jurisdiction === jurisdictions['de']) cb();
      },
    };
  }
})();
