import '../../../service/domain/historyBalanceFlow.js'
import '../../../service/rx/history$.js'
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

// https://mymth.github.io/vanillajs-datepicker/#/
(function () {
  'use strict';

  const directive = { name: 'lindaBalanceFlowPeriodFilterDatepicker' };

  controller.$inject = ['historyBalanceFlow', 'history$', '$timeout'];

  function controller(_historyBalanceFlow, _history$, $timeout) {
    const singleInputHTML = `
      <div class='balance-filter-inputs'>
        <div class='balance-filter-inputs__input-wrp'>
          <input type='text' size='9' readonly name='start' class='balance-filter-inputs__input'>
        </div>
      </div>
    `;
    const doubleInputHTML = `
      <div class='balance-filter-inputs'>
        <div class='balance-filter-inputs__input-wrp'>
          <input type='text' size='9' readonly name='start' class='balance-filter-inputs__input'>
        </div>
        <span class='balance-filter-inputs__divider'>—</span>
        <div class='balance-filter-inputs__input-wrp'>
          <input type='text' size='9' readonly name='end' class='balance-filter-inputs__input'>
        </div>
      </div>
    `;
    const datepickerOptions = {
      minDate: new Date().setFullYear(new Date().getFullYear() - 5),
      maxDate: 'today',
      format: 'dd.mm.yyyy',
      autohide: true,
      maxView: 0,
    };

    function link($scope, $element) {
      const element = $element[0];
      const destroy$ = new Subject();

      let datepickerInstance = null;
      let currentMaxDays = _historyBalanceFlow.maxDays;

      const isSingleDate = () => currentMaxDays === 1;

      const updateModel = () => {
        let from;
        let to;

        if (isSingleDate()) {
          from = datepickerInstance.getDate().toISOString();
          to = from;
        } else {
          from = datepickerInstance.getDates()[0].toISOString();
          to = datepickerInstance.getDates()[1].toISOString();
        }

        $timeout(() => {
          $scope.ngModel.from = from;
          $scope.ngModel.to = to;
        });
      };

      const initSingleDatepicker = () => {
        element.innerHTML = singleInputHTML;

        const datepickerElement = element.querySelector('input');

        datepickerInstance = new Datepicker(datepickerElement, datepickerOptions);
        datepickerInstance.setDate('today');
        datepickerElement.addEventListener('changeDate', updateModel);
      };

      const initRangeDatepicker = () => {
        element.innerHTML = doubleInputHTML;
        datepickerInstance = new DateRangePicker(element, datepickerOptions);
        datepickerInstance.setDates('today', 'today');
        datepickerInstance.inputs.forEach((input) => {
          input.addEventListener('changeDate', updateModel);
        });
      };

      const destroyDatepicker = () => {
        datepickerInstance.destroy();
      };

      const initDatepicker = () => {
        if (isSingleDate()) {
          initSingleDatepicker();
          return;
        }

        initRangeDatepicker();
      };

      const reinitDatepicker = () => {
        destroyDatepicker();
        initDatepicker();
      };

      const onInit = () => {
        initDatepicker();

        _history$
          .pipe(
            takeUntil(destroy$),
            filter(({ action }) => action === 'maxDays')
          )
          .subscribe((incomeMaxDays) => {
            if (incomeMaxDays !== currentMaxDays) {
              currentMaxDays = incomeMaxDays;
              reinitDatepicker();
            }
          });
      };

      onInit();

      $scope.$on('$destroy', () => {
        destroyDatepicker();
        destroy$.next();
        destroy$.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
      require: 'ngModel',
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, controller);
})();
