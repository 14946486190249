import gamePageShop from '../service/game-page-shop.js';

(function () {
  'use strict';

  const directive = { name: 'handleGamePageShopTab' };

  controller.$inject = ['gamePageShop'];

  function controller(_gamePageShop) {
    function link(scope) {
      scope.activeTab = '';

      const activeTabSubscription = _gamePageShop.tabOpened$.subscribe((tabName) => {
        scope.activeTab = tabName;
      });

      scope.openShopTabByName = (tabName) => {
        _gamePageShop.setActiveTab(tabName);
      };

      scope.resetShopTab = () => {
        _gamePageShop.resetShopTab();
      };

      this.$onDestroy = () => {
        activeTabSubscription?.unsubscribe();
      };
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
