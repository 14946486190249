import '../../../service/domain/tournament.js';
(function () {
  controller.$inject = ['$scope', 'tournament'];

  function controller($scope, _tournament) {
    $scope.collection = [];

    $scope.casinoTournaments = [];

    $scope.futureCollection = [];
    $scope.activeCollection = [];
    $scope.pastCollection = [];

    this.$onChanges = function () {
      $scope.preloader = true;

      const options = {};
      if (this.category && this.category.length) options.category = this.category;
      options.showPlayersInfo = this.players;
      options.max_members = this.competitors;

      _tournament
        .list(options)
        .then((answer) => {
          $scope.collection = answer.result;

          $scope.casinoTournaments = answer.result.filter((tournament) => tournament.sport != true);

          $scope.futureCollection = answer.result.filter(
            (tournament) => tournament.status === 0 && tournament.sport != true,
          );
          $scope.activeCollection = answer.result.filter(
            (tournament) => tournament.status === 1 && tournament.sport != true,
          );
          $scope.pastCollection = answer.result.filter(
            (tournament) => tournament.status === 2 && tournament.sport != true,
          );
          // - add this scopes in https://jira.gosystem.io/browse/GREAT-9100
          $scope.activeSportCollection = answer.result.filter(
            (tournament) => tournament.status === 1 && tournament.sport == true,
          );
          $scope.pastSportCollection = answer.result.filter(
            (tournament) => tournament.status === 2 && tournament.sport == true,
          );
        })
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  new Controller('tournamentList', controller, {
    category: '<',
    players: '<',
    competitors: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanTournamentList${i}`, 'tournamentList');
  });
})();
