import '../../service/configs/registration.js';
import '../../service/domain/system.js';
import '../../service/helper.js';
import '../../service/domain/popup.js';
import '../../service/domain/remembered.js';
import '../../service/domain/auth-with-deposit.js';
(function () {
  'use strict';

  const component = {
    name: 'authWithDepositInitForm',
  };

  controller.$inject = ['$scope', 'registration', 'system', 'helper', 'popup', 'remembered', 'authWithDeposit'];

  function controller($scope, _registration, _system, _helper, _popup, _remembered, _authWithDeposit) {
    $scope.preloader = false;
    $scope.currencyList = [];
    const vm = this;
    $scope.formData = { amount: 0 };
    $scope.rememberedLogin = !!_remembered.login;

    const accountPreSaveData = _authWithDeposit.preSavedData();

    vm.$onInit = () => {
      prepareCurrencies();
    };

    const prepareCurrencies = () => {
      $scope.preloader = true;
      _system
        .countries()
        .then((answer) => {
          const countries = answer.result;
          $scope.country = countries.filter((item) => item.code === answer.default)[0] || {};
          $scope.currencyList = _registration.prepareCurrencyList($scope.country.currency) || [];
          $scope.formData.currency = $scope.currencyList[0].key;

          const val = $scope.country.currency[0].defaultDepositAmount;
          $scope.formData.amount = val;

          $scope.$watch('formData.currency', (newValue) => {
            const foundCurrency = $scope.country.currency.find((item) => item.code === newValue);
            if (foundCurrency) {
              $scope.formData.amount = foundCurrency.defaultDepositAmount;
            }
          });
        })
        .catch((error) => console.error(error))
        .finally(() => ($scope.preloader = false));
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      accountPreSaveData.set({
        ...accountPreSaveData.get(),
        ...$scope.formData,
        country: $scope.country.code,
      });

      _popup.open({ name: 'auth-with-deposit' });
    });

    vm.$onDestroy = () => {
      accountPreSaveData.set(null);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
