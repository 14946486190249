import '../../../service/domain/withdraw.js';
import '../../../service/domain/popup.js';

class WithdrawalItem {
  static $inject = ['$scope', 'withdraw', 'popup'];
  constructor($scope, withdraw, popup) {
    $scope.preloader = false;

    this.$onChanges = () => {
      $scope.item = this.item;
    }

    $scope.cancel = (id) => {
      $scope.preloader = true;
      withdraw.cancel({ alias: id }).finally(() => {
        $scope.preloader = false;
        popup.close();
      });
    }
  }
}

new Controller('container', WithdrawalItem, {item: '<'});
(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanWithdrawalItem${i}`, 'container');
  });
})();
