import { fromEvent } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    let body = document.querySelector('body');

    return fromEvent(body, 'keyup');
  }

  app.factory('keyup$', controller);
})();
