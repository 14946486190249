(function () {
  'use strict';

  const directive = { name: 'scrollBox' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs) => {
      element[0].setAttribute('style', 'overflow-y: scroll; -webkit-overflow-scrolling: touch;');

      if (typeof bodyScrollLock === 'object') {
        bodyScrollLock.disableBodyScroll(element[0]);

        scope.$on('$destroy', () => {
          bodyScrollLock.enableBodyScroll(element[0]);
        });
      }
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
