import '../service/event.js'
(function () {
  'use strict';

  const directive = { name: 'toggleClassOnEvent' };

  controller.$inject = ['event'];

  function controller(_event) {
    function link(scope, element, attrs) {
      const { event, selector, class: classNames } = scope.$eval(attrs[directive.name]);

      element.on(event || 'click', () => {
        const targetElements = selector ? document.querySelectorAll(selector) : [element[0]];
        const classNamesAsArray = classNames?.split(' ') || [];

        targetElements.forEach((targetElement) => {
          classNamesAsArray.forEach((className) => {
            targetElement.classList.toggle(className);
          });
        });
      });

      _event.create(event, element[0]);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
