export enum FunIdEnvType {
  Development = 'dev',
  Production = 'prod',
}

export class FundEnvService {
  private storageKey: string = 'funid_env';

  public getEnvType(): string | null {
    return localStorage.getItem(this.storageKey);
  }

  public isValidEnv(name: unknown): boolean {
    return (
      name === FunIdEnvType.Development || name === FunIdEnvType.Production
    );
  }

  public hasSpecifiedEnv(): boolean {
    return Boolean(localStorage.getItem(this.storageKey));
  }
}
