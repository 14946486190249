import '../../../service/domain/paysystem.js'
(function () {
  'use strict';

  controller.$inject = ['$scope', 'paysystem', '$timeout'];

  function controller($scope, _paysystem, $timeout) {
    $scope.preloader = true;
    $scope.collection = [];
    $scope.selected = undefined;
    $scope.selectedId = 0;
    $scope.changing = false;
    let category;

    this.$onInit = () => {
      category = this.category || 'standard';

      _paysystem
        .collection({ type: 'deposit', payment_category: category })
        .then((answer) => {
          $scope.collection = answer.result;
          $scope.selected = $scope.collection[0];
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    $scope.setSelected = (selected) => ($scope.selected = selected);

    $scope.$watch('selected', (newVal) => {
      $scope.changing = true;
      $timeout(() => {
        $scope.changing = false;
      }, 0);
    });
  }

  new Controller('cashboxDeposit', controller, { category: '<' });
})();

[1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
  new Batman(`batmanCashboxDeposit${i}`, 'cashboxDeposit');
});
