(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    let sessions = {};

    const add = (session) => {
      const newSessionsDictionary = {
        ...sessions,
        [session.id]: session,
      };

      sessions = newSessionsDictionary;
      return newSessionsDictionary;
    };

    const remove = (sessionId) => {
      const sessionToRemove = sessions[sessionId];

      if (sessionToRemove) {
        const newSessionsDictionary = { ...sessions };
        delete newSessionsDictionary[sessionId];

        sessions = newSessionsDictionary;
      }

      return sessionToRemove;
    };

    return {
      add,
      remove,
    };
  }

  app.factory('sessions', controller);
})();
