(function () {
  'use strict';

  const directive = { name: 'removeIfPwa' };

  controller.$inject = [];

  function controller() {
    function link($scope, $element, $attrs, $ctrl, $transclude) {
      let pwaPopupClosed = window.localStorage.getItem('pwaPopupClosed');
      let pwaApp = localStorage.getItem('pwa');

      $scope.close = () => {
        $element[0].nextElementSibling.remove();
        window.localStorage.setItem('pwaPopupClosed', 'true');
      };

      if (!pwaPopupClosed && !pwaApp) {
        $transclude($scope, (clone) => {
          $element[0].parentNode.insertBefore(clone[0], $element[0].nextSibling);
        });
      }
    }

    return {
      restrict: 'A',
      link,
      transclude: true,
    };
  }

  app.directive(directive.name, controller);
})();
