import '../../../service/domain/achievement.js'
import '../../../service/rx/achievement$.js'
import '../../../service/domain/popup.js'
(function () {
  controller.$inject = ['$scope', '$timeout', 'achievement', 'achievement$', 'popup'];

  function controller($scope, $timeout, _achievement, _achievement$, _popup) {
    $scope.preloader = false;
    $scope.achievement = {};

    // GET - /achievement/item {id, alias}
    // GET - /achievement/list {category, page, count, etc...}
    // POST - /achievement/take {id, alias}

    $scope.take = () => {
      $scope.preloader = true;
      const id = $scope.achievement.id;

      _achievement
        .take({ id })
        .then(
          (a) => {
            _achievement$.next({
              action: 'take',
              achievement: $scope.achievement,
            });
          },
          (a) => {}
        )
        .finally(() => {
          $scope.preloader = false;
        });
    };

    this.$onChanges = () => {
      $scope.achievement = this.item;
    };
  }

  new Controller('achievementItem', controller, {
    item: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanAchievementItem${i}`, 'achievementItem');
  });
})();
