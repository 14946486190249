import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

(function () {
  'use strict';

  const directive = { name: 'menuAnimation' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const bg = element[0].querySelector('.entrance-menu__bg-wrap');
      const label = element[0].querySelector('.entrance-menu__label-text');
      const offer = element[0].querySelector('.entrance-menu__title');

      gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
      gsap.defaults({ overwrite: false });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.entrance-menu',
            end: '+=700',
            scrub: true,
          },
        })
        .from(bg, { duration: 1, y: 200, opacity: 0 });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.entrance-menu',
            start: 'top 500',
            end: '+=1',
            scrub: true,
          },
        })
        .fromTo(label, { x: '100%', autoAlpha: 0 }, { duration: 1, x: 0, y: 0, autoAlpha: 1 });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: offer,
            start: 'top bottom',
            end: '+=300',
            scrub: true,
          },
        })
        .from(offer, { y: '10%', opacity: 0 });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
