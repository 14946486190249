(function () {
  'use strict';

  const directive = { name: 'ngDbtap' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs) => {
      var firstTap = false;
      var firstTapDate = null;

      element.on('touchend', function (e) {
        firstTap === false ? firstTapAction() : secondTapAction();
      });

      element.on('touchstart', function (e) {
        element.addClass('ng-tap--start');
      });

      element.on('touchend', function (e) {
        element.removeClass('ng-tap--start');
      });

      function firstTapAction() {
        firstTap = true;
        firstTapDate = Date.now();
      }

      function secondTapAction() {
        if (Date.now() - firstTapDate < 500) {
          doubleTapAction();
        }

        firstTap = false;
        firstTapDate = null;
      }

      function doubleTapAction() {
        scope.$apply(attrs[directive.name], element);
      }
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
