import '../service/gamification/sport-cards/team.js';
(function () {
  'use strict';

  const directive = { name: 'getSportTeamsToScope' };

  factory.$inject = ['team'];

  function factory(_team) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'teams';

      scope[variable] = {};

      const config = {};
      config.order = 'status_progress_desc';

      function getData(options) {
        _team
          .list(options)
          .then((a) => {
            scope[variable].__proto__ = _team.object;
          })
          .catch((e) => {});
      }

      getData(config);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
