class Entity {
  static $inject = ['$q', 'http'];
  constructor($q, http) {
    this.$q = $q;
    this.http = http;
  }

  getFeatureFlags() {
    return this.http.getData('entityInfo', {}, true).then(({ result }) => {
      return result?.options?.feature_flags || {};
    });
  }
}

app.service('entity', Entity);
