import '../service/configs/config.js';
(function () {
  'use strict';

  controller.$inject = ['config'];

  function controller(_config) {
    function link(scope, element, attrs) {
      const variable = attrs.getConfigToScope || 'config';

      scope[variable] = {};
      scope[variable].__proto__ = _config;
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('getConfigToScope', controller);
})();
