import '../seo/utils/seo-ranking.js';

class RobotsService {
  static $inject = ['$document', 'seoRanking'];

  constructor($document, seoRanking) {
    this.$document = $document[0];
    this.seoRanking = seoRanking;
    this.targetNode = this.$document.querySelector('meta[name="description"]');
  }

  init() {
    let robotsNode = this.$document.head.querySelector('meta[name="robots"]');
    if (robotsNode === null) {
      robotsNode = this.$document.createElement('meta');
      robotsNode.setAttribute('name', 'robots');
    }

    const robotsInstructions = Object.keys($_GET).length > 0 ? 'NOINDEX, FOLLOW' : 'INDEX, FOLLOW';
    robotsNode.setAttribute('content', robotsInstructions);
    return robotsNode;
  }

  update() {
    const isPageRanged = this.seoRanking.check();
    const robots = this.init();
    if (!isPageRanged) {
      if (robots.parentNode) robots.parentNode.removeChild(robots);
      return;
    }
    this.targetNode.after(robots);
  }
}

app.service('robotsService', RobotsService);
