(function () {
  controller.$inject = ['$scope', '$state'];

  function controller($scope, $state) {
    $scope.name = null;
    this.$onInit = () => {
      $scope.name = $state.params.name;
    };
  }

  new Controller('gameHall', controller, {});
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanGameHall${i}`, 'gameHall');
  });
})();
