import '../service/rx/system$.js';
(function () {
  'use strict';

  app.directive('chatOpen', controller);

  controller.$inject = ['system$'];

  function controller(_system$) {
    function link(scope, element, attrs) {
      element.on('click', _system$.next.bind(_system$, { action: 'open chat' }));
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
