import '../../service/domain/modal.js';

(function () {
  'use strict';

  controller.$inject = ['modal'];

  function controller(_modal) {
    function link(scope, element, attrs) {
      let tapping = false;

      element.bind('touchstart', function (e) {
        tapping = true;
        element.addClass('ng-tap--start');
      });

      element.bind('touchmove', function (e) {
        tapping = false;
      });

      element.bind('touchend', function (e) {
        element.removeClass('ng-tap--start');
        if (tapping) {
          _modal.answer = true;
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('lindaModalResolveOnTouch', controller);
})();
