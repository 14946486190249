import '../service/domain/system.js'
import '../service/domain/user.js'
import '../service/rx/system$.js'
import { filter } from 'rxjs/operators'

(function () {
  'use strict';

  const directive = { name: 'hideByCountryCode' };

  factory.$inject = ['system', 'user', 'system$'];

  function factory(_system, _user, _system$) {
    function link(scope, element, attrs) {
      const listOfLanguages = scope.$eval(attrs[directive.name]);

      let userCountryCode = null;

      function addTargetNode(userCountryCode) {
        if (listOfLanguages.indexOf(userCountryCode) === -1) {
          element[0].style.display = 'block';
          return;
        }
        element[0].style.display = 'none';
      }

      function showByCountryCode() {
        if (!_user.status) {
          _system.info().then(({ result }) => {
            userCountryCode = result.country.code.toLowerCase();
            addTargetNode(userCountryCode);
          });
        }
        if (_user.status) {
          userCountryCode = _user.profile.country.toLowerCase();
          addTargetNode(userCountryCode);
        }
      }

      showByCountryCode();

      _system$
        .pipe(filter((m) => m.action === 'statusChanged'))
        .subscribe(() => {
          showByCountryCode();
        });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
