import {
  SlDialog
} from "./chunk.LXCGA2AO.js";

// src/components/dialog/dialog.ts
var dialog_default = SlDialog;
SlDialog.define("sl-dialog");

export {
  dialog_default
};
