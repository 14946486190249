import '../../../service/trustly/paynplay.js';
import '../../../service/domain/popup.js';

(function () {
  'use strict';

  const component = { name: 'lindaFunidCode' };

  controller.$inject = ['$scope', '$window', 'paynplay', 'contentBuffer', 'popup'];

  function controller($scope, $window, _paynplay, _contentBuffer, _popup) {
    const params = {
      paymethod: 'funid',
      amount: 0,
      currency: _contentBuffer.read('popup').currency || 'EUR',
      trigger_codes: null,
      locale: 'en_GB',
    };

    $scope.iframeUrl = '/preloader.html';
    $scope.iframeSanbox = 'allow-forms allow-scripts allow-same-origin allow-popups';
    let orderData = null;

    function initFunId() {
      _paynplay.init(params).then((data) => {
        orderData = data.result;
        $scope.iframeUrl = data.result.url;
      });
    }

    function authenticateByFunId(response) {
      if (response.data.message === 'callback' && response.data.params.action === 'success') {
        const authParams = {
          order_id: orderData?.order_id,
          paymethod: 'funid',
          pep: false, // it’s hardcode because of the required fields of the endpoint
          gdpr_data: false, // it’s hardcode because of the required fields of the endpoint
          accept: true, // it’s hardcode because of the required fields of the endpoint
          gdpr_marketing: false, // it’s hardcode because of the required fields of the endpoint
        };
        _paynplay.auth(authParams).then((data) => {
          _popup.close();
        });
      }
    }

    this.$onChanges = function () {
      params.trigger_codes = this.triggerCode;
      if (this.avatar) {
        params.avatar = this.avatar;
      }

      initFunId();
    };

    $window.addEventListener('message', authenticateByFunId);

    this.$onDestroy = () => {
      $window.removeEventListener('message', authenticateByFunId);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      triggerCode: '<',
      avatar: '<',
    },
  });
})();
