(function () {
  controller.$inject = ['$document'];

  function controller($document) {
    function link() {
      $document.scrollTopAnimated(0, 400);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('scrollToTop', controller);
})();
