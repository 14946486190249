(function () {
  'use strict';

  const directive = { name: 'offline' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs) => {
      document.addEventListener(
        'offline',
        function () {
          element.css('display', 'block');
        },
        false
      );

      document.addEventListener(
        'online',
        function () {
          element.css('display', 'none');
        },
        false
      );
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
