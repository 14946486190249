import '../../service/domain/content-buffer.js';
import '../../service/analytics/analytics.js';
import '../../service/configs/analytic-events.js';
(function () {
  'use strict';

  app.factory('modal', controller);

  controller.$inject = ['$q', '$templateCache', 'contentBuffer', 'analytics', 'analyticsEvents'];

  function controller($q, $templateCache, _contentBuffer, analytics, _analyticsEvents) {
    let _answer = null,
      _defer = {},
      _name = '';

    const modal = {};

    let _h = document.querySelector('html');

    modal.open = function (name, data) {
      const d = data || {};

      _name = name;
      _defer = $q.defer();

      _contentBuffer.write('modal', JSON.parse(JSON.stringify(d)) || null);

      if (!$templateCache.get(modal.template)) {
        console.warn(`no such modal template: ${_name}`);
        _name = '';
        _defer.reject();
      } else {
        document.activeElement?.blur();
        _h.classList.add('linda-has-modal');
      }

      analytics.send(_analyticsEvents.modalOpen, name, 'No_Label', { nonInteraction: true });

      return _defer.promise;
    };

    Object.defineProperty(modal, 'current', {
      set(val) {},
      get() {
        return _name;
      },
    });

    Object.defineProperty(modal, 'template', {
      set(val) {},
      get() {
        return _name === '' ? '' : `/${_name}-modal.html`;
      },
    });

    Object.defineProperty(modal, 'answer', {
      set(val) {
        let data = {};

        if (typeof val === 'object') {
          data = val.data;
          val = val.answer;
        }

        _answer = val;
        switch (val) {
          case true:
            _defer.resolve(data);
            break;
          case false:
            _defer.reject(data);
            break;
          default:
            _defer.reject(data);
            break;
        }
        //                _contentBuffer.clear( 'modal' );
        _name = '';

        _h.classList.remove('linda-has-modal');
      },
      get() {
        return _answer;
      },
    });

    return modal;
  }
})();
