(function () {
  'use strict';

  app.factory('wsEvents', controller);

  function controller() {
    return {
      balanceUpdate: 'balance_update',
      balanceDetails: 'balance_details',
      bonusIssue: 'bonus_issue',
      logout: 'logout',
      bonusActivated: 'bonus_activated',
      promocode: 'promocode',

      newLogin: 'new_login',
      newRegistration: 'new_registration',
      newWin: 'new_win',
      newWinList: 'new_win_list',
      newBonus: 'new_bonus',

      achieve: 'achieve',
      achieveComplete: 'achieve_complete',

      message: 'message',
      messageOtimove: 'message_optimove',
      deposit: 'deposit',

      notificationCenter: 'notification_center',

      coolOffGameInActivated: 'cool_off_game_in_activated',
      coolOffGameInDeactivated: 'cool_off_game_in_deactivated',
      closeGameSession: 'close_game_session',

      fastTournamentUpdateLeaderboard: 'fast_tournament_update_leaderboard',

      leagueRewardWinners: 'league_reward',

      cashierStatus: 'cashier_status',

      newBonusCrabAttempts: 'new_bonus_crab_attempts',
      promoExtraBonus: 'promo_extra_bonus',
      balanceThresholdReached: 'balance_threshold_reached',
    };
  }
})();
