import 'air-datepicker/air-datepicker.css';

(function () {
  'use strict';

  const directive = { name: 'airDatepicker' };

  controller.$inject = ['$filter', '$parse', 'config'];

  function controller($filter, $parse, _config) {
    const link = ($scope, $element, $attrs, $ctrl) => {
      const params = $scope.$eval($attrs[directive.name]);
      const defaultOptions = {
        single: {
          toggleSelected: false,
        },
        range: {
          multipleDatesSeparator: ' - ',
          range: true,
        },
      };

      let airDatepicker = null;
      let datepickerLanguage = null;

      const airDatepickerLoad = Promise.all([import('air-datepicker'), chooseLang(_config.currentLang)]);

      airDatepickerLoad.then(([{ default: AirDatepicker }, language]) => {
        datepickerLanguage = language.default;
        airDatepicker = new AirDatepicker(
          $element[0],
          Object.assign(
            {
              locale: datepickerLanguage,
              autoClose: true,
              dateFormat: 'dd.MM.yyyy',
              onSelect: function ({ date }) {
                $scope.$apply(function () {
                  if (angular.isArray(date)) {
                    const fromValueSetter = $parse(params.fromValue).assign;
                    const toValueSetter = $parse(params.toValue).assign;
                    fromValueSetter($scope, $filter('date')(date[0], 'yyyy-MM-dd'));
                    toValueSetter($scope, $filter('date')(date[1], 'yyyy-MM-dd'));
                    return;
                  }
                  $ctrl.$setViewValue($filter('date')(date, 'yyyy-MM-dd'));
                });
              },
            },
            params.type ? defaultOptions[params.type] : defaultOptions.single,
            params?.datepickerOptions,
          ),
        );
      });

      async function chooseLang(lang) {
        try {
          const { [lang]: langImportFunction } = await import('air-datepicker/locale/*.js');
          if (langImportFunction) {
            return langImportFunction();
          }
          return await import('air-datepicker/locale/en.js');
        } catch (error) {
          console.error(error);
        }
      }

      $ctrl.$formatters.push((value) => {
        return $filter('date')(value, 'dd.MM.yyyy');
      });

      $scope.$on('$destroy', () => {
        airDatepicker.destroy();
      });
    };

    return {
      require: 'ngModel',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
