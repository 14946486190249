import '../../service/configs/config.js';
(function () {
  'use strict';

  const component = { name: 'lindaLangPopup' };

  mobileController.$inject = ['$scope', 'config'];

  function mobileController($scope, _config) {
    $scope.changeLang = function (currentLang) {
      _config.currentLang = currentLang;
      document.location = `#/${currentLang}/`;

      localStorage.setItem('lang', currentLang);
    };
  }

  webController.$inject = [];

  function webController() {}

  app.component(component.name, {
    controller: app.getCC(mobileController, webController),
    template: app.getTU(component.name),
  });
})();
