import '../../../service/gamification/sport-cards/squad.js';
import '../../../service/rx/sport-cards$';
import {filter} from 'rxjs/operators';
(function () {
  'use strict';

  controller.$inject = ['$scope', 'squad', 'sportCards$'];

  function controller($scope, _squad, _sportCards$) {
    $scope.collection = [];
    $scope.preloader = false;

    let subscription;

    function getSquad(options) {
      $scope.preloader = true;
      _squad
        .list(options)
        .then((a) => {
          $scope.collection = a.result;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = function () {
      const config = {};

      config.team = this.team;

      if (config.team) {
        getSquad(config);
      }

      subscription = _sportCards$
        .pipe(filter((m) => m.target === 'card' && m.event === 'open'))
        .subscribe((m) => {
          if (config.team) {
            getSquad(config);
          }
        });
    };

    this.$onDestroy = function () {
      subscription.unsubscribe();
    };
  }

  const bindings = {
    team: '<',
  };

  new Controller('sportSquadList', controller, bindings);
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSportSquadList${i}`, 'sportSquadList');
  });
})();
