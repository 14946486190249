(function () {
  const directive = {
    name: 'lindaSwiper',
  };

  controller.$inject = ['$q'];

  function controller($q) {
    const defered = $q.defer();

    const script = document.createElement('script');
    const target = document.scripts[document.scripts.length - 1];
    const src = 'https://unpkg.com/swiper@7.4.1/swiper-bundle.min.js';

    script.async = true;
    script.src = src;
    script.onload = onload;

    target.parentElement.insertBefore(script, target.nextSibling);

    function onload() {
      defered.resolve();
    }

    const link = (scope, element, attrs) => {
      const givenConfig = scope.$eval(attrs[directive.name]) || {};
      const name = scope.$eval(attrs['name']) || 'swiper';
      const defaultConfig = {};

      const config = Object.assign(defaultConfig, givenConfig);

      defered.promise
        .then((a) => {
          scope[name] = new Swiper(element[0], config);
        })
        .catch((e) => {});
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
