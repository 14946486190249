import '../../../service/domain/modal.js';
import '../../../service/helper.js';
import '../../../service/domain/content-buffer.js';
import '../../../service/zipmler/zimpler.paynplay.js';
import '../../../service/domain/system.js';
import '../../../service/configs/registration.js';
import '../../../service/analytics/p161.js';
import '../../../service/otp/otp-verification.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaZimplerRegistrationModal' };

  controller.$inject = [
    '$scope',
    'modal',
    'helper',
    'contentBuffer',
    'zimpler.paynplay',
    'system',
    'registration',
    'p161',
    'otpVerification'
  ];
  function controller($scope, _modal, _helper, _contentBuffer, _zimpler_paynplay, _system, _registration, _p161, otpVerification) {
    const vm = this;
    const params = _contentBuffer.read('modal');

    $scope.hide_bonuses = params.is_registration_bonuses_shown;
    $scope.person = params.profile;
    $scope.showOtp = otpVerification.isOtpFlowNeeded($scope.person.phone_verification_hash);
    $scope.delay = 0;
    $scope.preloader = false;

    $scope.formField = {
      name: { disabled: Boolean($scope.person.name) },
      surname: { disabled: Boolean($scope.person.surname) },
      email: { disabled: Boolean($scope.person.email) },
      phone: { disabled: Boolean($scope.person.phone) },
      city: { disabled: Boolean($scope.person.city) },
      postcode: { disabled: Boolean($scope.person.postcode) },
      address: { disabled: Boolean($scope.person.address) },
      gender: { disabled: Boolean($scope.person.gender) },
      birthday: { disabled: Boolean($scope.person.birthday) },
    };

    $scope.formData = {
      name: $scope.person.name,
      surname: $scope.person.surname,
      email: $scope.person.email,
      phone: $scope.person.phone,
      city: $scope.person.city,
      postcode: $scope.person.postcode,
      address: $scope.person.address,
      gender: $scope.person.gender || 'M',
      pep: false,
      gdpr_data: false,
      accept: false,
      gdpr_marketing: false,
      birthday: $scope.person.birthday,
      enable_trigger_codes: true,
    };

    vm.$onInit = () => {
      preparePhoneField();
      _registration.addPromotionsToScope($scope);
      _registration.addAvatarsToScope($scope);
    };

    $scope.registrationStep = 'firstStep';

    $scope.setStep = (step) => {
      $scope.registrationStep = step;
    };

    $scope.sendSms = (phone) => {
      $scope.preloader = true;
      otpVerification.sendSms(phone)
        .then(({result}) => {
          $scope.inputsCount = result.otp_code_size;
          if (!result.frozen_reason) {
            $scope.delay = result.next_attempt_delay * 1000;
            $scope.setStep('otpValidation');
            return;
          }
          if(result.frozen_reason === 6351) {
            $scope.inputsDisabled = true;
          }
          $scope.delay = new Date(result.frozen_to).getTime() - new Date().getTime();
          $scope.setStep('otpValidation');
        })
        .finally(() => {
          $scope.preloader = false;
        })
    }

    const subscription = otpVerification.otpVerified$
      .pipe(filter((item) => item.event === 'codeVerified'))
      .subscribe(({data}) => {
        submitVerificationData(data.phone_verification_hash);
      });

    const subscriptionOnInputs = otpVerification.otpVerified$
      .pipe(filter((item) => item.event === 'inputsAvailable'))
      .subscribe(() => {
        $scope.inputsDisabled = false;
      });

    const subscriptionVerification = otpVerification.otpVerified$
      .pipe(filter((item) => item.event === 'verificationBlocked'))
      .subscribe(({ data }) => {
        $scope.delay = new Date(data.frozen_to).getTime() - new Date().getTime();
        $scope.inputsDisabled = true;
      });

    $scope.timerCallback = () => {
      $scope.delay = 0;
      $scope.inputsDisabled = false;
    };

    function submitVerificationData(phone_verification_hash) {
      $scope.preloader = true;
      if(phone_verification_hash) {
        Object.assign($scope.formData, {phone_verification_hash})
      }
      if ($scope.formData.enable_trigger_codes !== true || $scope.hide_bonuses) {
        delete $scope.formData.trigger_codes;
      }
      delete $scope.formData.enable_trigger_codes;

      _zimpler_paynplay
        .auth($scope.formData)
        .then(() => {
          _p161.track('registration');
          _modal.answer = true;
        })
        .catch(() => {
          if ($scope.formData.trigger_codes) {
            $scope.formData.enable_trigger_codes = true;
          }
        })
        .finally(() => {
          $scope.preloader = false;
          otpVerification.otpVerified$.next(
            {
              event: 'preloader',
              data: {status: false}
            })
        });
    }

    $scope.submit = _helper.form.validator((form) => {
      submitVerificationData();
    });

    const preparePhoneField = () => {
      $scope.preloader = true;

      _system
        .countries()
        .then((a) => {
          const country = a.result.filter((item) => item.code === a.default)[0] || {};

          if (!$scope.formData.phone && typeof country.prefix === 'string') {
            $scope.formData.phone = `+${parseInt(country.prefix.replace(/\-/g, ''), 10)}`;
          }
        })
        .catch((e) => console.error(e))
        .finally(() => ($scope.preloader = false));
    };

    vm.$onDestroy = () => {
      subscription.unsubscribe();
      subscriptionOnInputs.unsubscribe();
      subscriptionVerification.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
