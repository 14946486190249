import '../../../service/domain/system.js';
import '../../../service/domain/state.js';
import '../../../service/domain/popup.js';
(function () {
  'use strict';

  const directive = { name: 'zimplerFastDepositButton' };

  factory.$inject = ['system', 'state', 'popup'];

  function factory(_system, _state, _popup) {
    function link(scope, element, attrs) {
      element.on('click', (e) => {
        _state.goto('paynplay');
        _popup.close();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
