import '../../service/mobile-app/taptic-engine.js';
(function () {
  'use strict';

  const directive = { name: 'mHref' };

  controller.$inject = ['$state', 'tapticEngine'];

  function controller($state, _tapticEngine) {
    const link = (scope, element, attrs) => {
      const href = attrs[directive.name];
      const target = attrs['target'] || '_system';

      let tapping = false;

      element.bind('touchstart', function (e) {
        tapping = true;
      });

      element.bind('touchmove', function (e) {
        tapping = false;
      });

      element.bind('touchend', function (e) {
        if (tapping) {
          window.open(href, target);
        }
      });
    };
    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
