export class FunIdAuthLogger {
  private storageKey: string = 'funid_debug';

  private debugEnabled: boolean = Boolean(
    localStorage.getItem(this.storageKey)
  );

  public log(...value: unknown[]): void {
    if (!this.debugEnabled) {
      return;
    }

    const css: string = 'color: #6419E9';

    console.log('%c[FunId Auth]', css, ...value);
  }
}
