import '../service/event.js';
(function () {
  'use strict';

  const directive = { name: 'inputAutoWidth' };

  controller.$inject = ['event'];

  function controller() {
    function link(scope, element, attrs, ctrl) {
      const shadowEl = document.createElement('span');
      shadowEl.style.cssText = `
                display: inline;
                width: auto;
                height: 0;
                padding: 0;
                border: 0;
                margin: 0;
                overflow: hidden;
                position: absolute;
                font: ${getComputedStyle(element[0], null).font};
                letter-spacing: ${getComputedStyle(element[0], null).letterSpacing}`;
      document.body.appendChild(shadowEl);

      ctrl.$parsers.push((value) => {
        setSize(value);
        return value;
      });

      ctrl.$formatters.push((value) => {
        setSize(value);
        return value;
      });

      scope.$on('$destroy', () => {
        shadowEl.remove();
      });

      function setSize(value) {
        if (value) {
          shadowEl.innerText = value.toString() + '|';
          element[0].style.cssText = `width: ${shadowEl.offsetWidth}px;`;
        }
      }
    }

    return {
      require: 'ngModel',
      link,
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, controller);
})();
