import '../../../service/domain/tournament.js';
import '../../../service/rx/system$.js';

import { filter } from 'rxjs/operators';

(function () {
  controller.$inject = ['$scope', 'tournament', '$state', 'system$'];

  function controller($scope, _tournament, $state, _system$) {
    const tournamentName = $state.params.name;
    $scope.tournament = {
      name: tournamentName,
    };

    const config = {
      name: this.name,
      params: {
        max_members: this.members,
      },
    };

    function getTournamentItem(params) {
      $scope.preloader = true;
      _tournament
        .item(params)
        .then(({ result }) => {
          $scope.tournament = result;
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = () => {
      config.params.max_members = this.members;
      config.name = this.name;

      if (this.name?.length) {
        getTournamentItem(config);
      } else {
        $scope.tournament = this.item || {};
      }
    };

    const subscription = _system$
      .pipe(filter(({ action }) => action === 'statusChanged'))
      .subscribe(() => {
        getTournamentItem(config);
      });

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  new Controller('tournamentItem', controller, {
    item: '<',
    name: '<',
    members: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanTournamentItem${i}`, 'tournamentItem');
  });
})();
