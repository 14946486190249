import '../../service/rx/splash$.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  app.directive('splashscreen', controller);

  controller.$inject = ['splash$'];

  function controller(_splash$) {
    function link(scope, element, attrs) {
      _splash$
        .pipe(filter((m) => m === 'loaded'))
        .subscribe((m) => {
          if (navigator.splashscreen) {
            navigator.splashscreen.hide();
          }
        });
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
