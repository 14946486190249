import '../../service/rx/content-buffer$.js';
(function () {
  'use strict';

  controller.$inject = ['contentBuffer$'];

  function controller(_contentBuffer$) {
    const bucket = {
      popup: null,
      modal: null,
    };

    return {
      write(name, content) {
        bucket[name] = content;
        _contentBuffer$.next({
          name,
          content,
        });
      },
      clear(name) {
        bucket[name] = null;
      },
      read(name) {
        return bucket[name] || null;
      },
    };
  }

  app.factory('contentBuffer', controller);
})();
