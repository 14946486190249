import '../domain/tournament.js';
import '../rx/system$.js';
import '../domain/user.js';
import '../domain/popup.js';
import { filter } from 'rxjs/operators';

export const tournamentWelcome = 'tournamentWelcome';

class TournamentWelcome {
  static $inject = ['$timeout', 'tournament', 'system$', 'user', 'popup'];
  constructor($timeout, tournament, system$, user, popup) {
    this.$timeout = $timeout;
    this.tournament = tournament;
    this.system$ = system$;
    this.user = user;
    this.popup = popup;
  }

  tournament = {};
  tournamentName = 'euro2024';

  init() {
    this.system$.pipe(filter((message) => message.action === 'login')).subscribe(() => {
      this.tournament
        .item({ name: this.tournamentName })
        .then((answer) => {
          this.tournament = answer.result;
          this.initWelcomePopup();
        })
        .catch(() => {});
    });
  }

  convertToIsoString(date) {
    if (!date) return;
    const yourDate = new Date(date);
    return yourDate.toISOString().split('T')[0];
  }

  triggerPopup(previousLogin, currentDate) {
    if (previousLogin === currentDate) {
      return;
    }

    this.$timeout(() => {
      this.popup.open({ name: 'tournament-welcome', content: this.tournament });
    }, 1000);
  }

  initWelcomePopup() {
    const currentDate = +new Date();
    const previousLoginDate = this.user.profile.sessions_info.previous_ts * 1000 || null;
    if (previousLoginDate) {
      this.triggerPopup(this.convertToIsoString(previousLoginDate), this.convertToIsoString(currentDate));
    }
  }
}

app.factory(tournamentWelcome, TournamentWelcome);
