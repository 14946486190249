import '../../app/service/rx/splash$.js';
import { filter } from 'rxjs/operators';
(function () {
  'use strict';

  const directive = { name: 'scrollWhenHoldWithRtl' };
  let $;

  controller.$inject = ['splash$', '$timeout'];

  function controller(_splash$, $timeout) {
    function link(scope, element, attrs) {
      // Params:
      // el - String, selector of scrollable element
      // direction - String, can be: top | right | bottom | left
      // speed - Integer, pixels per seconds, instead offset, default 800 px/s
      // easing - String, default 'linear', can be 'swing' or 'linear'
      const params = {};
      const options = {};
      const rtl = window.$_CONFIG.currentLang === 'ar';

      function checkEndScroll() {
        const el = $(config.el)[0];
        let x = false;

        if (config.direction === 'top') {
          x = el.scrollTop === 0;
        }
        if (config.direction === 'right') {
          x = !rtl ?
            Math.floor(el.scrollWidth - el.clientWidth - el.scrollLeft) === 0 :
            Math.floor(el.scrollWidth - el.clientWidth + el.scrollLeft) === 0;
        }
        if (config.direction === 'bottom') {
          x = Math.floor(el.scrollHeight - el.clientHeight - el.scrollTop) === 0;
        }
        if (config.direction === 'left') {
          x = el.scrollLeft === 0;
        }

        if (x) {
          element.addClass('is-disable');
        } else {
          element.removeClass('is-disable');
        }
      }

      const config = scope.$eval(attrs[directive.name]);

      window.onload = () => {
        const observer = new MutationObserver(() => {
          checkEndScroll();
        });
        observer.observe(document.querySelector(config.el), { childList: true });
      };

      import('jquery').then((jQuery) => {
        $ = jQuery;

        if (config.direction && config.el) {
          element.on('mousedown touchstart', (e) => {
            const el = $(config.el)[0];
            const speed = config.speed || 800;

            if (config.direction === 'top') {
              params.scrollTop = 0;
              options.duration = el.scrollTop / speed;
            }
            if (config.direction === 'right') {
              params.scrollLeft = !rtl ?
                (el.scrollWidth - el.clientWidth || 10000) :
                -(el.scrollWidth - el.clientWidth || 10000);
              options.duration = (el.scrollWidth - el.scrollLeft - el.clientWidth) / speed;
            }
            if (config.direction === 'bottom') {
              params.scrollTop = el.scrollHeight - el.clientHeight || 10000;
              options.duration = (el.scrollHeight - el.scrollTop - el.clientHeight) / speed;
            }
            if (config.direction === 'left') {
              params.scrollLeft = 0;
              options.duration = !rtl ?
                el.scrollLeft / speed :
                -el.scrollLeft / speed;
            }
            options.duration *= 1000;
            options.easing = config.easing || 'linear';

            $(config.el).animate(params, options);
          });

          element.on('mouseup touchend', (e) => {
            $(config.el).stop(true);
          });

          $timeout(() => {
            checkEndScroll();
            let debounceId = null;
            $(config.el).scroll((e) => {
              clearTimeout(debounceId);
              debounceId = $timeout(() => {
                checkEndScroll();
              }, 200);
            });
          }, 0);

          const subscription = _splash$
            .pipe(filter((m) => m === 'loaded'))
            .subscribe((i) => {
              checkEndScroll();
            });

          scope.$on('$destroy', () => {
            subscription.unsubscribe();
          });
        }
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
