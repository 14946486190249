import '../service/configs/config.js';
import '../service/interceptor/*.js';
import '../libs/translatePartialLoader.js';

(function () {
  app.config(controller);

  controller.$inject = [
    '$locationProvider',
    '$translateProvider',
    '$stateProvider',
    '$urlRouterProvider',
    '$sceProvider',
    '$httpProvider',
    '$translatePartialLoaderProvider',
    'config',
  ];

  function controller(
    $locationProvider,
    $translateProvider,
    $stateProvider,
    $urlRouterProvider,
    $sceProvider,
    $httpProvider,
    $translatePartialLoaderProvider,
    _config,
  ) {
    if (!_config.mobileApp) {
      $locationProvider.html5Mode({ enabled: true });
    } else {
      $locationProvider.hashPrefix('');
    }

    $urlRouterProvider.otherwise(`/${_config.currentLang}/404`);

    const scrollToTop = [
      '$q',
      '$document',
      ($q, $document) => {
        const defer = $q.defer();
        $document.scrollTopAnimated(0, 400).then(() => {
          defer.resolve({});
        });
        return defer.promise;
      },
    ];

    const resolve_private = [
      '$q',
      '$state',
      function ($q, $state) {
        const defer = $q.defer(),
          interval = 50,
          watcher = setInterval(() => {
            if (_config.sessionStatus !== null) {
              switch (_config.sessionStatus) {
                case true:
                  defer.resolve({});
                  break;
                case false:
                  if ($state.current.name.length) {
                    defer.reject({ code: 400, reason: 'Unauthorized' });
                  } else {
                    defer.reject({ code: 401, reason: 'Unauthorized' });
                  }
                  break;
              }
              clearInterval(watcher);
            }
          }, interval);
        return defer.promise;
      },
    ];

    const get_system_info = [
      '$q',
      function ($q) {
        const defer = $q.defer(),
          interval = 50,
          watcher = setInterval(() => {
            if (_config.system_info) {
              defer.resolve({});
              clearInterval(watcher);
            }
          }, interval);
        return defer.promise;
      },
    ];

    const only_unauth = [
      '$q',
      function ($q) {
        const defer = $q.defer(),
          interval = 50,
          watcher = setInterval(() => {
            if (_config.sessionStatus !== null) {
              switch (_config.sessionStatus) {
                case true:
                  defer.reject({ code: 500, reason: 'Authorized' });
                  break;
                case false:
                  defer.resolve(true);
                  break;
              }
              clearInterval(watcher);
            }
          }, interval);
        return defer.promise;
      },
    ];

    //// redirect

    const redirectIfMobile = [
      '$q',
      function ($q) {
        const defer = $q.defer();
        const code = 310;
        defer.reject({ code: code, reason: 'mobile redirect' });
        return defer.promise;
      },
    ];

    const mobileLaunch = [
      '$q',
      function ($q) {
        const defer = $q.defer();
        let code = _config.mobileApp ? 307 : 308;
        if (_config.jurisdiction === 'spain') code = 309;
        defer.reject({ code: code, reason: 'mobile launch' });
        return defer.promise;
      },
    ];

    const updateCheck = [
      '$q',
      'system',
      function ($q, _system) {
        const defer = $q.defer();
        if (window.$_CONFIG.updateDialogIsShown === true) {
          defer.resolve({});
        } else {
          _system.mobile.updateCheck().then(
            (answer) => {
              defer.resolve(answer);
            },
            (answer) => {
              defer.resolve(answer);
            }
          );
        }
        return defer.promise;
      },
    ];

    const authResolver = [
      '$q',
      function ($q) {
        const defer = $q.defer(),
          interval = 50,
          watcher = setInterval(function () {
            if (_config.sessionStatus === true || _config.sessionStatus === false) {
              clearInterval(watcher);
              defer.resolve();
            }
          }, interval);
        return defer.promise;
      },
    ];

    const rootRouteConfig = {
      abstract: true,
      url: '/{lang:(?:' + _config.languages.join('|') + ')}?activation&restore&lindaanchor',
      resolve: {
        auth: authResolver,
        system_info: get_system_info,
      },
      template: '<ui-view></ui-view>',
      params: {
        activation: undefined,
        restore: undefined,
        lindaanchor: undefined,
      },
    };

    if (_config.mobileConfig.mobile) rootRouteConfig.resolve.actuality = updateCheck;

    $stateProvider
      .state('app', rootRouteConfig)
      .state('update', {
        abstract: true,
        template: '<ui-view></ui-view>',
      })
      .state('errors', {
        url: '/errors',
        template: '<ui-view></ui-view>',
      });

    _config.router.forEach(function (route) {
      const name = route.name;
      delete route.name;

      route.resolve = route.resolve || {};

      if (route.private === true) route.resolve.private = resolve_private;

      if (route.onlyUnauth === true) route.resolve.onlyUnauth = only_unauth;

      if (route.scrollToTop === true) route.resolve.scrollToTop = scrollToTop;

      if (route.gamePage === true && (_config.device.mobile === true || _config.mobileApp === true)) {
        if (route.params && route.params.redirectIfMobile && route.params.redirectIfMobile.length > 0) {
          route.resolve.redirectIfMobile = redirectIfMobile;
        } else {
          route.resolve.launch = mobileLaunch;
        }
      }

      $stateProvider.state(name, route);
    });

    if (_config.mobileConfig.mobile) {
      $httpProvider.interceptors.push('errors');
    }

    $httpProvider.interceptors.push('splash');
    $httpProvider.interceptors.push('auth');
    $httpProvider.interceptors.push('headers');
    $httpProvider.interceptors.push('device');
    $httpProvider.interceptors.push('bb');
    $httpProvider.interceptors.push('seon');

    $sceProvider.enabled(false);

    $translatePartialLoaderProvider.addPart('main');

    const translationCategory = _config.project ? `?category=${_config.project}` : ``;

    const urlTemplate =
      _config.locale === ''
        ? _config.apiUrl + `/{lang}/api/${_config.apiVersion}/lang/translation${translationCategory}`
        : _config.locale;

    $translateProvider.useLoader('$translatePartialLoader', { urlTemplate });
  }
})();
