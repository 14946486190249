import '../../service/domain/entity.js';

class ShowIfFunidDirective {
  static $inject = ['entity'];
  constructor(entity) {
    this.entity = entity;
  }
  link(scope, element, attrs, ctrl, transclude) {
    this.entity.getFeatureFlags().then(({ funid }) => {
      if (funid) {
        transclude(scope, (clone) => {
          element[0].parentNode.insertBefore(clone[0], element[0].nextSibling);
        });
      }
    });
  }
}

app.directive('showIfFunid', () => {
  return {
    restrict: 'A',
    controller: ShowIfFunidDirective,
    transclude: 'element',
    link: function (scope, element, attrs, ctrl, transclude) {
      ctrl.link(scope, element, attrs, ctrl, transclude);
    },
  };
});
