import '../../../service/domain/account.js';
import '../../../service/domain/modal.js';
import '../../../service/domain/state.js';
(function () {
  'use strict';

  const component = { name: 'lindaMissedDataCancellationModal' };

  controller.$inject = ['$scope', 'account', 'modal', 'state'];

  function controller($scope, _account, _modal, _state) {
    $scope.preloader = false;

    $scope.reject = () => {
      _modal.answer = false;
    };

    $scope.resolve = () => {
      $scope.preloader = true;

      _account
        .logout()
        .then(() => (_modal.answer = true))
        .catch(() => {})
        .finally(() => ($scope.preloader = false));
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
