import '../../service/configs/config.js'
(function () {
  'use strict';

  controller.$inject = ['config', '$injector', '$q'];

  function controller(_config, $injector, $q) {
    return {
      responseError(rejection) {
        if (rejection.status === 451) {
          $injector.get('$state').go('errors.451');
        }
        return $q.reject(rejection);
      },
    };
  }

  app.factory('errors', controller);
})();
