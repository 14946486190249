(function () {
  'use strict';

  const DOMAIN = window.location.origin;
  const config = {
    apiUrl: $_CONFIG.production && !$_CONFIG.mobileApp ? '' : $_CONFIG.domain,

    cdn: $_CONFIG.cdn,

    siteDomain: DOMAIN,
    mobileSiteDomain: $_CONFIG.mobileSiteDomain,
    siteName: $_CONFIG.siteName,
    mobile: $_CONFIG.mobile,
    locale: $_CONFIG.locale,
    languages: $_CONFIG.language,
    liveAgent: {},

    freshChat: $_CONFIG.freshChat,
    preChatFormConfig: $_CONFIG.preChatFormConfig,

    currentLang: $_CONFIG.currentLang,

    alternates: $_DICT.alternates || {},

    sessionStatus: null,
    userActivated: null,

    device: $_CONFIG.device,
    browser: $_CONFIG.browser,

    apiVersion: $_CONFIG.apiVersion,

    // todo: for sweden
    currency: $_CONFIG.currency, // .filter( o => o.key === 'SEK' && (new Date()).getFullYear() === 2019 ? false : true ),

    mobileApp: $_CONFIG.mobileApp,

    mobileConfig: $_CONFIG.mobileConfig || {},

    production: $_CONFIG.production,

    product: $_CONFIG.product,

    mid: $_CONFIG.mid,

    aliases: $_CONFIG.aliases || window.$_ALIAS || {},

    links: $_CONFIG.links,
    licenseId: $_CONFIG.licenseId,

    redirectToNotFound: $_CONFIG.redirectToNotFound, // true: go to 404 if seo data not found

    regily: $_CONFIG.regily || {},
    sentry: $_CONFIG.sentry || {},

    jurisdiction: $_CONFIG.jurisdiction,
    jurisdictions: $_CONFIG.jurisdictions,

    project: $_CONFIG.project,
    projectType: $_CONFIG.projectType,

    state: {
      isEntercash: false,
    },

    sport: {
      fixedBottom: $_CONFIG.sportFixedBottom,
      fixedTop: $_CONFIG.sportFixedTop,
      banners: $_CONFIG.banners || {},
      callbackUrl: $_CONFIG.sportBannerCallbackUrl || $_CONFIG.mobileSiteDomain,
      initializationParams: $_CONFIG.sportInitializationParams || {},
      insufficientbalancecallback: $_CONFIG.sportInsufficientBalanceCallback,
      hasSport: $_CONFIG.sport,
    },

    altenar: {
      widget: {
        integration: $_CONFIG.sportId || '',
      },
    },

    pwa: false, // Змінюється директивою linda-pwa у випадку коли сайт запущено в режимі progressive web application
    ios: Boolean(navigator.userAgent.match(/ipad|iphone/i)),

    slider: typeof $_CONFIG.slider === 'object' ? $_CONFIG.slider : {},

    googleAnalytics: $_CONFIG.googleAnalytics && $_CONFIG.googleAnalytics.appId ? $_CONFIG.googleAnalytics : {},
    hotjar: $_CONFIG.hotjar ? $_CONFIG.hotjar : {},

    feature: $_CONFIG.feature,

    isPayNPlayRegistrationAvailable: $_CONFIG.isPayNPlayRegistrationAvailable,

    sportBanners: $_CONFIG.sportBanners || null,

    // Disable notifications for codes in ignoreErrorCodes array
    ignoreErrorCodes: $_CONFIG.ignoreErrorCodes || [],
    pgw: $_CONFIG.pgw,
  };

  let _system_info = {};

  function getLACreds(type) {
    const key = $_CONFIG.liveAgent[config.currentLang] ? config.currentLang : 'default';
    return $_CONFIG.liveAgent[key][type];
  }

  Object.defineProperty(config, 'system_info', {
    set(v) {
      _system_info = v;
    },
    get() {
      return Object.assign({}, { platform_version: '2' }, _system_info);
    },
  });

  Object.defineProperty(config.liveAgent, 'sid', {
    set(v) {},
    get() {
      return getLACreds('sid');
    },
  });

  Object.defineProperty(config.liveAgent, 'bid', {
    set(v) {},
    get() {
      return getLACreds('bid');
    },
  });

  //    config.state.isEntercash = Boolean([ 'sv', 'fi' ].indexOf( config.currentLang ) + 1);

  const apiUrl = window.localStorage.getItem('api-url') || '';
  if (apiUrl.length) {
    config.apiUrl = apiUrl;
  }

  config.router = $_CONFIG.router.length ? $_CONFIG.router : window.$_ROUTER || [];

  app.constant('config', config);
})();
