(function () {
  'use strict';

  const o = window.location.origin;

  const env = 'production';

  if (typeof $_CDN === 'object') {
    $_CDN.env = env;

    if ($_CDN.language && $_CDN.language[env]) {
      $_CONFIG.language = $_CDN.language[env];
    }

    if (!window.$_DRAGON) {
      console.error('Dragon.js not loading, please check this problem!');
    }

    if (window.$_DRAGON?.language?.length) {
      $_CONFIG.language = $_DRAGON.language;
    }

    if (window.$_DRAGON?.requestInfo) {
      $_CONFIG.requestInfo = $_DRAGON.requestInfo;
    }

    if ($_CDN.licenseId) {
      $_CONFIG.licenseId = $_CDN.licenseId;
    }

    if ($_CDN.liveAgent) {
      $_CONFIG.liveAgent = $_CDN.liveAgent;
    }

    if ($_CDN.zendesk) {
      $_CONFIG.zendesk = { ...$_CDN.zendesk, ...$_CONFIG.zendesk };
    }

    if ($_CDN.freshChat) {
      $_CONFIG.freshChat = $_CDN.freshChat;
    }

    if ($_CDN.preChatFormConfig) {
      $_CONFIG.preChatFormConfig = $_CDN.preChatFormConfig;
    }
  }
})();
