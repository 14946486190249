import '../../service/domain/http.js'
import {holidayListJson} from './holidaList.js';
export const holidayApiService = 'holidayApiService';
const listCasino = holidayListJson;
// const listSport = holidayListJsonSport
// const bonusData = progreessData;

class holidayApi {
  static $inject = ['$q', 'http']

  constructor($q, http) {
    this.$q = $q;
    this.http = http;
  }

  list(params = {}) {
    const alias = 'holidayList', defer = this.$q.defer(), config = {
      params, meta: {
        type: 'api', alias,
      },
    };
    this.http
      .get(alias, config)
      .then(({data}) => {
        defer.resolve(data);
      })
      .catch(() => {
        defer.reject();
      })
      .finally(() => {
      });
    return defer.promise;
  };

  progress(params = {}) {
    const alias = 'holidayProgress', defer = this.$q.defer(), config = {
      params, meta: {
        type: 'api', alias,
      },
    };
    this.http
      .get(alias, config)
      .then(({data}) => {
        defer.resolve(data)
      })
      .catch(() => {
        defer.reject();
      })
      .finally(() => {
      });
    return defer.promise;
  };

  complete(data = {}) {
    const alias = 'holidayPromoComplete', defer = this.$q.defer(), config = {
      meta: {alias},
    };
    this.http
      .post(alias, data, config)
      .then((data) => {
        defer.resolve(data);
      })
      .catch((e) => {
        defer.reject(e);
      })
      .finally(() => {
      });
    return defer.promise;
  }
  start(data = {}) {
    const alias = 'holidayPromoStart', defer = this.$q.defer(), config = {
      meta: {alias},
    };
    this.http
      .post(alias, data, config)
      .then((data) => {
        defer.resolve(data);
      })
      .catch((e) => {
        defer.reject(e);
      })
      .finally(() => {
      });
    return defer.promise;
  }
}

app.service(holidayApiService, holidayApi);
