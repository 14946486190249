class LinkedDataService {
  static $inject = ['$document', '$location'];
  constructor($document, $location) {
    this.$document = $document[0];
    this.$location = $location;
    this.targetNode = this.$document.querySelector('meta[name="keywords"]');
  }

  init() {
    let linkedDataNode = this.$document.head.querySelector('script[type="application/ld+json"]');
    if (linkedDataNode === null) {
      linkedDataNode = this.$document.createElement('script');
      linkedDataNode.setAttribute('type', 'application/ld+json');
      this.targetNode.after(linkedDataNode);
    }
    return linkedDataNode;
  }

  update(linkedData) {
    const linkedDataScript = this.init();
    if (Object.keys(linkedData).length === 0) {
      if (linkedDataScript.parentNode) linkedDataScript.parentNode.removeChild(linkedDataScript);
      return;
    }
    linkedDataScript.innerHTML = angular.toJson(linkedData);
  }
}

app.service('linkedDataService', LinkedDataService);
