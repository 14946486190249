import '../../../service/domain/historyBalanceFlow.js';
import '../../../service/rx/history$.js';
import { filter } from 'rxjs/operators';
(function () {
  'use strict';

  controller.$inject = ['$compile', 'historyBalanceFlow', 'history$'];

  function controller($compile, _historyBalanceFlow, _history$) {
    function link(scope, element, attrs) {
      const div = document.createElement('div');
      let isSingleDay = false;
      const setEndDate = (endDate) => (scope.formData.date.to = endDate);
      const checkIfSingleDay = () => !!_historyBalanceFlow.maxDays;
      const changeRange = () => {
        isSingleDay = checkIfSingleDay();
        if(!isSingleDay){
          div.classList.add('linda-has-range');
          return;
        }
        setEndDate(scope.formData.date.from);
        div.classList.remove('linda-has-range');
      };

      div.innerHTML = `
                <div class='table-filter__date'>
                    <input type='date' ng-model='${attrs['ngModel']}.from' m-datepicker />
                </div>
                <div class='table-filter__date'>
                    <input type='date' ng-model='${attrs['ngModel']}.to' m-datepicker />
                </div>`;
      $compile(div)(scope);
      element.append(div);

      changeRange();
      _history$.pipe(filter((i) => i.action === 'maxDays')).subscribe(() => {
        if (isSingleDay === checkIfSingleDay()) return;
        setEndDate(new Date());
        changeRange()
      });

      scope.$watch('formData.date.from', (newVal) => {
        if (isSingleDay){
          setEndDate(scope.formData.date.from);
        }
      });
    }

    return {
      restrict: 'A',
      link,
      require: 'ngModel',
    };
  }

  app.directive('lindaBalanceFlowPeriodFilterNative', controller);
})();
