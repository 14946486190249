(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return {
      restrict: 'A',
      link: function (scope, el, attr) {
        const selector = attr['lindaPasswordTypeToggle'];
        const elementToggle = document.querySelector(selector);

        el.on('click', function () {
          elementToggle.setAttribute('type', elementToggle.getAttribute('type') === 'password' ? 'text' : 'password');
          el.toggleClass('toggle-type');
        });
      },
    };
  }

  app.directive('lindaPasswordTypeToggle', controller);
})();
