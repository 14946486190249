import '../../../service/domain/state.js'
import '../../../service/zignsec/zignsec.paynplay.js'
import '../../../service/domain/popup.js'
(function () {
  'use strict';

  const directive = { name: 'zignsecResumePlayButton' };

  factory.$inject = ['state', 'zignsec.paynplay', 'popup'];

  function factory(_state, _zignsec_paynplay, _popup) {
    function link(scope, element, attrs) {
      element.on('click', (e) => {
        _zignsec_paynplay.data.flow = 'resume_play';
        _zignsec_paynplay.data.amount = 0;
        _zignsec_paynplay.data.step = 0;
        _state.goto('paynplay');
        _popup.close();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
