import '../../service/configs/config.js';
import '../../service/domain/user.js';
import '../../service/domain/documentVerification.js';
(function () {
  const directive = { name: 'lindaDocumentVerificationOnfido' };

  window.onfidoLoaded = undefined;

  controller.$inject = ['config', 'user', 'verificationInfo$'];

  function controller(_config, _user, _verificationInfo$) {
    const onfidoSDK = `${$_CONFIG.cdnOnfidoFiles.js}`;

    const onfidoStyle = `${$_CONFIG.cdnOnfidoFiles.style}`;

    let callOnfido;
    let onfidoOut;

    const addScriptElement = (script) => {
      const element = document.createElement('script');

      element.src = `${script}?build=${$_CONFIG.build}`;
      element.async = false;
      element.onload = () => {
        onfidoLoaded = true;
        callOnfido();
      };

      document.head.appendChild(element);
    };

    const addStyleElement = (style) => {
      const head = document.getElementsByTagName('HEAD')[0];
      const element = document.createElement('link');

      element.rel = 'stylesheet';
      element.type = 'text/css';
      element.href = `${style}?build=${$_CONFIG.build}`;
      element.onload = () => {};
      head.appendChild(element);
    };

    addScriptElement(onfidoSDK);
    addStyleElement(onfidoStyle);

    function link(scope, element, attrs) {
      if (!_user.status) return;

      scope.preloader = true;

      const config = scope.$eval(attrs[directive.name]);

      callOnfido = () => {
        config.onComplete = (data) => {
          const verificationInfo = {
            providerData: data,
            requestedDocumentId: config.requestedDocumentId,
          };
          _verificationInfo$.next({
            event: 'onfidoResult',
            data: verificationInfo,
          });
        };

        config.language = $_DICT.onfido?.langReplacements?.[_config.currentLang] || 'en_US';

        onfidoOut = Onfido.init(config);
        scope.preloader = false;
      };

      if (onfidoLoaded) callOnfido();

      scope.$on('$destroy', () => {
        onfidoOut.tearDown();
        onfidoOut = null;
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
