import templates from '../../dist/templates.js';
(function () {
  app.getTU = function (name) {
    return templates[name] || '';
  };

  app.getCC = function (mc, wc) {
    return $_CONFIG.mobileApp ? mc : wc;
  };
})();
