import '../../../service/gamification/sport-cards/team.js';
import '../../../service/rx/sport-cards$';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'team', 'sportCards$'];

  function controller($scope, _team, _sportCards$) {
    $scope.team = {};
    $scope.preloader = false;
    let subscription;

    function getTeam(options) {
      $scope.preloader = true;
      _team
        .item(options)
        .then((a) => {
          $scope.team = a.result[0];
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = function () {
      const config = {};
      config.alias = this.alias;

      if (this.alias) {
        getTeam(config);
      } else if (this.team) {
        $scope.team = this.team;
      }
      // todo add functional for stars
      // $scope.stars = $scope.team.current === 100 ? $scope.team.progress.squads.current : $scope.team.progress.squads.current - 1;
    };

    this.$onInit = function () {
      subscription = _sportCards$
        .pipe(filter((m) => m.target === 'card' && m.event === 'open'))
        .subscribe((m) => {
          $scope.team = m.data.find((team) => $scope.team.alias === team.alias);
        });
    };

    this.$onDestroy = function () {
      subscription.unsubscribe();
    };
  }

  const bindings = {
    alias: '<',
    team: '<',
  };

  new Controller('sportTeamItem', controller, bindings);
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSportTeamItem${i}`, 'sportTeamItem');
  });
})();
