import '../service/domain/account.js';
import '../service/domain/state.js';
import '../service/domain/popup.js';
(function () {
  'use strict';

  controller.$inject = ['account', 'state', 'popup'];

  function controller(_account, _state, _popup) {
    const mobileLink = (scope, element, attrs) => {
      element.on('click', function () {
        _account.logout();
        _state.goto('home');
      });
    };

    const webLink = (scope, element, attrs) => {
      element.on('click', function () {
        this.classList.add('linda-has-preloader');
        _account
          .logout()
          .then((a) => {
            _popup.close();
            _state.goto('home');
          })
          .catch((e) => {})
          .finally(() => {
            this.classList.remove('linda-has-preloader');
          });
      });
    };

    return {
      restrict: 'A',
      link: app.getCC(mobileLink, webLink),
    };
  }

  app.directive('logout', controller);
})();
