import './config.js';
import '../domain/system.js';
import '../domain/user.js';
import '../rx/system$.js';
import '../rx/country-layout$.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  app.factory('countryLayout', controller);

  controller.$inject = ['config', 'system', 'user', 'system$', 'countryLayout$'];

  function controller(_config, _system, _user, _system$, _countryLayout$) {
    async function loadFeatures(feature) {
      const importFeature = await import('../../../src/config/features/*.json');
      if (importFeature.hasOwnProperty(feature)) {
        return await importFeature[feature]();
      }
      return null;
    }

    let countryConfig = {};

    const setValueInConfig = (code, layout) => {
      countryConfig[layout.type] = layout.countries.includes(code.toLowerCase());
    };

    const updateConfig = (layouts) => {
      if (!_user.status) {
        _system.info().then(({ result }) => {
          layouts.forEach((layout) => {
            setValueInConfig(result.country.code, layout);
          });
        });
        return;
      }

      layouts.forEach((layout) => {
        setValueInConfig(_user.profile.country, layout);
      });

      _countryLayout$.next(countryConfig);
    };

    const init = () => {
      loadFeatures('layoutFirst').then((result) => {
        if (result) {
          const layouts = result.layout || [];
          updateConfig(layouts);
          _system$
            .pipe(filter((message) => message.action === 'statusChanged'))
            .subscribe(() => {
              updateConfig(layouts);
            });
        }
      });
    };

    return {
      init,
      countryConfig,
    };
  }
})();
