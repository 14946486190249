import '../../../service/gamification/sport-cards/team.js';
import '../../../service/rx/sport-cards$';
import '../../../service/rx/system$.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'team', 'sportCards$', 'system$'];

  function controller($scope, _team, _sportCards$, _system$) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.category = '';
    let subscription;
    let authorizationSubscription;

    function getTeam(options) {
      $scope.preloader = true;
      _team
        .list(options)
        .then(({result}) => {
          const sortedByOrder = result.sort((a, b) => a.team_order - b.team_order);
          const sortedByProgress = sortedByOrder.sort((item1, item2) => item2.progress.general - item1.progress.general);
          $scope.collection = sortedByProgress;

          if (options.alias) {
            $scope.team = result[0];
          }
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = function () {
      const config = {};

      $scope.category = this.category;

      config.category = this.category;
      config.order = this.orderby;
      config.status = this.status;

      getTeam(config);

      subscription = _sportCards$
        .pipe(filter((m) => m.target === 'card' && m.event === 'open'))
        .subscribe((m) => {
          $scope.collection = m.data;
        });

      authorizationSubscription = _system$
        .pipe(filter((message) => message.action === 'login'))
        .subscribe((message) => {
          getTeam(config);
        });
    };

    this.$onDestroy = function () {
      subscription.unsubscribe();
      authorizationSubscription.unsubscribe();
    };
  }

  const bindings = {
    category: '<',
    orderby: '<',
    status: '<',
  };

  new Controller('sportTeamList', controller, bindings);
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSportTeamList${i}`, 'sportTeamList');
  });
})();
