(function () {
  'use strict';

  const component = { name: 'lindaRegistrationPopup' };

  cmpCtrl.$inject = ['$scope'];

  function cmpCtrl($scope) {}

  app.component(component.name, {
    controller: cmpCtrl,
    template: app.getTU(component.name),
  });
})();
