class OgTitleService {
  static $inject = ['$document'];

  constructor($document) {
    this.$document = $document[0];
    this.titleRef = this.$document.head.querySelector('meta[property="og:title"]');
    this.targetNode = this.$document.querySelector('meta[property="og:type"]');
    if (this.titleRef === null) {
      this.create();
    }
  }

  create() {
    this.titleRef = this.$document.createElement('meta');
    this.titleRef.setAttribute('property', 'og:title');
    return this.targetNode.after(this.titleRef);
  }

  update(title) {
    if (angular.isString(title)) this.titleRef.setAttribute('content', title);
  }
}

app.service('ogTitleService', OgTitleService);
