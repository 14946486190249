import '../domain/http.js';
import '../localStorage.js';

export const serviceName = 'affilate';

class Affilate {
  static $inject = ['http', 'localStorage'];

  constructor(http, localStorage) {
    this.http = http;
    this.localStorage = localStorage;
  }

  storedData = {};

  getData() {
    if (!Object.keys(this.storedData).length) {
      const affParams = this.localStorage.getAffiliateData();
      return this.http.getData('affiliateInfo', affParams).then((data) => {
        this.storedData = data;
        return this.storedData;
      });
    }
    return new Promise((resolve) => resolve(this.storedData));
  }
}

app.service(serviceName, Affilate);
