(function () {
  'use strict';

  controller.$inject = [];

  function controller() {}

  new Controller('uiWrapper', controller, {});
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanUiWrapper${i}`, 'uiWrapper');
  });
})();
