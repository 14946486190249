(function () {
  'use strict';

  // toggle-class-at-elements="{ selector: 'section h2', class:'is-open', event: 'click' trigger: true}"
  // You can add config to parent: ng-init="toggleClassAtElementsConfig = {...}".
  // This directive adds event on selected elements and toggle class on them.
  // All fields are optional BUT if don`t pass selector nothing will work.
  // selector - selector of elements                                                   default - ''
  // class - toggle class                                                              default - 'active'
  // event - toggle event                                                              default - 'click'
  // trigger - trigger of deleting class from other elements(boolean)                  default - false
  // scroll - put 'true' if you want autoscroll to target element(boolean)             default - false
  // scrollIntoViewOptions - options for scrollIntoView(see documentation on MDN)

  const directive = {
    name: 'toggleClassAtElements',
  };

  function controller() {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs[directive.name]) || scope[`${directive.name}Config`] || {};
      const selector = config.selector ? `${config.selector}` : '';
      const elems = selector ? [...element[0].querySelectorAll(selector)] : [];
      const configEvent = config.event || 'click';
      const className = config.class ? `${config.class}` : 'active';
      const trigger = config.trigger || false;
      const enabledAutoScroll = config.scroll || false;
      const scrollIntoViewOptions = config.scrollIntoViewOptions || { behavior: 'smooth' };

      const action = (event) => {
        const active = elems.find((el) => el.classList.contains(`${className}`));
        const target = event.currentTarget;
        if (trigger && active && active !== target) {
          active.classList.remove(className);
        }
        target.classList.toggle(className);
        if (enabledAutoScroll) {
          target.scrollIntoView(scrollIntoViewOptions);
        }
      };

      elems.forEach((el) => el.addEventListener(configEvent, (event) => action(event)));
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
