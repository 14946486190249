(function () {
  'use strict';

  const component = { name: 'lindaImg' };

  controller.$inject = ['$scope', '$element'];

  const createClasses = (baseClass) => {
    const states = ['loading', 'loaded', 'error'];
    return states.reduce((acc, state) => {
      acc[state] = `${baseClass}_${state}`;
      return acc;
    }, {});
  };

  function controller(scope, element) {
    const rootElement = element[0];
    const imgElement = rootElement.querySelector('img');
    let classes = {};

    const unsubscribe = () => {
      imgElement.removeEventListener('load', loadHandler, false);
      imgElement.removeEventListener('error', errorHandler, false);
    };
    const loadHandler = () => {
      rootElement.classList.remove(classes.loading);
      rootElement.classList.add(classes.loaded);
      unsubscribe();
    };
    const errorHandler = () => {
      rootElement.classList.remove(classes.loading);
      rootElement.classList.remove(classes.loaded);
      rootElement.classList.add(classes.error);
      unsubscribe();
    };

    this.$onChanges = () => {
      scope.source = this.source;
      scope.type = this.source?.split('.').pop();
      scope.title = this.title || '';
      scope.height = this.height || 200;
      scope.loading = this.loading ? this.loading : 'lazy';

      const baseClass = this.baseclass || 'lazy-image';
      classes = createClasses(baseClass);

      rootElement.classList.add(classes.loading);
      imgElement.addEventListener('load', loadHandler, false);
      imgElement.addEventListener('error', errorHandler, false);
    };

    scope.$on('$destroy', unsubscribe);
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      height: '<',
      source: '<',
      title: '<',
      loading: '<',
      baseclass: '<',
    },
  });
})();
