(function () {
  'use strict';

  const directive = { name: 'ngTap' };

  controller.$inject = ['$state'];

  function controller($state) {
    const link = (scope, element, attrs) => {
      const atStart = element[0].hasAttribute('ng-tap-start');
      const isState = attrs[directive.name + 'State'];
      const ngTapActive = attrs[directive.name + 'Active'];

      if (isState && ngTapActive) {
        const params = scope.$eval(attrs[directive.name + 'State']);
        const stateName = attrs[directive.name];

        if ($state.$current.self.name === stateName && $state.params.name === params.name) {
          element.addClass(ngTapActive);
        } else {
          element.removeClass(ngTapActive);
        }
      }

      let tapping = false;

      const doIt = () => {
        if (isState) {
          let params = scope.$eval(attrs[directive.name + 'State']);
          if (typeof params !== 'object') params = {};
          $state.go(attrs[directive.name], params);
        } else {
          scope.$apply(attrs[directive.name], element);
        }

        document.querySelector('[ng-tap-active]')?.classList.remove('active');
        element.addClass('active');
      };

      element.on('touchstart', function (e) {
        tapping = true;
        element.addClass('ng-tap--start');
        if (atStart && attrs[directive.name]) {
          doIt();
        }
      });

      element.on('touchmove', function (e) {
        element.addClass('ng-tap--move');
        tapping = false;
      });

      element.on('touchend', function (e) {
        element.removeClass('ng-tap--start');
        element.removeClass('ng-tap--move');
        if (!atStart && tapping && attrs[directive.name] && attrs[directive.name] != 'ng-tap') {
          doIt();
        }
      });
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
