import '../../service/rx/splash$.js';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  app.directive('lindaSplash', controller);

  controller.$inject = ['splash$'];

  function controller(_splash$) {
    function link(scope, element) {
      const subscription = _splash$
        .pipe(filter((m) => m === 'loaded'))
        .subscribe(() => {
          element.addClass('loaded');
        });

      scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
