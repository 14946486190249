const router = [
  ///////////////////
  // layer routes
  ///////////////////

  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk ></linda-app>',
  },
  // header
  {
    name: 'app.root.h1',
    abstract: true,
    template: '<linda-view-layer-one class="has-header linda-layer"></linda-view-layer-one>',
  },
  // header + footer
  {
    name: 'app.root.h1.f1',
    abstract: true,
    template: '<linda-view-layer-two class="linda-layer"></linda-view-layer-two>',
  },

  ////////////////////
  // empty template
  ////////////////////

  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback></linda-payment-callback>',
  },

  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/success',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'success',
    },
  },
  {
    name: 'app.root.withdraw-paymentcb-fail',
    url: '/withdraw-payment/fail',
    template: '<linda-withdrawal-payment-callback></linda-withdrawal-payment-callback>',
    params: {
      status: 'fail',
    },
  },

  // For redirecting to homepage after clicking home button in game iframe
  {
    name: 'app.root.callback',
    url: '/callback/:name',
    template: '<linda-callback-page/>',
  },

  //////////////////////////
  // header + footer
  /////////////////////////
  {
    name: 'app.root.h1.f1.home',
    url: '/',
    template: '<linda-homepage scroll-to-top></linda-homepage>',
  },
  {
    name: 'app.root.summer',
    url: '/seasons/summer',
    private: true,
    template: '<summer-promo-wrapper scroll-to-top="" ></summer-promo-wrapper>',
  },
  {
    name: 'app.root.summer-game',
    url: '/seasons-summer',
    private: true,
    template: `<summer-promo-page scroll-to-top=""></summer-promo-page>`,
  },
  {
    name: 'app.root.h1.f1.404',
    url: '/404',
    template: '<not-found class="full-screen"></not-found>',
  },

  //////////////////////////
  // Missed data
  /////////////////////////
  {
    name: 'app.root.missedData',
    url: '/missed-data',
    template: '<linda-missed-data-form/>',
  },
  //////////////////////////
  // Paynplay
  /////////////////////////
  {
    name: 'app.root.h1.f1.paynplay',
    url: '/fast-deposit',
    template: '<linda-zimpler-paynplay-page scroll-to-top class="full-screen"></linda-zimpler-paynplay-page>',
  },

  //////////////////////////
  // Info pages
  /////////////////////////
  {
    name: 'app.root.h1.f1.info',
    abstract: true,
    template: '<linda-view-layer-six scroll-to-top></linda-view-layer-six>',
  },
  {
    name: 'app.root.h1.f1.info.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap scroll-to-top></linda-sitemap>',
    params: {
      name: 'sitemap',
    },
  },
  {
    name: 'app.root.h1.f1.info.privacy-policy',
    url: '/privacy-policy',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
    },
  },
  {
    name: 'app.root.h1.f1.info.about',
    url: '/about-us',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
    },
  },
  {
    name: 'app.root.h1.f1.info.contact',
    url: '/contact-us',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
    },
  },
  {
    name: 'app.root.h1.f1.info.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
    },
  },
  {
    name: 'app.root.h1.f1.info.terms',
    url: '/rules',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
    },
  },
  {
    name: 'app.root.h1.f1.info.faq',
    url: '/faq',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
    },
  },
  {
    name: 'app.root.h1.f1.info.cookies-policy',
    url: '/cookies-policy',
    template: '<linda-info-page scroll-to-top></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
    },
  },

  /////////////////////////////////////
  // Collections
  /////////////////////////////////////

  {
    name: 'app.root.h1.f1.collection',
    abstract: true,
    //private:true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.h1.f1.collection.list',
    url: '/collections',
    template: '<batman-sport-team-list1  scroll-to-top="" ></batman-sport-team-list1>',
  },
  {
    name: 'app.root.h1.f1.collection.item',
    private: true,
    url: '/collections/:name',
    template: '<linda-sport-team-page  scroll-to-top="" ></linda-sport-team-page>',
    // template: '<batman-view-layer7 scroll-to-top=""></batman-view-layer7>',
  },

  {
    name: 'app.root.h1.f1.collection.guide',
    url: '/collections-terms',
    template: '<linda-view-layer-five  scroll-to-top="" ></linda-view-layer-five>',
  },

  /////////////////////////////////////
  // VIP
  /////////////////////////////////////
  {
    name: 'app.root.h1.f1.vip',
    url: '/vip',
    template: '<linda-vip class="full-screen" scroll-to-top="" ></linda-vip>',
  },

  /////////////////////////////////////
  // CUP
  /////////////////////////////////////
  {
    name: 'app.root.h1.f1.cup',
    private: true,
    url: '/champions-cup',
    template: '<linda-cup-page class="full-screen" scroll-to-top="" ></linda-cup-page>',
    // template: '<batman-view-layer6 scroll-to-top="" ></batman-view-layer6>',
  },

  /////////////////////////////////////
  // Holidays promos
  /////////////////////////////////////
  // {
  //   name: 'app.root.h1.f1.seasons',
  //   url: '/seasons/:name',
  //   template: `<linda-promo-wrapper class="holiday-promo-wrapper" scroll-to-top=""></linda-promo-wrapper>`,
  // },
  // {
  //   name: 'app.root.h1.f1.seasons',
  //   url: '/seasons/:name',
  //   template: '<easter-promo scroll-to-top="" ></easter-promo>',
  // },

  /////////////////////////////////////
  // Sport
  /////////////////////////////////////
  {
    name: 'app.root.h1.f1.sportpage',
    abstract: true,
    template: '<linda-sport-page></linda-sport-page>',
  },
  {
    name: 'app.root.h1.f1.sportpage.prelive',
    url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'prelive',
      isHashMode: false,
    },
  },
  {
    name: 'app.root.h1.f1.sportpage.live',
    url: '/live?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'live',
      isHashMode: false,
    },
  },
  {
    name: 'app.root.h1.f1.sportpage.virtual',
    url: '/virtual?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'vflm',
      isHashMode: false,
    },
  },
  {
    name: 'app.root.h1.f1.horse_racing',
    abstract: true,
    template: '<linda-view-layer-seven scroll-to-top=""></>linda-view-layer-seven',
  },
  {
    name: 'app.root.h1.f1.horse_racing.horse_racing',
    url: '/horse-racing',
    params: {
      category: 'horseRacing',
    },
  },
  {
    name: 'app.root.h1.f1.horse_racing.harness',
    url: '/harness',
    params: {
      category: 'harness',
    },
  },
  {
    name: 'app.root.h1.f1.horse_racing.greyhound',
    url: '/greyhound',
    params: {
      category: 'greyhound',
    },
  },
  {
    name: 'app.root.h1.f1.horse_racing.next-to-jump',
    url: '/next-to-jump',
    params: {
      category: 'nextToJump',
    },
  },

  /////////////////////////////////////
  // Promotions
  /////////////////////////////////////

  {
    name: 'app.root.h1.f1.promo',
    abstract: true,
    template: '<ui-view class="linda-view"></ui-view>',
  },
  {
    name: 'app.root.h1.f1.promo.list',
    url: '/promotions',
    template: '<linda-promos scroll-to-top=""></linda-promos>',
  },
  {
    name: 'app.root.h1.f1.promo.item',
    url: '/promotions/:name',
    template: '<linda-promo-page scroll-to-top=""></linda-promo-page>',
  },

  /////////////////////////////////////
  // Tournaments
  /////////////////////////////////////

  {
    name: 'app.root.h1.f1.tournament',
    abstract: true,
    template: '<ui-view class="linda-view" scroll-to-top></ui-view>',
  },
  {
    name: 'app.root.h1.f1.tournament.list',
    url: '/tournaments',
    template: '<batman-tournament-list1 players="true" competitors="5"></batman-tournament-list1>',
  },
  {
    name: 'app.root.h1.f1.tournament.page',
    url: '/tournaments/:name',
    template: '<linda-tournament-page></linda-tournament-page>',
    params: {
      hasLeagues: true,
    },
  },
  // THE MASTERS is hidden while the EURO 2024 is active
  // {
  //   name: 'app.root.h1.f1.sporttournament',
  //   url: '/sport-tournament',
  //   template: '<batman-view-layer2 scroll-to-top></batman-view-layer2>',
  //   params: {
  //     name: 'the-masters-rabona',
  //     hasLeagues: true,
  //   },
  // },

  /////////////////////////////////////
  // GAME HALL
  /////////////////////////////////////
  {
    name: 'app.root.h1.f1.casino',
    abstract: true,
    template: '<linda-view-layer-three></linda-view-layer-three>',
  },

  {
    name: 'app.root.h1.f1.casino.s1.last-played',
    url: '/games/continue-playing',
    private: true,
    template: '<batman-recently-played-list3 scroll-to-top ></batman-recently-played-list3>',
    params: {
      collection: 'games',
    },
  },
  {
    name: 'app.root.h1.f1.casino.s1',
    abstract: true,
    template: '<linda-view-layer-four></linda-view-layer-four>',
  },
  {
    name: 'app.root.h1.f1.casino.s1.provider',
    url: '/casino-providers/:name',
    template: '<batman-game-hall2 scroll-to-top></batman-game-hall2>',
    params: {
      providers: 'provider',
    },
  },
  {
    name: 'app.root.h1.f1.casino.s1.favourites',
    url: '/favourites',
    template: '<batman-game-box-favourites2 scroll-to-top></batman-game-box-favourites2>',
    params: {
      collection: 'games',
    },
  },

  /////////////////////////////////////
  //header + footer + sidebar profile
  //////////////////////////////////////

  {
    name: 'app.root.h1.f1.account',
    abstract: true,
    private: true,
    template: '<linda-profile-page class="profile-page"></linda-profile-page>',
  },
  {
    name: 'app.root.h1.f1.account.info',
    url: '/account',
    template: '<linda-profile-settings scroll-to-top=""></linda-profile-settings>',
  },
  {
    name: 'app.root.h1.f1.account.bonuses',
    url: '/account/my-bonuses',
    template: '<linda-profile-bonus-list scroll-to-top=""></linda-profile-bonus-list>',
  },
  {
    name: 'app.root.h1.f1.account.history',
    url: '/account/balance-history',
    template: '<linda-cashbox-balance-information scroll-to-top=""></linda-cashbox-balance-information>',
  },
  {
    name: 'app.root.h1.f1.account.achievement',
    url: '/account/weekly-achievement',
    template: '<linda-achievements-page scroll-to-top=""></linda-achievements-page>',
  },
  {
    name: 'app.root.h1.f1.account.responsible-gaming',
    url: '/account/responsible-gaming',
    template: '<linda-selfcontrol-wrapper scroll-to-top=""></linda-selfcontrol-wrapper>',
  },

  //Identify verification profile page
  {
    name: 'app.root.h1.f1.account.verification',
    url: '/account/verification',
    template: '<dvs-page scroll-to-top></dvs-page>',
  },

  {
    name: 'app.root.h1.f1.account.mybets',
    url: '/account/mybets',
    template: '<batman-ui-wrapper1 scroll-to-top=""></batman-ui-wrapper1>',
    params: {
      page: 'mybets',
      isHashMode: false,
    },
  },

  ////////////////////
  // header 2  + footer 2
  //////////////////

  {
    name: 'app.root.game',
    abstract: true,
    template: '<linda-game-page class="game-page-wrap"></linda-game-page>',
    gamePage: true,
  },

  // <MACHINES> ...
  {
    name: 'app.root.h1.claw_games',
    abstract: true,
    private: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.h1.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-view-layer1 />',
  },
  //- </MACHINES>

  /*
  {
    name: 'app.root.h1.bonus-crab',
    url: '/play/cash-crab',
    template: '<batman-view-layer1 />',
    private: true,
  },
  {
    name: 'app.root.h1.cash-crab',
    url: '/play/cash_crab',
    template: '<batman-view-layer2 />',
    private: true,
  },

   */
  {
    name: 'app.root.game.real',
    url: '/play/:name',
    template: '',
    private: true,
    params: {
      mode: 'real',
    },
  },
  {
    name: 'app.root.game.demo',
    url: '/game/:name',
    template: '<linda-demo-game-footer  game="game"></linda-demo-game-footer>',
    params: {
      mode: 'demo',
    },
  },

  ////////////////////
  // All payments page
  //////////////////

  {
    name: 'app.root.h1.f1.payments-list',
    url: '/payments-list',
    template: '<batman-banking-page1 scroll-to-top=""></batman-banking-page1>',
  },
  {
    name: 'app.root.h1.f1.casino.s1.collection',
    url: '/:collection/:category',
    template: '<batman-game-hall1 scroll-to-top></batman-game-hall1>',
    params: {
      collection: 'games',
      category: 'top',
    },
    onEnter: [
      'casinoResolver',
      function (casinoResolver) {
        casinoResolver.collection();
      },
    ],
  },
  {
    name: 'app.root.h1.f1.casino.hall',
    url: '/:collection',
    template: '<linda-casino-section scroll-to-top ></linda-casino-section>',
    params: {
      collection: 'games',
    },
    onEnter: [
      'casinoResolver',
      function (casinoResolver) {
        casinoResolver.collection();
      },
    ],
  },
  // {
  //   name: 'app.root.h1.f1.tyson_ambassador',
  //   url: '/tyson-ambassador',
  //   template: '<batman-view-layer5 scroll-to-top="" ></batman-view-layer5>',
  // },
];

export { router };
