import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

(function () {
  ('use strict');

  const directive = { name: 'promoAnimation' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
      gsap.defaults({ overwrite: false });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: element[0],
            end: '+=' + element[0].offsetHeight,
            scrub: true,
          },
        })
        .from(element[0], {
          y: 100,
          opacity: 0,
        });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
