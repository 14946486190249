class KeywordsService {
  static $inject = ['$document'];

  constructor($document) {
    this.$document = $document[0];
    this.keywordsRef = this.$document.head.querySelector('meta[name="keywords"]');
    this.targetNode = this.$document.querySelector('meta[name="description"]');
    if (this.keywordsRef === null) {
      this.create();
    }
  }

  create() {
    this.keywordsRef = this.$document.createElement('meta');
    this.keywordsRef.setAttribute('name', 'keywords');
    return this.targetNode.after(this.keywordsRef);
  }

  update(keywords) {
    if (angular.isString(keywords)) this.keywordsRef.setAttribute('content', keywords);
  }
}

app.service('keywordsService', KeywordsService);
