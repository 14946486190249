import '../../../service/domain/banner.js'
import '../../../service/rx/system$.js'
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

(function () {
  controller.$inject = ['$scope', 'banner', 'system$', 'user'];

  function controller($scope, _banner, _system$, _user) {
    $scope.preloader = false;
    $scope.banner = {};
    const destroy$ = new Subject();
    let params = {};

    function getBanner(options) {
      $scope.preloader = true;
      _banner
        .collection(options)
        .then((answer) => {
          $scope.banner = answer.result?.[0] || {};
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onInit = () => {
      params = {
        category: 'banner',
        place: this.place,
        out: this.out || '',
      };
      getBanner(params);

      _system$
        .pipe(
          takeUntil(destroy$),
          filter((m) => m.action === 'statusChanged')
        )
        .subscribe(() => {
          params.auth = _user.status;
          getBanner(params);
        });
    };

    this.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }

  new Controller('banner', controller, {
    place: '<',
    out: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].forEach((i) => {
    new Batman(`batmanBanner${i}`, 'banner');
  });
})();
