import '../../../service/trustly/paynplay.js';
import '../../../service/configs/config.js';
import '../../../service/domain/user.js';
import '../../../service/domain/state.js';
import '../../../service/helper.js';
import '../../../service/rx/trustly$.js';
import '../../../service/configs/trustly.js';
(function () {
  'use strict';

  const component = { name: 'lindaTrustlyPaynplayBanner' };

  controller.$inject = [
    '$scope',
    'paynplay',
    'config',
    'user',
    'state',
    'helper',
    'trustly$',
    '$timeout',
    'trustly.config',
  ];

  function controller($scope, _paynplay, _config, _user, _state, _helper, _trustly$, $timeout, _trustly_config) {
    $scope.amount = _paynplay.data.amount;
    $scope.paysystem = _trustly_config;

    $scope.submit = _helper.form.validator((form) => {
      if ($scope.amount > 0) {
        _paynplay.data.amount = $scope.amount;
        _paynplay.data.step = 1;
        _state.goto('paynplay');
      }
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
