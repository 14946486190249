import { BehaviorSubject } from 'rxjs';

(function () {
  window.deferredPrompt$ = new BehaviorSubject({ isEnabled: false });

  window.addEventListener('beforeinstallprompt', function (e) {
    e.preventDefault();
    window.deferredPrompt = e;
    window.deferredPrompt$.next({ isEnabled: true });
  });
})();
