(function () {
  'use strict';

  const directive = { name: 'scrollToTopOnClick' };

  controller.$inject = ['$document'];

  function controller($document) {
    function link(scope, element, attrs) {
      const params = scope.$eval(attrs[directive.name]);
      const selector = params.selector;
      const isAnimated = angular.isUndefined(params.isAnimated) ? true : params.isAnimated;

      element.on('click', () => {
        const targetAngularElement = selector ? angular.element($document[0].querySelector(selector)) : $document;
        if (isAnimated) {
          targetAngularElement?.duScrollTopAnimated(0, 400);
        } else {
          targetAngularElement?.scrollTop(0);
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
