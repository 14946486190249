import '../../service/domain/modal.js';

(function () {
  'use strict';

  controller.$inject = ['modal'];

  function controller(_modal) {
    function link(scope, element, attrs) {
      element.on('click', (e) => {
        _modal.answer = false;
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('lindaModalRejectOnClick', controller);
})();
