import '../service/domain/brand.js'
(function () {
  'use strict';

  const directive = {
    name: 'getProvidersToScope',
  };

  factory.$inject = ['brand'];

  function factory(_brand) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'providers';
      const nameOrderingKey = attrs.orderBy || 'name';
      const countOrderingKey = 'total_count';

      const ascByNameClass = 'linda-brands-ordered-by-name-asc';
      const descByNameClass = 'linda-brands-ordered-by-name-desc';
      const ascByCountClass = 'linda-brands-ordered-by-count-asc';
      const descByCountClass = 'linda-brands-ordered-by-count-desc';

      let orderByNameBtn;
      let orderByCountBtn;

      scope[variable] = {};
      scope.brandsOrdering = ['', ''];

      _brand
        .collection()
        .then((a) => {
          scope[variable].__proto__ = _brand['object'];
        })
        .catch((e) => {});

      scope.orderByName = ({ currentTarget }) => {
        if (!orderByNameBtn) {
          orderByNameBtn = currentTarget;
        }

        if (!isSortedByName() || (isSortedByName() && isReverseByName())) {
          firstOrderingByName();
        } else {
          secondOrderingByName();
        }
      };

      scope.orderByCount = ({ currentTarget }) => {
        if (!orderByCountBtn) {
          orderByCountBtn = currentTarget;
        }

        if (!isSortedByCount() || (isSortedByCount() && !isReversedByCount())) {
          firstOrderingByCount();
        } else {
          secondOrderingByCount();
        }
      };

      const isSortedByName = () => scope.brandsOrdering[0].includes(nameOrderingKey);
      const isReverseByName = () => scope.brandsOrdering[0].includes('-');
      const isSortedByCount = () => scope.brandsOrdering[0].includes(countOrderingKey);
      const isReversedByCount = () => scope.brandsOrdering[0].includes('-');

      const firstOrderingByName = () => {
        scope.brandsOrdering = [nameOrderingKey, countOrderingKey];

        if (orderByCountBtn) {
          orderByCountBtn.classList.remove(ascByCountClass);
          orderByCountBtn.classList.remove(descByCountClass);
        }

        orderByNameBtn.classList.remove(descByNameClass);
        orderByNameBtn.classList.add(ascByNameClass);
      };

      const secondOrderingByName = () => {
        scope.brandsOrdering[0] = `-${nameOrderingKey}`;
        orderByNameBtn.classList.remove(ascByNameClass);
        orderByNameBtn.classList.add(descByNameClass);
      };

      const firstOrderingByCount = () => {
        scope.brandsOrdering = [`-${countOrderingKey}`, nameOrderingKey];

        if (orderByNameBtn) {
          orderByNameBtn.classList.remove(ascByNameClass);
          orderByNameBtn.classList.remove(descByNameClass);
        }
        orderByCountBtn.classList.remove(ascByCountClass);
        orderByCountBtn.classList.add(descByCountClass);
      };
      const secondOrderingByCount = () => {
        scope.brandsOrdering = [countOrderingKey, nameOrderingKey];

        orderByCountBtn.classList.remove(descByCountClass);
        orderByCountBtn.classList.add(ascByCountClass);
      };
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
