(function () {
    'use strict';
  
    const directive = { name: 'rabonaScrollTo' };
  
    controller.$inject = ['$document','$timeout'];
  
    function controller($document,$timeout) {
      function link(scope, element, attrs) {
        const el = scope.$eval(attrs[directive.name]);
        element.on('click', () => {
        const targetElement = $document[0].querySelector(el);
          if (targetElement) {
            $timeout(() => {
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            });
          }
        });
      }
  
      return {
        restrict: 'A',
        link: link,
      };
    }
  
    app.directive(directive.name, controller);
  })();
