import '../../../service/domain/account.js';
import '../../../service/domain/popup.js';
// linda-selfcontrol-deposit-item

(function () {
  'use strict';

  const component = { name: 'lindaSelfcontrolAddForm' };

  controller.$inject = ['$scope', 'account', 'popup'];

  function controller($scope, _account, _popup) {
    $scope.showAddNewForm = false;
    $scope.type = $scope.$parent.type || 'deposit';
    $scope.preloader = false;
    $scope.formData = {
      type: '31',
      amount: 1000,
    };

    $scope.submit = (form) => {
      if (form.$valid) {
        $scope.preloader = true;

        const o = {
          group: $scope.type,
          id: null,
          type: $scope.formData.type,
          amount: $scope.formData.amount,
        };

        _account
          .limits(o)
          .then(() => {
            $scope.showAddNewForm = false;
            _popup.close();
          })
          .finally(() => {
            $scope.preloader = false;
          });
      } else {
        angular.forEach(form.$error, (type) => {
          angular.forEach(type, (field) => {
            field.$setTouched();
          });
        });
      }
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
