import '../../service/domain/http.js'
import '../localStorage.js'
import {ReplaySubject} from 'rxjs';

(function () {
  controller.$inject = ['http', '$q', 'localStorage'];
  app.factory('holiday', controller);

  function controller(_http, $q, _localStorage) {
    let isInitializied = false;
    let activePromos = [];
    const currentDate = getCurrentDate();
    let takenTodayBonus = null;
    const selectedBonus$ = new ReplaySubject();
    const dayInMs = 24 * 60 * 60 * 1000;
    function getCurrentDate() {
      const testDate = window.localStorage.getItem('test-date') || ''; //yyyy-mm-dd
      return testDate ? +new Date(testDate) : +new Date();
    }

    // function checkIfPromoIsActive(startDate, endDate) {
    //   return currentDate >= startDate && currentDate <= (endDate + dayInMs);
    // }

    function isBonusTaken(bonusId, type) {
      return _localStorage.getObject('holidayBonus')?.[bonusId] === type ?? false;
    }

    function request(params = {}) {
      const alias = 'holidayList',
        defer = $q.defer(),
        config = {
          params,
          meta: {
            type: 'api',
            alias,
          },
        };

      _http
        .get(alias, config)
        .then(({ data }) => {
          isInitializied = true;
          // activePromos = data.result.filter((p) => checkIfPromoIsActive(p.promo_start_date, p.close_date));
          activePromos = data.result;
          activePromos = activePromos.map((i) => groupEvents(i));
          if (activePromos.length) {
            defer.resolve(activePromos);
            return;
          }
          if (!data.result.length) {
            _localStorage.remove('holidayBonus');
          }
          defer.reject(data);
        })
        .catch(() => {
          defer.reject();
        })
        .finally(() => {});

      return defer.promise;
    }

    function setEventDayStatus({ date }) {
      const dayInMs = 24 * 60 * 60 * 1000;

      if (currentDate >= date) {
        if (currentDate - date < dayInMs) {
          return 'today';
        }
        if (currentDate - date < 2 * dayInMs) {
          return 'yesterday';
        }
        return 'previous';
      }
      if (currentDate < date) {
        if (date - currentDate <= dayInMs) {
          return 'next';
        }
        return 'future';
      }
    }

    function groupEvents(data) {
      const result = data;
      let lastDay = 0;
      result.events = result.events.reduce((collection, event) => {
        const { type } = event;
        if (!collection[type]) {
          collection[type] = [];
        }
        event.dayStatus = setEventDayStatus(event);
        event.isTaken = isBonusTaken(event.id, event.type);
        collection[type].push(event);
        if (event.date > lastDay) {
          lastDay = event.date;
        }
        if (event.isTaken && event.dayStatus === 'today') {
          takenTodayBonus = event;
        }
        return collection;
      }, {});
      result.takenTodayBonus = takenTodayBonus;
      result.last_day = lastDay;

      return result;
    }

    const getItem = (item) => {
      if (isInitializied) {
        return new Promise((resolve, reject) => {
          const promo = activePromos.find((m) => m.template === item);
          if (promo) {
            resolve(promo);
          } else {
            reject();
          }
        });
      } else {
        return new Promise((resolve, reject) => {
          request()
            .then((data) => {
              const promo = data.find((promotion) => promotion.template === item);
              if (promo) {
                resolve(promo);
              } else {
                reject();
              }
            })
            .catch(() => {
              reject();
            });
        });
      }
    };

    const list = () => {
      if (isInitializied) {
        return new Promise((resolve) => resolve(activePromos));
      } else {
        return request();
      }
    };
    const saveTakenBonus = (bonusIdentifier, type) => {
      const bonusList = _localStorage.getObject('holidayBonus') || {};
      bonusList[bonusIdentifier] = type;
      _localStorage.setMapObject('holidayBonus', bonusList);
      isInitializied = false;
      const bonus = activePromos[0].events[type].toObject('id')[bonusIdentifier];
      bonus.isTaken = true;
      selectedBonus$.next(bonus);
    };

    return {
      list,
      getItem,
      getCurrentDate,
      saveTakenBonus,
      selectedBonus$,
    };
  }
})();
