import '../../service/domain/state.js';
(function () {
  'use strict';

  const name = 'lindaSportWidget';

  // !!! AltenarWidget initialization REMOVE all classes and id from element
  //
  // Use example
  // linda-sport-widget="{
  //      type: 'WLivenow',    - optional option; type: string; values: WLivenow, WHighlights, WUpcoming; default: 'WLivenow'
  //      maxTabs: 3          - optional option; type:number; default: 3
  // }"

  controller.$inject = ['state'];

  function controller(_state) {
    const link = (scope, element, attrs) => {
      let AW = {};

      const options = scope.$eval(attrs.lindaSportWidget);

      const hash = `sportWidget${options.type}${Date.now()}`;

      attrs.$set('id', hash);

      const ident = setInterval(() => {
        if (typeof AltenarWidget === 'function') {
          const params = {
            widget: options.type || 'WLivenow',
            maxTabs: options.maxTabs || 3,
            eventclickcallback: (eventid) => {
              _state.goto('sportEvent', { eventid });
            },
          };

          AW = new AltenarWidget(`#${hash}`, params);
          clearInterval(ident);
        }
      }, 20);
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
