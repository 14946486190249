import '../service/domain/user.js'
(function () {
  'use strict';

  app.directive('checkPendingWithdrawals', controller);

  controller.$inject = ['user'];

  function controller(_user) {
    function link(scope) {
      scope.checkPendingWithdrawals = !!_user.profile.pending_withdrawals;
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
