(function () {
  'use strict';

  const directive = { name: 'hideForSw' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const lang = scope.$eval(attrs[directive.name]);

      if (lang === 'sv' && new Date().getFullYear() === 2019) {
        element.parentNode.removeChild(element);
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
