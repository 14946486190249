class TitleService {
  static $inject = ['$document'];

  constructor($document) {
    this.$document = $document[0];
    this.titleRef = this.$document.head.querySelector('title');
    this.targetNode =
      this.$document.querySelector('meta[charset="UTF-8"]') || this.$document.querySelector('meta[charset="utf-8"]');
    if (this.titleRef === null) {
      this.create();
    }
  }

  create() {
    this.titleRef = this.$document.createElement('title');
    return this.targetNode.after(this.titleRef);
  }

  update(title) {
    if (angular.isString(title)) this.titleRef.textContent = title;
  }
}

app.service('titleService', TitleService);
