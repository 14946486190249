// https://mymth.github.io/vanillajs-datepicker/#/
(function () {
  'use strict';

  const directive = { name: 'datepickerBirthday' };

  function controller() {
    function link($scope, $element, $attrs, $ngModelCtrl) {
      const element = $element[0];
      const defaultFormat = 'yyyy-mm-dd';
      const {
        modelFormat = defaultFormat,
        viewFormat = defaultFormat,
        minAge = 18,
      } = $scope.$eval($attrs[directive.name]) || {};

      const updateModel = (modelValue) => $scope.$apply(() => $ngModelCtrl.$setViewValue(modelValue));

      const datepickerOptions = {
        autohide: true,
        format: viewFormat,
        minDate: new Date().setFullYear(new Date().getFullYear() - 100),
        maxDate: new Date().setFullYear(new Date().getFullYear() - minAge),
        startView: 2,
      };

      const datepickerInstance = new Datepicker(element, datepickerOptions);

      element.addEventListener('changeDate', () => {
        const formattedPickedDate = datepickerInstance.getDate(modelFormat);
        updateModel(formattedPickedDate);
      });

      $scope.$on('$destroy', () => {
        datepickerInstance.destroy();
      });
    }

    return {
      restrict: 'A',
      link,
      require: 'ngModel',
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, controller);
})();
