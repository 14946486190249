import { $_CONFIG as config } from '../../src/config/config.mjs';
import { $_SLIDER as slider } from '../../src/config/slider.mjs';
import { aliases } from '../../src/config/alias.mjs';
import { router } from '../../src/config/router.mjs';

import '../../gulp/date-build.js';
import { version } from '../../dist/version.json';

const $_ALTENARCONFIG = {};

const $_CONFIG = {
  jurisdiction: 'curacao', // can be olso 'malta', 'spain', 'sweden'
  jurisdictions: {
    de: 'germany',
    sw: 'sweden',
  },

  isPayNPlayRegistrationAvailable: true,

  sport: false,
  sportVersion: 1,
  sportFixedBottom: 55,

  locale: '',
  project: '', // категорія для перекладів
  product: 'casino',

  apiVersion: 'v2',
  cdn: '/joxi',
  domain: '',
  siteDomain: '',
  siteName: '', // використовується для доступа до персонального конфіга сайта на цдн
  build: Date.now(),
  buildVersion: version,
  production: process.env.NODE_ENV === 'production',
  mobileApp: '#{mobileApp}' === 'true',
  mid: '',
  language: ['en'],

  insertCdnScripts: true,

  currency: window.$_DICT.currency || [{ key: 'EUR', name: '', icon: '€' }],

  templates: '#{templates}',
  links: {},

  chat: 'liveAgent',
  liveAgent: {
    default: {},
  },
  freshDeskId: '',
  freshChat: {},

  router: [],
  scripts: [],

  gsap3Plugins: [],

  wsScript: 'socket/socket.io.js',
  licenseScript: 'https://a3aacfbf-d709-4229-9d74-b9b46bae5237.snippet.antillephone.com/apg-seal.js',
  licenseId: '',
  customScripts: [],
  useSvgSprite: true,
  redirectToNotFound: false,
  feature: {},
};

const cdnFeature = window?.$_CDN?.feature || {};
let localStorageFeature = null;
try {
  localStorageFeature = JSON.parse(window.localStorage.getItem('feature-flags'));
} catch {}

$_CONFIG.feature = localStorageFeature ? localStorageFeature : cdnFeature;

window.$_CONFIG = Object.assign({}, $_CONFIG, config, { aliases }, { router }, { slider });

if (typeof module !== 'undefined' && module.exports) module.exports = $_CONFIG;
