(function () {
  'use strict';

  const directive = { name: 'sliderRange' };

  //TODO: 'DRAFT:', directive.name

  function controller() {
    function link(scope, element, attrs) {
      const color = scope.color;
      const range = scope.range;
      const initialValue = scope.value;

      const inputRange = document.getElementById('input-range');
      const inputEnter = document.getElementById('input-enter');
      const sliderBar = document.getElementById('slider-bar');

      const interval = 100 / (range.length - 1);
      let intervalStepValueMap = {};

      const setSliderBarBg = (position) => {
        sliderBar.style.background = `linear-gradient(to right, ${color} 0, ${color} ${position}%, rgba(255,255,255,0) ${position}%, rgba(255,255,255,0) 100%)`;
      };

      const definePosition = (val) => {
        let prevPoint = 0;
        range.forEach((item, idx, arr) => {
          if (val - item >= 0) prevPoint = idx;
          intervalStepValueMap[idx] = (arr[idx + 1] - item) / interval;
        });

        let currentIntervalStepValue = intervalStepValueMap[prevPoint];
        let stepsInCurrentInterval = (val - range[prevPoint]) / currentIntervalStepValue;
        inputRange.value = prevPoint * interval + stepsInCurrentInterval;
        return inputRange.value;
      };
      const defineVal = (position) => {
        let prevPoint = Math.floor(position / interval);
        let currentIntervalStepValue = intervalStepValueMap[prevPoint] || range[range.length - 1];
        let stepsInCurrentInterval = position - interval * prevPoint;
        return Math.round(range[prevPoint] + stepsInCurrentInterval * currentIntervalStepValue);
      };

      //-ACTIONS ON SLIDER LOAD--start
      const initialPosition = definePosition(initialValue);
      setSliderBarBg(initialPosition);
      //-ACTIONS ON SLIDER LOAD--end

      //-WATCHING DATA--start
      inputRange.addEventListener('input', (e) => {
        setSliderBarBg(e.target.value);
        scope.$apply(() => {
          scope.value = defineVal(e.target.value);
        });
      });

      inputEnter.addEventListener('input', (e) => {
        const position = definePosition(e.target.value);
        setSliderBarBg(position);
        scope.$apply(() => {
          scope.value = parseInt(e.target.value);
        });
      });
      //-WATCHING DATA--end
    }

    return {
      restrict: 'A',
      scope: {
        value: '=',
        range: '<',
        color: '@',
      },
      link,
    };
  }

  app.directive(directive.name, controller);
})();
