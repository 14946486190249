/* eslint-disable angular/on-watch */
import '../service/rx/user$.js';
import '../service/domain/user.js';
import '../service/domain/account.js';
import '../service/rx/notification$.js';
import '../service/configs/config.js';
import '../service/domain/popup.js';
import '../service/domain/game.js';
import '../service/domain/state.js';
import '../service/mobile-app/game-mobile-app.js';
import '../service/domain/system.js';

(function () {
  app.run(controller);

  controller.$inject = [
    '$rootScope',
    'user$',
    'user',
    'account',
    'notification$',
    '$timeout',
    'config',
    'popup',
    '$state',
    'game',
    'state',
    'gameMobileApp',
    'system',
  ];

  function controller(
    $rootScope,
    _user$,
    _user,
    _account,
    _notification$,
    $timeout,
    _config,
    _popup,
    $state,
    _game,
    _state,
    _gameMobileApp,
    _system,
  ) {
    if (_config.mobileApp) FastClick.attach(document.body);
    if (_user.sessionToken && _user.sessionToken.length >= 10) {
      _account.info({ nocache: true });
    } else {
      _user.profile = {};
    }
    _system
      .info()
      .then((answer) => {
        _config.system_info = answer.result;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      switch (error.detail.code) {
        case 307:
          // Start game on app
          _gameMobileApp.launch({ code: toParams.name, mode: toParams.mode });
          break;

        case 308:
          // TODO діч
          _game.mobileLaunch({ code: toParams.name, mode: toParams.mode });
          break;

        case 309:
          $state.go('app.root.mobile-game', toParams);
          // сторінка гри для моб іспанії
          break;

        case 310:
          $state.go(toParams.redirectIfMobile, toParams);
          break;

        // приватна сторінка з проханням авторизації
        case 400:
          if (
            _config.jurisdiction === 'sweden' ||
            (_config.currentLang === 'fi' && _config.isPayNPlayRegistrationAvailable)
          ) {
            _state.goto('paynplay');
            return;
          }
          _popup.open({
            name: _user.profile.login ? 'login' : 'registration',
            cb() {
              $state.go(toState.name, toParams);
            },
          });
          break;

        // приватна сторінка з редіректом на головну і проханням авторизації
        // case 401:
        //   const state = _state.get('home');
        //
        //   if (state !== null) {
        //     $state.go(state.name, { lang: _config.currentLang }).then(() => {
        //       if (
        //         _config.jurisdiction === 'sweden' ||
        //         (_config.currentLang === 'fi' && _config.isPayNPlayRegistrationAvailable)
        //       ) {
        //         _state.goto('paynplay');
        //         return;
        //       }
        //       _popup.open({
        //         name: _user.profile.login ? 'login' : 'registration',
        //         cb() {
        //           $state.go(toState.name, toParams);
        //         },
        //       });
        //     });
        //   }
        //
        //   break;

        case 402:
          break;

        // Пользователь авторизован, страница доступна только для неавторизованных (форма регистрации, авторизации...)
        case 500:
          _state.goto('home');
          break;
      }
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      _state.previous.name = fromState.name;
      _state.previous.params = fromParams;
      _state.history.push(toState.name);
    });
  }
})();
