import '../../../service/domain/game.js'
import '../../../service/rx/scroll$.js'
import '../../../service/rx/resize$.js'
(function () {
  controller.$inject = ['$scope', '$element', 'game', 'scroll$', 'resize$'];

  function controller($scope, $element, _game, _scroll$, _resize$) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.content = {};
    $scope.category = null;
    $scope.provider = null;
    $scope.lastPage = false;
    $scope.infiniteScroll = false;

    $scope.total_count = 0;

    let page = 0;
    let loading = false;
    const config = {};

    let skeletonCollection = [],
      startIndex = 0;
    const defaultGameObject = { skeleton: true };

    let intersectionObserver = null;
    let answerTimeStamp = null;

    function getCollection(options) {
      if (!loading) {
        loading = true;
        page++;
        options.page = page;
        $scope.preloader = true;
        startIndex = $scope.collection.length;
        $scope.collection = $scope.collection.concat(skeletonCollection);
        _game
          .collection(options)
          .then((answer) => {
            $scope.collection.splice(startIndex, skeletonCollection.length, ...answer.result);
            $scope.lastPage = answer.page >= answer.total_pages;
            $scope.preloader = false;
            loading = false;
            $scope.total_count = answer.total_count;
            answerTimeStamp = Date.now();
          })
          .then(() => {
            if ($scope.infiniteScroll && !intersectionObserver) {
              observeIntersection();
            }
            if (skeletonCollection.length > $scope.total_count - $scope.collection.length) {
              skeletonCollection.length = $scope.total_count - $scope.collection.length;
            }
            if ($scope.lastPage) {
              unobserveIntersection();
            }
          })
          .catch(() => {
            $scope.collection = [];
            $scope.total_count = 0;
          })
          .finally(() => {
            $scope.preloader = false;
            loading = false;
          });
      }
    }

    function unobserveIntersection() {
      intersectionObserver?.disconnect();
    }

    function observeIntersection() {
      let element;

      try {
        element = $element[0].querySelector($scope.infiniteScroll);
        if (!element) throw new Error();
      } catch (e) {
        return;
      }

      const callBack = ([entry]) => {
        if (entry.isIntersecting) {
          const delta = Date.now() - answerTimeStamp;
          if (delta > 500) {
            getCollection(config);
          }
        }
      };
      intersectionObserver = new IntersectionObserver(callBack);
      intersectionObserver.observe(element);
    }

    this.$onDestroy = () => {
      unobserveIntersection();
    };

    this.$onChanges = function () {
      page = 0;

      $scope.collection = [];
      $scope.total_count = 0;

      if (this.skeleton) {
        skeletonCollection = [];
        skeletonCollection.length = this.count || 1;
        skeletonCollection.fill(defaultGameObject, 0, this.count || 1);
      }

      $scope.content = this.content;
      $scope.category = this.category;
      $scope.provider = this.provider;
      $scope.infiniteScroll = this.infiniteScroll || null;
      config.category = this.category;
      config.count = this.count;
      config.brand = this.provider;
      config.out = this.fields;

      config.random = this.random;

      config.tournament = this.tournament;

      getCollection(config);
    };

    $scope.nextPage = function () {
      getCollection(config);
    };
  }

  const bindings = {
    category: '<',
    count: '<',
    content: '<',
    provider: '<',
    fields: '<',
    tournament: '<',
    random: '<',
    infiniteScroll: '<',
    skeleton: '<',
  };

  new Controller('gameBox', controller, bindings);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].forEach((i) => {
    new Batman(`batmanGameBox${i}`, 'gameBox');
  });
})();
