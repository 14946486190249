(function () {
  'use strict';

  controller.$inject = ['$q'];

  function controller($q) {
    return typeof TapticEngine != undefined
      ? {
          selection() {
            TapticEngine.selection();
          },
          notification(type = 'error') {
            TapticEngine.notification({
              type: type, // success | warning | error
            });
          },
          impact(style = 'heavy') {
            TapticEngine.impact({
              style: style, // light | medium | heavy
            });
          },
        }
      : {};
  }

  app.factory('tapticEngine', controller);
})();
