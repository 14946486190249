(function () {
  'use strict';

  const directive = { name: 'smoothScrollTap' };

  controller.$inject = ['$document'];

  function controller($document) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs[directive.name]);

      element.on('tap', () => {
        const pageElement = $document[0].querySelector(config.page);
        const toElement = $document[0].querySelector(config.to);

        if (toElement && pageElement) {
          pageElement.scrollTo({
            top: toElement.getBoundingClientRect().top + pageElement.scrollTop,
            behavior: 'smooth',
          });
        }
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
