import { consume } from '@lit/context';
import { html, css, LitElement, TemplateResult, CSSResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import { FunIdAuthMedia } from '../model/funid-auth-model';
import { funIdConfigContext } from '../services/context/funid-config-context';
import { FunIdConfigService } from '../services/funid-config-service';

@customElement('funid-auth-button')
export class FunidAuth extends LitElement {
  public static styles: CSSResult = css`
    .auth-button::part(base) {
      border: none;
    }

    .auth-button::part(label) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--fid-auth-button-background-color);
      border-radius: 2px;
      padding: var(--fid-auth-button-padding);
      color: var(--fid-auth-button-text-color);
    }

    .auth-button--logo {
      display: block;
      width: 36px;
      height: 36px;
    }

    .auth-button--text {
      padding: var(--fid-auth-button-text-padding, 4px 16px);
    }
  `;

  @property({ type: String })
  public text!: string;

  @property({ type: Object })
  public logo!: FunIdAuthMedia;

  public render(): TemplateResult {
    return html`
      <sl-button class="auth-button">
        ${this.logo.url &&
        html`
          <img
            class="auth-button--logo"
            src="${this.withCdnUrl(this.logo.url)}"
            alt="${this.logo.alt}"
          />
        `}

        <span class="auth-button--text">${this.text}</span>
      </sl-button>
    `;
  }

  @consume({ context: funIdConfigContext })
  @property({ attribute: false })
  public config!: FunIdConfigService;

  protected withCdnUrl(url: string): string {
    return `${this.config.getCdnUrl()}${url}`;
  }
}
