(function () {
  'use strict';

  const directiveName = 'closeMenuSwipe';

  app.directive(directiveName, controller);

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const menuElement = angular.element(attrs[directiveName]);

      const limits = {
        max: menuElement.outerWidth(),
        min: 0,
      };

      const tapPosition = {
        start: 0,
        end: 0,
      };
      let tapping = false;

      let distance = 0;

      let transition = 'none';

      element.bind('touchstart', function (e) {
        tapping = true;
        tapPosition.start = e.changedTouches[0].pageX;
        transition = menuElement.css('transition');
        menuElement.css('transition', 'none');
      });

      element.bind('touchmove', function (e) {
        tapping = false;
        tapPosition.end = e.changedTouches[0].pageX;
        distance = tapPosition.start - tapPosition.end;
        distance = Math.min(distance, limits.max);
        distance = Math.max(distance, limits.min);
        menuElement.css('transform', 'translateX(' + (limits.max - distance) + 'px)');
        element.css('opacity', tapPosition.end / tapPosition.start);

        e.preventDefault();
      });

      element.bind('touchend', function (e) {
        tapPosition.end = e.changedTouches[0].pageX;
        menuElement.css('transition', transition);
        if (distance > limits.max * 0.4 || tapping) {
          menuElement.removeClass('menu-open');
        } else {
          menuElement.addClass('menu-open');
        }
        menuElement.css('transform', '');
        element.css('opacity', '1');

        e.preventDefault();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }
})();
