(function () {
  'use strict';

  const directive = { name: 'callNumber' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs) => {
      const number = attrs[directive.name];

      const bypassAppChooser = true;

      let tapping = false;

      element.bind('touchstart', function (e) {
        tapping = true;
      });

      element.bind('touchmove', function (e) {
        tapping = false;
      });

      element.bind('touchstart', function (e) {
        if (tapping) {
          if (!cordova || cordova.platformId === 'browser') {
            location.href = 'tel:' + number;
            return;
          }

          if (window.plugins && window.plugins.CallNumber) {
            window.plugins.CallNumber.callNumber(
              () => {},
              () => {},
              number,
              bypassAppChooser
            );
          }
        }
      });
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
