import '../../../service/domain/content-buffer.js';
import '../../../service/domain/account.js';
import '../../../service/domain/modal.js';
import '../../../service/domain/state.js';
(function () {
  'use strict';

  const component = {
    name: 'lindaMgaSessionLengthModal',
  };

  controller.$inject = ['$scope', 'contentBuffer', 'account', 'modal', 'state'];

  function controller($scope, _contentBuffer, _account, _modal, _state) {
    const o = {};
    o.token = _contentBuffer.read('modal').data.token;

    $scope.refresh = () => {
      $scope.preloader = true;

      _account
        .refreshSession(o)
        .then((a) => {
          _modal.answer = true;
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    $scope.history = () => {
      $scope.preloader = true;

      _account
        .refreshSession(o)
        .then((a) => {
          _modal.answer = true;
          _state.goto('balanceHistory');
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    $scope.logOut = () => {
      $scope.preloader = true;

      _account
        .logout()
        .then((a) => {})
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
          _modal.answer = true;
          _state.goto('home');
        });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
