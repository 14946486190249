import '../service/configs/config.js';
import '../service/utils/lottie.js';
(function () {
  const directive = { name: 'lottieAnimation' };

  controller.$inject = ['config', 'lottie'];

  function controller(_config, _lottie) {
    const link = (scope, element, attrs, ngModel) => {
      const id = element.attr('id');
      const path = _config.cdn + attrs[directive.name];
      if (!id) {
        throw new Error('ID attr required to animate element');
      }
      _lottie.loadAnimation({
        container: element[0],
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path,
      });
    };

    return { restrict: 'A', link };
  }

  app.directive(directive.name, controller);
})();
