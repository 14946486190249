(function () {
  'use strict';

  const component = { name: 'lindaCallbackPage' };

  controller.$inject = ['$state', '$timeout'];

  function controller($state, $timeout) {
    const action = $state.params.name;
    window.parent.postMessage(`${action}`, '*');
    $timeout(() => {
      window.history.back();
    }, 1000);
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
