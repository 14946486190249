import '../../../service/payment/cashbox-state.js';
import '../../../service/domain/content-buffer.js';
(function () {
  'use strict';

  const component = { name: 'lindaCashboxPopup' };

  controller.$inject = ['$scope', 'cashboxState', 'contentBuffer'];

  function controller($scope, _cashboxState, _contentBuffer) {
    $scope.showCryptoTab = _cashboxState.getValue().showCryptoTab;
    const params = _contentBuffer.read('popup');
    const cashboxState = params?.cashboxState;
    if (cashboxState) {
      _cashboxState.setValue(cashboxState);
    }

    this.$onDestroy = () => {
      _cashboxState.reset();
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
