(function () {
  'use strict';

  function controller() {
    function link(scope, element) {
      const el = element[0];
      el.onscroll = function () {
        if (
          el.scrollTop + el.clientHeight + 10 >= el.scrollHeight &&
          !(scope.lastPage || scope.preloader) &&
          el.scrollHeight > el.offsetHeight
        ) {
          scope.nextPage();
        }
      };
    }

    return {
      restrict: 'A',
      link,
    };
  }

  controller.$inject = [];
  app.directive('infiniteScroll', controller);
})();
