import { interval } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    return new interval(1000);
  }

  app.factory('timer$', controller);
})();
