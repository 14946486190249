let linkToSummer = 'joxi/summer-promo/sound';
export const summerSounds = {
  missClick: `${linkToSummer}/click_miss.mp3`,
  collectCoin: `${linkToSummer}/coin_click.mp3`,
  coinAnimation: `${linkToSummer}/coin_flying.mp3`,
  allCollected: `${linkToSummer}/all_coin.mp3`,
  gameStart: `${linkToSummer}/start_game.mp3`,

  gameBackground: `${linkToSummer}/background.mp3`,
  summerListOnload: `${linkToSummer}/start_map.mp3`,
  summerListBtns: `${linkToSummer}/purple_button.mp3`,
  summerListScroll: `${linkToSummer}/scroll_islands.mp3`,
  summerListPlay: `${linkToSummer}/green-button.mp3`,
}
