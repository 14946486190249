import { Subject } from 'rxjs'
import { take, filter, takeUntil } from 'rxjs/operators'

import '../service/domain/system.js'

(function () {
  'use strict';

  app.directive('licenseIcon', [
    'config',
    'helper',
    'splash$',
    'system',
    function (_config, _helper, _splash$, _system) {
      function link(scope, element, attrs) {
        const destroy$ = new Subject();
        const restrictedCountries = ['au', 'fr'];
        attrs.$set('id', `apg-${_config.licenseId}`);
        attrs.$set('data-apg-seal-id', `${_config.licenseId}`);
        attrs.$set('data-apg-image-size', `128`);
        attrs.$set('data-apg-image-type', `basic-small`);

        if (typeof APG !== 'undefined') APG.init();

        _splash$
          .pipe(
            filter((m) => m === 'loaded'),
            take(1),
            takeUntil(destroy$)
          )
          .subscribe(async (i) => {
            const systemInfo = await _system.info();
            if (restrictedCountries.includes(systemInfo?.result?.country.code.toLowerCase())) {
              return;
            }
            _helper.dom.addScript(`https://${$_CONFIG.licenseId}.snippet.antillephone.com/apg-seal.js`, () => {
              APG.init();
            });
          });

        scope.$onDestroy = () => {
          destroy$.next();
          destroy$.unsubscribe();
        };
      }

      return {
        restrict: 'A',
        link,
      };
    },
  ]);
})();
