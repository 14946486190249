(function () {
  'use strict';

  app.filter('intlNumberFormat', function () {
    return function (stringAmount, country = "de-DE") {
      if(stringAmount === undefined) return;
      return new Intl.NumberFormat(country.toLowerCase()).format(parseFloat(stringAmount).toFixed(2));
    };
  });
})();
