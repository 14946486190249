import '../../../service/domain/paysystem.js'
(function () {
  'use strict';

  controller.$inject = ['$scope', 'paysystem'];

  function controller($scope, _paysystem) {
    $scope.collection = [];
    $scope.type = '';

    this.$onChanges = () => {
      const config = {};

      if (this.type) {
        config.type = this.type;
        $scope.type = this.type;
      }

      if (this.currency) config.currency = this.currency;

      if (this.country) config.country = this.country;

      if (this.category && config.type === 'deposit') {
        config.payment_category = this.category;
      }

      if (config.country && !config.currency) {
        return;
      }

      $scope.preloader = true;
      _paysystem
        .banking(config)
        .then((a) => {
          $scope.collection = a.result.map((item) => Object.assign(item, { purpose: config.type }));
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  new Controller('bankingBox', controller, {
    type: '<',
    currency: '<',
    country: '<',
    category: '<',
  });
})();

(function () {
  'use strict';
  new Batman('batmanBankingBox1', 'bankingBox');
})();
(function () {
  'use strict';
  new Batman('batmanBankingBox2', 'bankingBox');
})();
(function () {
  'use strict';
  new Batman('batmanBankingBox3', 'bankingBox');
})();
