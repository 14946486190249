import '../../service/domain/article.js';
(function () {
  'use strict';

  const component = { name: 'lindaArticleBox' };

  controller.$inject = ['$scope', 'article'];

  function controller($scope, _article) {
    $scope.article = {};
    $scope.preloader = false;

    this.$onChanges = function () {
      if (typeof this.name !== 'undefined') {
        $scope.preloader = true;
        _article.item({ name: this.name }).then(
          (answer) => {
            $scope.article = answer.result;
            $scope.preloader = false;
          },
          (answer) => {
            $scope.preloader = false;
          }
        );
      }
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      name: '<',
    },
  });
})();
