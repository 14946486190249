import '../../../service/rx/system$.js'
import '../../../service/rx/splash$.js'
import '../../../service/configs/config.js'
import { Subject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const name = 'lindaFreshchat';


  controller.$inject = ['system$', 'splash$', 'config'];

  function controller(_system$, _splash$, _config) {
    const chatActivation$ = new Subject();
    const openChatEvent$ = _system$.pipe(filter(({ action }) => action === 'open chat'));
    combineLatest([chatActivation$, openChatEvent$]).subscribe(([openChatCb]) => openChatCb());

    const link = () => {
      const config = _config.freshChat || {};

      function initiateCall() {
        initialize(document, 'Freshdesk Messaging-js-sdk');
      }

      function initialize(i, t) {
        var e;
        i.getElementById(t)
          ? initFreshChat()
          : (((e = i.createElement('script')).id = t),
            (e.async = !0),
            (e.src = 'https://wchat.eu.freshchat.com/js/widget.js'),
            (e.onload = initFreshChat),
            i.head.appendChild(e));
      }

      function initFreshChat() {
        window.fcWidget.init({ ...config });
        chatActivation$.next(openChat);
      }

      function openChat() {
        window.fcWidget.open();
      }

      let subscription;

      subscription = _splash$
        .pipe(filter((m) => m === 'loaded'))
        .subscribe(() => {
          initiateCall();
          subscription.unsubscribe();
        });
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
