import { BehaviorSubject } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = [];

  function controller() {
    const authBufferData$ = new BehaviorSubject(null);

    return {
      preSavedData() {
        return {
          set: (data) => {
            authBufferData$.next(data)
          },
          get: () => {
            let lastValue;
            authBufferData$.subscribe((data) => { lastValue = data });
            return lastValue;
          }
        }
      },
    }
  }



  app.factory('authWithDeposit', controller);
})();
