import '../service/deferred-prompt.js';
(function () {
  'use strict';

  const directive = { name: 'installAppBox' };

  controller.$inject = ['deferredPrompt'];

  function controller(_deferredPrompt) {
    function compile() {
      return function ($scope, $element, $attrs, $ctrl, $transclude) {
        if (_deferredPrompt.isEnabled) {
          run();
        } else {
          const sEnabled = _deferredPrompt.isEnabled$.subscribe(() => {
            run();
            sEnabled.unsubscribe();
          });
          $scope.$on('$destroy', () => {
            sEnabled.unsubscribe();
          });
        }

        function run() {
          $transclude($scope.$new(true), (clone, scope) => {
            scope.install = () => {
              _deferredPrompt.open();
            };
            $element[0].parentNode.insertBefore(clone[0], $element[0].nextSibling);
          });

          const sAnswer = _deferredPrompt.answer$.subscribe(() => {
            removeBox();
          });
          $scope.$on('$destroy', () => {
            sAnswer.unsubscribe();
          });

          function removeBox() {
            $element[0].parentNode.removeChild($element[0].nextSibling);
            $element[0].parentNode.removeChild($element[0]);
          }
        }
      };
    }

    return {
      restrict: 'A',
      transclude: 'element',
      compile,
    };
  }

  app.directive(directive.name, controller);
})();
