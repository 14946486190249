(function () {
  'use strict';
  const directive = { name: 'lindaScrollTop' };

  controller.$inject = ['$document', '$state'];

  function controller($document, $state) {
    function link(scope, element, attrs) {
      const scrollClass = attrs[directive.name] ? `.${attrs[directive.name]}` : null;
      const targetScrollElement = scrollClass ? document.querySelector(scrollClass) : window;

      function scrollFunction() {
        if (typeof window.scroll === 'function') {
          targetScrollElement?.scroll({
            top: 0,
            behavior: 'smooth',
          });
          return;
        }

        setTimeout(function () {
          const targetAngularElement = scrollClass ? angular.element(targetScrollElement) : $document;
          targetAngularElement?.duScrollTopAnimated(0, 400);
        }, 0);
      }

      scope.$on('$stateChangeSuccess', function () {
        if (!$state.params?.disableScrollTop) {
          scrollFunction();
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
