import '../../service/domain/content-buffer.js';
import '../../service/domain/remembered.js';
import '../../service/domain/auth-with-deposit.js';
(function () {
  'use strict';

  const component = { name: 'authWithDepositPopup' };

  controller.$inject = ['$scope', 'contentBuffer', 'remembered', 'authWithDeposit'];

  function controller($scope, _contentBuffer, _remembered, _authWithDeposit) {
    const vm = this;
    const content = _contentBuffer.read('popup');
    const preSavedData = _authWithDeposit.preSavedData();
    $scope.hasRememberedUser = !!_remembered.login;

    $scope.depositInfo = preSavedData.get();
    $scope.paysystemStep = true;
    $scope.registrationStep = false;
    $scope.loginStep = false;
    $scope.$watch('paysystemStep', (newVal, oldVal) => {
      $scope.depositInfo = preSavedData.get();
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
