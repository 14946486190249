import otpVerification from '../../service/otp/otp-verification.js';
import { filter } from 'rxjs/operators';

(function () {
  const component = { name: 'lindaOtpComponents' };

  class Otp {
    static $inject = ['$scope', '$timeout', '$document', 'otpVerification'];

    constructor($scope, $timeout, $document, otpVerification) {
      const keys = {
        tab: 'Tab',
        backSpace: 'Backspace',
        letterV: 'KeyV',
        space: 'Space',
      };

      $scope.onPaste = function (index, event) {
        event.preventDefault();
        if (index !== 0) {
          return;
        }
        const pasteData = event.clipboardData?.getData('text');

        if (!pasteData || isNaN(+pasteData) || pasteData.length !== $scope.size) {
          return;
        }
        $scope.inputs = pasteData.split('');
        $document[0].getElementById('otp-input-' + ($scope.inputs.length - 1)).focus();
      };

      const focusCurrentInput = (inputIndex) => {
        const currentInput = $document[0].getElementById(`otp-input-${inputIndex}`);
        currentInput.focus();
      };

      const checkOnBackSpace = (eventKey) => {
        return eventKey === 'Backspace' || eventKey === 'Delete';
      };

      $scope.onKeyUp = (index, event) => {
        const isDigit = !isNaN(+event.key);
        if (checkOnBackSpace(event.key) && index - 1 >= 0) {
          $scope.inputs[index] = '';
          focusCurrentInput(index - 1);
          return;
        }
        if (!isDigit && event.key === 'Unidentified') {
          $scope.inputs[index] = '';
          return;
        }
        if (isDigit) {
          $scope.inputs[index] = event.key;
          if (index + 1 <= $scope.size - 1) {
            focusCurrentInput(index + 1);
          }
        }
      };

      const handlePasteEvent = (code, metaKey, windowsCtrl) => {
        return (code === keys.letterV && metaKey) || (code === keys.letterV && windowsCtrl);
      };

      $scope.onKeyDown = function (index, event) {
        const { code, metaKey, key } = event;
        const isDigit = !isNaN(+key);
        if (checkOnBackSpace(key) || handlePasteEvent(code, metaKey, event.ctrlKey)) {
          return;
        }
        if (!isDigit) {
          focusCurrentInput(index);
          event.preventDefault();
        }
      };

      this.$onInit = function () {
        $timeout(() => {
          focusCurrentInput(0);
        });
      };

      this.$onChanges = function () {
        $scope.size = this.size;
        $scope.inputsDisabled = this.inputsDisabled;
        $scope.inputs = new Array($scope.size).fill('');
      };

      const subscription = otpVerification.otpVerified$
        .pipe(filter((item) => item.event === 'preloader'))
        .subscribe(({ data }) => {
          $scope.preloader = data.status;
        });

      $scope.$watchCollection('inputs', function (newValue) {
        const otpInputValue = newValue.join('');
        if (otpInputValue.length === $scope.size) {
          $scope.preloader = true;
          otpVerification
            .sendCode(otpInputValue)
            .then(({ result }) => {
              otpVerification.otpVerified$.next({ event: 'codeVerified', data: result });
            })
            .catch((error) => {
              if (error.messages[0].code === 6347) {
                $scope.showInvalidOtpError = true;
              }
              if (error.messages[0].code === 6351) {
                otpVerification.otpVerified$.next({ event: 'verificationBlocked', data: error.messages[0].data });
                $scope.showBlockedOtpError = true;
                $scope.showInvalidOtpError = false;
              }
              $scope.preloader = false;
            });
        }
      });
      this.$onDestroy = () => {
        otpVerification.otpVerified$.next({ event: 'inputsAvailable' });
        subscription.unsubscribe();
      };
    }
  }

  app.component(component.name, {
    controller: Otp,
    template: app.getTU(component.name),
    bindings: { size: '<', inputsDisabled: '<' },
  });
})();
