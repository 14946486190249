import '../../../service/domain/user.js';
import '../../../service/gamification/sport-cards/card.js';
import '../../../service/domain/popup.js';
import '../../../service/domain/modal.js';
import '../../../service/domain/state.js';
import '../../../service/domain/remembered.js';
(function () {
  'use strict';

  controller.$inject = ['$scope', 'user', 'card', 'popup', 'modal', 'state', 'remembered'];

  function controller($scope, _user, _card, _popup, _modal, _state, _remembered) {
    $scope.preloader = false;
    $scope.card = {};

    $scope.open = function () {
      let team = {};

      if (!_user.status) {
        const rememberedLogin = _remembered.login;

        if (!rememberedLogin) {
          return _state.goto('registration');
        }

        return _state.goto('login');
      }

      if (_user.profile.coins >= $scope.card.price) {
        _modal
          .open('sport-card-open', $scope.card)
          .then(() => {
            $scope.preloader = true;
            _card
              .open({
                alias: $scope.card.alias,
              })
              .then((a) => {
                $scope.card.status = a?.card?.status || 'open';
                team = a.result.find((item) => item.alias === $scope.card.team.alias);

                if (team.progress.general === 100 || team.progress.current === 0) {
                  _popup.open({
                    name: 'sport-cards-complete-team',
                    content: team,
                  });
                  return;
                }
                if (a.card.rewards && a.card.rewards.length) {
                  _popup.open({
                    name: 'autobonus-action',
                    content: a.card.rewards[0].bonuses,
                  });
                }
              })
              .catch((e) => {})
              .finally(() => {
                $scope.preloader = false;
              });
          })
          .catch(() => {});
      } else {
        _popup.open({
          name: _user.profile.coins === 0 ? 'sport-cards-no-points-already' : 'sport-cards-points-missing',
          content: $scope.card,
        });
      }
    };

    this.$onChanges = function () {
      $scope.card = this.card;
    };
  }

  const bindings = {
    card: '<',
  };

  new Controller('sportCardItem', controller, bindings);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSportCardItem${i}`, 'sportCardItem');
  });
})();
