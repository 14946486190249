(function () {
  'use strict';

  const directive = { name: 'nativeDatepicker' };

  controller.$inject = ['$filter', 'birthdayDatepicker'];

  function controller($filter, _birthdayDatepicker) {
    const link = ($scope, $element, $attrs, $ctrl) => {
      const params = $scope.$eval($attrs[directive.name]) || {};

      $ctrl.$parsers.push((value) => {
        return $filter('date')(value, 'yyyy-MM-dd');
      });

      $ctrl.$formatters.push((value) => {
        return new Date(value);
      });

      if (params.setDefaultFormData) {
        const targetElement = $element[0];
        const focusListener = () => $scope.formData.birthday = $element.val() || _birthdayDatepicker.maxDateValue;
        targetElement.addEventListener('focus', focusListener);
        $scope.$on('$destroy', () => targetElement.removeEventListener(focusListener));
      }
    };

    return {
      require: 'ngModel',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
