import '../../service/configs/promo.js';

(function () {
  'use strict';

  const directive = { name: 'lindaPromoNotification' };

  factory.$inject = ['$window', 'promo.config'];

  function factory($window, _promo_config) {
    function compile() {
      return function ($scope, $element, $attrs, $ctrl, $transclude) {
        $scope.closePromoNotification = closePromoNotification;

        const promoName = $attrs[directive.name];
        const nflags = ($window.localStorage.getItem('promo-notification-flag') || '').split(',');

        if (_promo_config[promoName] && nflags.indexOf(promoName) < 0) {
          $transclude($scope.$new(true), (clone, scope) => {
            scope.closePromoNotification = closePromoNotification;
            $element[0].parentNode.insertBefore(clone[0], $element[0].nextSibling);
          });
        }

        function closePromoNotification() {
          if (nflags.indexOf(promoName) < 0) {
            nflags.push(promoName);
            $window.localStorage.setItem('promo-notification-flag', nflags.join(','));
            $element[0].parentNode.removeChild($element[0].nextSibling);
            $element[0].parentNode.removeChild($element[0]);
          }
        }
      };
    }

    return {
      restrict: 'A',
      transclude: 'element',
      compile,
    };
  }

  app.directive(directive.name, factory);
})();
