(function () {
  'use strict';

  app.directive('hideAfterNy', controller);
  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {}

    return {
      restrict: 'A',
      link,
    };
  }
})();
