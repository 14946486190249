(function () {
  'use strict';

  if (!Array.prototype.last) {
    Array.prototype.last = function () {
      return this[this.length - 1];
    };
  }

  if (!Array.prototype.toggle) {
    Array.prototype.toggle = function (item, state) {
      // наявність елемента в масиві
      const index = this.indexOf(item);

      switch (state) {
        // якщо потрібно тільки додати
        case true:
          if (index === -1) this.push(item);
          break;

        // якщо потрібно тільки видалити
        case false:
          if (index !== -1) this.splice(index, 1);
          break;

        // якщо потрібно додати або видалити
        default:
          index === -1 ? this.push(item) : this.splice(index, 1);
          break;
      }
    };
  }
  if (!Array.prototype.toObject) {
    Array.prototype.toObject = function (key) {
      const r = {};
      this.forEach((i, index) => {
        r[i[key || Object.keys(i)[0]]] = i;
      });
      return r;
    };
  }
})();
