import '../../service/helper.js';
(function () {
  'use strict';

  const directive = { name: 'lindaCountUp' };

  controller.$inject = ['helper'];

  function controller(_helper) {
    function link(scope, element, attrs) {
      const options = {
        useEasing: true,
        useGrouping: true,
        separator: ' ',
        decimal: '.',
      };

      const cancel = attrs.$observe(directive.name, (val) => {
        if (val > 0) {
          cancel();
          const demo = new CountUp(element[0], 10000000, val, 0, 2.5, options);

          const interval = setInterval(() => {
            if (_helper.dom.isElementInViewport(element[0])) {
              clearInterval(interval);

              if (!demo.error) demo.start();
            }
          }, 100);
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
