(function () {
  'use strict';

  app.factory('analyticsEvents', controller);

  function controller() {
    return {
      registrationSuccess: 'registration_form_success',
      registrationFail: 'registration_form_failure',
      loginSuccess: 'login_form_success',
      loginFail: 'login_form_failure',
      zimplerAuthSuccess: 'zimpler_authorization_success',
      zimplerAuthFail: 'zimpler_authorization_failure',
      bankidAuthSuccess: 'bankid_authorization_success',
      bankidAuthFail: 'bankid_authorization_failure',
      registerPopup: 'Register_Popup',
      createAccountButton: 'ClickCreateAccountButton',
      popupOpen: 'Popup_Open',
      popupClose: 'Popup_Close',
      modalOpen: 'Modal_Open',
      balanceFTD: 'balance_ftd',
      balanceDeposit: 'balance_deposit',
      recoveryFormSuccess: 'recovery_form_success',
      recoveryFormFail: 'recovery_form_failure',
      recoveryRequestSuccess: 'recovery_request_success',
      recoveryRequestFail: 'recovery_request_failure',
      gameLaunchSuccess: 'Game_Launch_Success',
      gameLaunchFail: 'Game_Launch_Failure',
      SwMirrorRedirect: 'swMirror_redirect',
    };
  }
})();
