import '../../service/domain/game.js';
import '../../service/domain/appBrowser.js';
(function () {
  'use strict';

  controller.$inject = ['game', 'appBrowser'];

  function controller(_game, _appBrowser) {
    return {
      launch(options) {
        let firstPreloader = true;

        let browser = _appBrowser
          .onExit(() => {})
          .onLoad(loadstart)
          .open('preloader.html');

        function loadstart(e) {
          if (e.url.indexOf('preloader.html') + 1 && !firstPreloader) {
            browser.close();
          }
          if (e.url.indexOf('preloader.html') + 1 && firstPreloader) {
            firstPreloader = false;

            _game.launch(options).then(
              (answer) => {
                browser.location = answer.result.url;
              },
              (answer) => {
                browser.close();
              }
            );
          }
        }
      },
    };
  }

  app.factory('gameMobileApp', controller);
})();
