class DescriptionService {
  static $inject = ['$document'];

  constructor($document) {
    this.$document = $document[0];
    this.descriptionRef = this.$document.head.querySelector('meta[name="description"]');
    this.targetNode = this.$document.querySelector('title');
    if (this.descriptionRef === null) {
      this.create();
    }
  }

  create() {
    this.descriptionRef = this.$document.createElement('meta');
    this.descriptionRef.setAttribute('name', 'description');
    return this.targetNode.after(this.descriptionRef);
  }

  update(description) {
    if (angular.isString(description)) this.descriptionRef.setAttribute('content', description);
  }
}

app.service('descriptionService', DescriptionService);
