import '../../service/domain/content-buffer.js'
import '../../service/domain/user.js'
import '../../service/configs/config.js'
(function () {
  'use strict';

  const name = 'lindaTranslate';

  controller.$inject = ['$compile', '$translate', 'contentBuffer', 'user', 'config'];

  function controller($compile, $translate, _contentBuffer, _user, _config) {
    function link(scope, element, attrs) {
      const { profile, balance, bonus, currency, login } = _user.profile;

      const bucket = {
        user: { profile, balance, bonus, currency, login, activeBonus: _user.activeBonus },
        data: _contentBuffer.read('modal')
          ? _contentBuffer.read('modal').data
          : _contentBuffer.read('popup')
          ? _contentBuffer.read('popup').data
          : null,
        links: _config.links,
        scope,
      };

      attrs.$observe(name, (key) => {
        $translate(key, bucket).then(
          (template) => {
            element.html(template);
            $compile(element.contents())(scope);
          },
          (key) => {}
        );
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
