(function () {
  'use strict';

  controller.$inject = ['$state', '$transitions'];

  function controller($state, $transitions) {
    function link(scope, element, attrs) {
      const variable = attrs.getStateParamsToScope || 'stateParams';

      scope[variable] = {};
      scope[variable].__proto__ = $state.transition ? $state.transition.params() : $state.params;

      $transitions.onSuccess({}, function (transition) {
        scope[variable].__proto__ = $state.transition ? $state.transition.params() : $state.params;
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('getStateParamsToScope', controller);
})();
