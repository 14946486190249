import '../service/domain/category.js';
(function () {
  'use strict';

  const directive = { name: 'getCategoriesToScope' };

  factory.$inject = ['category'];

  function factory(_category) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'categories';

      scope[variable] = {};

      _category
        .list({
          count: 1000,
        })
        .then((a) => {
          scope[variable].__proto__ = _category.object;
        })
        .catch((e) => {});
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
