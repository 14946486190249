import Parallax from 'parallax-js';

(function(){

    'use strict';

    const directive = { name: 'parallax' };

    app.directive( directive.name, controller );

    controller.$inject = [];

    function controller(){

        function link( scope, element, attrs ){

            let i = 0;
            const interval = 50;
            const max = 2000 / interval;


            const watcher = setInterval( () => {
                let status = typeof Parallax !== 'undefined';
                i ++;

                console.log( 'Parallax defined:', i, status );

                const params = scope.$eval( attrs[directive.name] ) || {};

                if( status ){

                    const parallaxInstance = new Parallax( element[0], params );
                    clearInterval( watcher );

                }


                if( i >= max ){
                    clearInterval( watcher );
                }


            }, interval );


/*
            const parallaxInstance = new Parallax( element[0], {
                relativeInput: true
            } );
*/


        }

        return {
            restrict: 'A',
            link
        };
    }



})();
