import '../../../service/domain/history.js';
import '../../../service/datepicker/balance-history-datepicker.js';
import '../../../service/rx/history$.js';
import { filter } from 'rxjs/operators';
(function () {
  'use strict';

  const component = { name: 'lindaCashboxBalanceInformation' };

  controller.$inject = ['$scope', 'history', 'history$', 'balanceHistoryDatepicker'];

  function controller($scope, _history, _history$, _balanceHistoryDatepicker) {
    $scope.collection = [];
    $scope.formData = {
      type: [],
      status: [],
      date: {
        from: new Date().toISODate(),
        to: new Date().toISODate(),
      },
    };

    $scope.isRange = false;

    $scope.datepickerOptions = {
      maxDate: _balanceHistoryDatepicker.todayDate,
    };

    $scope.$watchGroup(['formData.date.from', 'isRange'], function (newValues) {
      $scope.minValue = newValues[0];
      if (!$scope.isRange) {
        $scope.formData.date.to = newValues[0];
      }
    });

    _history$
      .pipe(filter((i) => i.action === 'maxDays'))
      .subscribe(() => {
        $scope.isRange = _balanceHistoryDatepicker.checkIfSingleDay();
      });

    $scope.getBalanceFlow = () => {
      $scope.preloader = true;
      const params = {
        status: $scope.formData.status,
        type: $scope.formData.type,
        date_begin: $scope.formData.date.from,
        date_end: $scope.formData.date.to,
      };
      _history
        .balanceFlow(params)
        .then((a) => {
          $scope.collection = a.result.map((item) => {
            item.date = item.date * 1000;
            return item;
          });
        })
        .catch((a) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    $scope.getBalanceFlow();

    $scope.resetFilters = () => {
      $scope.formData = {
        type: [],
        status: [],
        date: {
          from: new Date(),
          to: new Date(),
        },
      };
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
