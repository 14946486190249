(function () {
  'use strict';
  const directive = {name: 'rabonaRegistrationPromocode'};

  controller.$inject = ['$timeout'];

  function controller($timeout) {
    function link($scope, element){
      $scope.promocodeLabelFlag = false;

      $scope.setStatusPromocode = (status = false, type, event) => {
        $scope.promocodeLabelFlag = status;

        switch (type) {
          case 'bonus':
            $scope.formData.code = '';
            $scope.hasPromocode = false;
            break;

          case 'without':
            $scope.formData.code = '';
            $scope.activePromotion = '';
            $timeout(() => {
              $scope.formData.enable_trigger_codes = true;
              $scope.formData.trigger_codes = 'no_bonus';
            });
            $scope.hasPromocode = false;
            break;

          case 'promocode':
            $scope.activePromotion = '';
            $scope.formData.enable_trigger_codes = false;
            $scope.hasPromocode = true;
            break;

          default:
            break;
        }
      }
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive(directive.name, controller);
})();
