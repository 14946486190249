(function () {
  'use strict';
  const directive = { name: 'getCurrentTimeToScope' };

  controller.$inject = ['$interval'];

  function controller($interval) {
    function link(scope, element, attrs) {
      const variable =
        attrs.getCurrentTimeToScope && attrs.getCurrentTimeToScope !== 'get-current-time-to-scope'
          ? attrs.getCurrentTimeToScope
          : 'currentTime';

      scope[variable] = new Date().getTime();

      $interval(() => {
        scope[variable] = new Date().getTime();
      }, 1000);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
