import '../../../service/domain/banner.js'
import '../../../service/rx/system$.js'
import { filter } from 'rxjs/operators'
(function () {
  controller.$inject = ['$scope', 'banner', '$timeout', 'system$'];

  function controller($scope, _banner, $timeout, _system$) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.transition = false;

    let subscription1, subscription2;

    const o = {};

    this.$onInit = () => {
      o.category = 'slider';
      o.place = this.place;

      getCollection(o);

      subscription1 = _system$
        .pipe(filter((m) => m.action === 'login'))
        .subscribe((m) => {
          o.auth = true;
          getCollection(o);
        });
      subscription2 = _system$
        .pipe(filter((m) => m.action === 'logout'))
        .subscribe((m) => {
          o.auth = false;
          getCollection(o);
        });
    };

    this.$onDestroy = () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
    };

    $scope.getCurrent = (event, slick, currentSlide, nextSlide) => {
      $scope.current = nextSlide;
      $scope.transition = true;
      $timeout(() => {
        $scope.transition = false;
      }, 600);
    };

    function getCollection(c) {
      $scope.current = 0;
      $scope.loaded = false;
      $scope.preloader = true;
      _banner.collection(c).then(
        (answer) => {
          $scope.collection = answer.result;
          $scope.loaded = true;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }
  }

  new Controller('slider', controller, { place: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSlider${i}`, 'slider');
  });
})();
