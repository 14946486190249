import { ContextRoot, provide } from '@lit/context';
import { PropertyValues } from '@lit/reactive-element';
import { css, CSSResult, html, LitElement, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';

import './components/funid-auth-button';
import './components/funid-auth-modal';
import LightTheme from '@shoelace-style/shoelace/dist/themes/light.styles.js';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { funIdConfigContext } from './services/context/funid-config-context';
import { FunIdAuthLogger } from './services/funid-auth-logger';
import { funIdAuthLoggerContext } from './services/context/funid-auth-logger-context';
import {
  FunIdAuthDataModel,
  FunidAuthEvent,
  FunIdDialogParams,
  FunidViewType,
} from './model/funid-auth-model';
import { FunIdConfigService } from './services/funid-config-service';
import { FunidElementsCreator } from './services/funid-elements-creator';
import { FunIdAuthService } from './services/funid-auth-service';
import { Task } from '@lit/task';

@customElement('funid-auth')
export class FunidAuth extends LitElement {
  public static styles: CSSResult[] = [
    LightTheme,
    css`
      :host {
        --text-main: var(--fid-uth-text-main, #1b3063);
        --text-secondary: var(--fid-uth-text-secondary, #7e7bb4);
        --fid-auth-button-text-color: #fff;
        --fid-auth-button-background-color: #3f38dd;
        --fid-auth-button-padding: 6px;
        --fid-auth-dialog-z-index: 1;
        --fid-auth-dialog-qr-width: 200px;
        --fid-auth-dialog-qr-margin: 20px auto 20px;
        --fid-auth-dialog-qr-color: var(--text-main);
        --fid-auth-get-app-button-text-color: #fff;
        --fid-auth-get-app-button-background-color: #3f38dd;
        --fid-auth-show-more-button-color: #3f38dd;
        --fid-auth-details-state-background-color: #f1f3fb;
        --fid-auth-details-title-color: #3f38dd;
        --fid-auth-guide-background-color: #fff;
        --fud-auth-close-button-background-color: #fff;

        font-family: 'Montserrat', sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-style: normal;
        display: block;
      }
    `,
  ];

  public render(): TemplateResult {
    return html`
      ${this.dataTask.render({
        complete: (data: FunIdAuthDataModel) => html`
          <funid-auth-modal
            .open="${this.popupOpened}"
            .viewType="${this.viewType}"
            .url="${this.config.getQrUrl()}"
            .data="${data}"
            .close="${this.closeDialog.bind(this)}"
          ></funid-auth-modal>

          <slot>
            <funid-auth-button
              @click=${this.openDialogEvent}
              .text="${data.title}"
              .logo="${data.logo}"
            ></funid-auth-button>
          </slot>
        `,
        error: error => html`<p>Oops, something went wrong: ${error}</p>`,
      })}
    `;
  }

  @provide({ context: funIdAuthLoggerContext })
  public logger: FunIdAuthLogger = new FunIdAuthLogger();

  @provide({ context: funIdConfigContext })
  public config: FunIdConfigService = new FunIdConfigService();

  @property({ type: String, reflect: true }) viewType: FunidViewType =
    FunidViewType.QrCode;
  @property({ type: String, reflect: true }) langCode: string = 'en';
  @property({ type: String, reflect: true }) qrcUrl!: string;
  @property({ type: String, reflect: true })
  public transactionKey: string = 'transaction_id';

  private readySubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readySubscription: Subscription | null = null;

  @state()
  protected popupOpened: boolean = false;

  private dataTask: Task<string[], FunIdAuthDataModel> = new Task(this, {
    task: async ([apiUrl]): Promise<FunIdAuthDataModel> => {
      const langCode: string = await this.getLangCode(this.langCode);
      return FunIdAuthService.getWidget(apiUrl, langCode);
    },
    args: () => [this.config.getApiUrl()],
    autoRun: true,
  });

  constructor() {
    super();
    this.connectFonts();
  }

  public connectedCallback(): void {
    super.connectedCallback();

    const root: ContextRoot = new ContextRoot();
    root.attach(document.body);

    if (this.qrcUrl) {
      const transactionId: string = this.getTransactionId(this.qrcUrl);

      this.config.set(this.qrcUrl, transactionId);
      this.logger.log('Config updated: ', {
        qrcUrl: this.qrcUrl,
        transactionId,
      });
    }
  }

  public firstUpdated(changedProperties: PropertyValues): void {
    super.firstUpdated(changedProperties);
    this.readySubject$.next(true);
  }

  public attributeChangedCallback(
    name: string,
    oldValue: string | null,
    value: string | null
  ): void {
    super.attributeChangedCallback(name, oldValue, value);
    this.logger.log('Props changed:', { name, oldValue, newValue: value });

    if (name === 'langcode' && value !== oldValue) {
      this.logger.log(`langCode changed: ${value}`);
      this.dataTask.run([this.config.getApiUrl()]);
    }
  }

  public disconnectedCallback(): void {
    super.disconnectedCallback();
    this.readySubscription?.unsubscribe();
  }

  public openDialogEvent(): void {
    if (typeof this.qrcUrl !== 'undefined') {
      this.logger.log('openDialogEvent');
      this.openDialog();
    } else {
      this.dispatchEvent(
        new CustomEvent(FunidAuthEvent.AuthOpen, {
          detail: {
            handler: this.openDialog.bind(this),
          },
          bubbles: true,
          composed: true,
        })
      );
      this.logger.log(`"${FunidAuthEvent.AuthOpen}" custom event dispatched`);
    }
  }

  public openDialog(params?: FunIdDialogParams): void {
    this.readySubscription = this.readySubject$
      .asObservable()
      .pipe(filter(Boolean))
      .subscribe(() => {
        if (
          params?.viewType &&
          Object.values(FunidViewType).includes(params.viewType)
        ) {
          this.viewType = params.viewType;
        }

        if (params?.qrcUrl) {
          this.qrcUrl = params.qrcUrl;
          this.langCode = params.langCode;

          const transactionId: string = this.getTransactionId(this.qrcUrl);

          this.config.set(this.qrcUrl, transactionId);
          this.logger.log('Config updated: ', {
            qrcUrl: this.qrcUrl,
            transactionId,
          });
        }

        this.popupOpened = true;
        this.logger.log('"openDialog()" called');
        this.requestUpdate();
      });
  }

  public closeDialog(): void {
    this.popupOpened = false;
    this.logger.log('Dialog closed');
    this.dispatchEvent(
      new CustomEvent(FunidAuthEvent.AuthClose, {
        bubbles: true,
        composed: true,
      })
    );
  }

  private connectFonts(): void {
    const font: HTMLElement = FunidElementsCreator.createNode('link', {
      attrs: {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap',
      },
    });

    document.head.appendChild(font);
    this.logger.log('Fonts connected');
  }

  private getTransactionId(query: string): string {
    const url: URL = new URL(query);

    if (url.searchParams.has(this.transactionKey)) {
      const transactionId: string = url.searchParams.get(
        'transaction_id'
      ) as string;

      this.logger.log(`transactionId:  ${transactionId}`);

      return transactionId;
    } else {
      this.logger.log('transaction_id not found');
      throw new Error("'transaction_id' not found in options [funid-auth]");
    }
  }

  private async getLangCode(code: string): Promise<string> {
    const langMap: Record<string, string> = {
      no: 'nn',
      gr: 'el',
    };
    const defaultLangCode: string = 'en';
    const apiUrl: string = this.config.getApiUrl();
    let langCode: string = code;

    if (code in langMap) {
      langCode = langMap[code];
    }

    if (!apiUrl) {
      return defaultLangCode;
    }

    const availableLanguages: string[] =
      await FunIdAuthService.getAvailableLangs(apiUrl);
    const isValidLang: boolean = availableLanguages?.includes(langCode);

    if (!isValidLang) {
      return defaultLangCode;
    }

    return langCode;
  }
}
