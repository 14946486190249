import '../../service/domain/account.js';
import '../../service/helper.js';
import '../../service/domain/popup.js';
import '../../service/localStorage.js';
import '../../service/domain/auth-with-deposit.js';
import '../../service/domain/paymentWindow.js';

(function () {
  'use strict';

  const component = {
    name: 'authWithDepositLogin',
  };

  controller.$inject = ['$scope', '$timeout', 'account', 'helper', 'popup', 'localStorage', 'authWithDeposit', 'paymentWindow'];

  function controller($scope, $timeout, _account, _helper, _popup, _localStorage, _authWithDeposit, _paymentWindow) {
    $scope.rememberedLogin = _localStorage.get('rememberedLogin') || false;
    $scope.rememberedAvatar = _localStorage.get('rememberedAvatar') || '';
    $scope.rememberedName = _localStorage.get('rememberedName') || '';
    $scope.preloader = false;

    $scope.credentials = {
      login: $scope.rememberedLogin === false ? undefined : $scope.rememberedLogin,
    };

    $scope.resetLoginCredentials = () => (($scope.credentials.login = ''), ($scope.rememberedLogin = false));

    const parentScope = $scope.$parent.$parent;

    $scope.backToPayments = () => {
      parentScope.paysystemStep = true;
      parentScope.hideLogin = true;
    };

    $scope.switchToRegistration = () => {
      parentScope.registrationStep = true;
      parentScope.loginStep = false;
      parentScope.hasRememberedUser = false;
    };

    const login = (form) => {
      $scope.preloader = true;
      const preSavedData = _authWithDeposit.preSavedData().get();
      let preparedWindow = {};

      const checkIfCanOpenPayment = () => {
        const { max_amount, min_amount, fields } = preSavedData.paysystem;
        const { amount } = preSavedData;

        return !(max_amount < amount || min_amount > amount || fields.length > 0);
      };

      const checkIfValidCurrencyAndCountry = ({currency, country}) => {
        return (preSavedData.currency === currency && preSavedData.country === country);
      };

      if (checkIfCanOpenPayment()) {
        preparedWindow = _paymentWindow.prepared();
        const currentWindow = window;
        currentWindow.focus();
      }

      _account
        .login($scope.credentials)
        .then((answer) => {
          _popup.close();

          if (!checkIfValidCurrencyAndCountry(answer.result)) {
            _popup.open({ name: 'cashbox', content: { loginWarning: true } });
            preparedWindow.close();
            return;
          }

          if (!checkIfCanOpenPayment()) {
            _popup.open({
              name: 'cashbox',
              content: {
                tab: 'deposit',
                step: 'form',
                paySystem: preSavedData.paysystem,
                amount: preSavedData.amount,
              },
            });
            preparedWindow.close();
            return;
          }

          _paymentWindow.open($scope.credentials.login, preSavedData, preparedWindow);
        })
        .catch((error) => {
          preparedWindow.close();
          console.error(error);
        })
        .finally(() => {
          $timeout(() => {
            $scope.preloader = false;
          }, 0);
        });
    };

    $scope.submit = _helper.form.validator(login);
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
