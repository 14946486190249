import '../../service/configs/config.js'
(function () {
  const directive = { name: 'sliderSplide' };

  controller.$inject = ['config', '$compile'];

  function controller(_config, $compile) {
    function link(scope, element, attrs) {
      if (typeof Splide !== 'function') return;

      const o = scope.$eval(attrs[directive.name]);
      const instanceKey = o.instance || 'splide';
      const collectionKey = o.collectionKey || 'collection';
      const slideClone = element[0].querySelector('.splide__slide').cloneNode(true);
      const slidesWrapper = element[0].querySelector('.splide__list');

      scope.$watchCollection(collectionKey, (collection) => {
        if (collection && collection.length) {
          renewSlider(collection);
        }
      });

      function renewSlider(collection) {
        if (scope[instanceKey]) destroySlider();

        buildSlider(collection);
      }

      function buildSlider(collection) {
        repeatSlides(collection);

        scope[instanceKey] = initSwiper(collection);
      }

      function initSwiper(collection) {
        var splide = new Splide('.splide', {
          perPage: 1,
          perMove: 1,
          drag: 'true',
          type: 'fade',
          speed: 800,
          easing: 'ease',
          focus: 'center',
          trimSpace: false,
          arrows: false,
          keyboard: false,
          autoWidth: false,
          slideFocus: false,
          pagination: true,
          autoplay: false,
        });

        splide.on('mounted', function () {
          bindCollectionToSlides(collection, splide);
        });

        splide.mount();

        return splide;
      }

      function bindCollectionToSlides(collection, splide) {
        splide.Components.Slides.forEach((item) => {
          const indexFromCollection = item.isClone ? item.slideIndex : item.index;
          const slideScope = scope.$new();
          slideScope.slide = collection[indexFromCollection];
          $compile(item.slide)(slideScope);
        });
      }

      function repeatSlides(collection) {
        const repeatedSlides = document.createDocumentFragment();

        collection.forEach(() => {
          repeatedSlides.appendChild(slideClone.cloneNode(true));
        });

        slidesWrapper.replaceChildren(repeatedSlides);
      }

      function destroySlider() {
        scope[instanceKey].destroy(true);
        scope[instanceKey] = null;
      }

      scope.$on('$destroy', destroySlider);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
