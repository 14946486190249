import '../../../service/domain/account.js';
import '../../../service/domain/content-buffer.js';
import '../../../service/domain/popup.js';
(function () {
  'use strict';

  const component = { name: 'lindaSelfcontrolChangeExclusionForm' };

  controller.$inject = ['$scope', 'account', 'contentBuffer', 'popup'];

  function controller($scope, _account, _contentBuffer, _popup) {
    $scope.period = '5';
    $scope.count = 1;
    $scope.formData = {
      frozen_to: `${$scope.period}${$scope.count}`,
    };

    $scope.$watchGroup(['period', 'count'], (newValues, oldValues, scope) => {
      if (scope.period === '7') {
        $scope.formData.frozen_to = '71';
        return;
      }
      $scope.formData.frozen_to = `${scope.period}${scope.count}`;
    });

    $scope.submit = (form) => {
      const token = _contentBuffer.read('modal').data.token || null;
      const excludeData = {
        frozen_to: $scope.formData.frozen_to,
        token: `${token}`,
      };
      _account.exclude(excludeData).then(
        (answer) => {
          _popup.close();
        },
        (answer) => {}
      );
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
