import '../service/configs/config.js';
(function () {
  'use strict';

  app.filter('icon', func);

  func.$inject = ['config'];

  function func(_config) {
    return function (string) {
      let sign = string + '';

      _config.currency.forEach(function (item) {
        if (string) if (string.toUpperCase() === item.key) if (item.icon) sign = item.icon;
      });

      return sign;
    };
  }
})();
