import '../../service/domain/content-buffer.js';

const componentName = 'lindaBalanceAlertPopup';

class balanceAlertPopup {
  static $inject = ['$scope', 'contentBuffer'];
  constructor($scope, contentBuffer) {
    const data = contentBuffer.read('popup');
    $scope.balance = data.balance;

    if(data.max_pending_withdrawal){
      $scope.withdrawalData = data.max_pending_withdrawal;
    }
  }
}

app.component(componentName, {
  controller: balanceAlertPopup,
  template: app.getTU(componentName)
});


