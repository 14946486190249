export const transcludeResolverEvents = 'transcludeResolverEvents';
import { game } from '../domain/game.js';
import { tournament } from '../domain/tournament.js';

class Events {
  static $inject = ['$q', 'tournament', 'game'];

  constructor($q, tournament, game) {
    this.$q = $q;
    this.game = game;
    this.tournament = tournament;
  }

  checkOnExist(config) {
    const { alias, params } = config;
    Object.assign(params, { cache: true });

    switch (alias) {
      case 'tournament':
        return this.tournament.item(params);
      case 'game':
        return this.game.item(params);
      case 'tournamentActive':
        return (() => {
          const defer = this.$q.defer();
          this.tournament
            .item(params)
            .then((data) => {
              if (data.result.status === 1) {
                defer.resolve(data);
              } else {
                defer.reject(data);
              }
            })
            .catch(() => {
              defer.reject();
            });
          return defer.promise;
        })();
    }
  }
}

app.service(transcludeResolverEvents, Events);
