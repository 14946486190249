import '../../service/rx/system$.js'
import '../../service/domain/user.js'
import '../../service/rx/splash$.js'
import '../../service/configs/config.js'
import { Subject, combineLatest} from 'rxjs';
import { filter } from 'rxjs/operators';
import '../../service/domain/zendesk.js';

(function () {
  const name = 'lindaZendesk';
  controller.$inject = ['system$', 'user', 'splash$', 'config', 'zendesk'];

  function controller(_system$, _user, _splash$, _config, _zendesk) {
    const chatActivation$ = new Subject();
    const openChatEvent$ = _system$.pipe(filter((message) => message.action === 'open chat'));
    combineLatest(chatActivation$, openChatEvent$).subscribe(([openChatCb]) => openChatCb());
    let chatIsOpen = false;
    const link = () => {
      function activateChat() {
        sendWidgetMeta();
        zE.activate();
        chatIsOpen = true;
        identify(_user);
        if (_user.status) {
          _zendesk.sendInfoMessage();
        }
      }

      const scriptZendesk = document.createElement('script');
      scriptZendesk.id = 'ze-snippet';
      scriptZendesk.src = `${$_CDN.zendesk.sdk}?key=${$_CDN.zendesk.key}`;

      const target = document.scripts[document.scripts.length - 1];
      target.parentElement.insertBefore(scriptZendesk, target.nextSibling);

      const config = {
        userName: _user.status ? _user.profile.login : null,
        userIsVip: false,
        userLanguage: $_DICT.zendesk?.langReplacements?.[$_CONFIG.currentLang] || $_CONFIG.currentLang,
      };

      let chatTags;

      scriptZendesk.onload = () => {
        initZendeskChat(config.userLanguage, config.userName, config.userIsVip);
      };

      function identify({ profile, status }) {
        if (!status) return;

        zE('webWidget', 'identify', {
          name: `${profile.profile.name} ${profile.profile.surname}`,
          email: profile.email,
        });
      }

      function initZendeskChat(userLanguage = 'en', userName, userIsVip) {
        const code = $_CDN.zendesk.code;
        const license = $_CDN.zendesk.license;
        const brand = $_CDN.zendesk.brand;
        const theme = $_CDN.zendesk.theme;
        const analytics = $_CDN.zendesk.analytics ? $_CDN.zendesk.analytics : false;

        let userId = userName ? `${_user.profile.prefix}${userName}` : userName;
        let userVipLabel = _user.profile.level_v2_label;

        const department = getDepartment();

        const webWidgetSettings = {
          webWidget: {
            color: { theme },
            chat: {
              departments: { select: department, enabled: [] },
            },
          },
          analytics,
        };
        chatTags = [userLanguage, license, brand, code];

        if (userId) {
          chatTags.push(userId);
        }
        if (userId && userVipLabel) {
          chatTags.push(userVipLabel);
        }
        if (userId && userIsVip) {
          chatTags.push('vip');
        }
        zE('webWidget', 'hide');

        window.zESettings = webWidgetSettings;

        zE('webWidget:on', 'chat:connected', function () {
          const availableDepartments = [];
          zE('webWidget', 'updateSettings', {
            webWidget: {
              chat: {
                departments: {
                  select: department,
                  enabled: availableDepartments,
                },
              },
            },
          });
          webWidgetSettings.webWidget.chat.enabled = availableDepartments;
          chatActivation$.next(activateChat);
        });

        function getDepartment() {
          const language = userLanguage || 'en';

          return `${license} ${userIsVip ? `+ VIP ` : ''}+ ${language.toUpperCase()}`;
        }
        _system$
          .pipe(filter((message) => message.action === 'login'))
          .subscribe(() => {
            if (chatIsOpen) {
              _zendesk.sendInfoMessage();
            }
            userId = `${_user.profile.prefix}${userName}`;
            userVipLabel = _user.profile.level_v2_label;
            userIsVip = false;
            if (userIsVip) chatTags.push('vip');
            if (userVipLabel) {
              zE('webWidget', 'chat:addTags', [userId, userVipLabel]);
              return;
            }

            zE('webWidget', 'chat:addTags', [userId]);
          });

        _system$
          .pipe(filter((message) => message.action === 'logout'))
          .subscribe(() => {
            zE('webWidget', 'hide');
            zE('webWidget', 'clear');
            chatIsOpen = false;
          });
      }

      function sendWidgetMeta() {
        zE('webWidget', 'setLocale', config.userLanguage);
        zE('webWidget', 'chat:addTags', [chatTags]);
      }
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();
