import '../service/domain/content-buffer.js';
import '../service/rx/content-buffer$.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  controller.$inject = ['contentBuffer', 'contentBuffer$'];

  function controller(_contentBuffer, _contentBuffer$) {
    function link(scope, element, attrs) {
      const variable = attrs.getModalContentToScope || 'config';

      scope[variable] = _contentBuffer.read('modal');

      _contentBuffer$
        .pipe(filter((data) => data.name === 'modal'))
        .subscribe((data) => {
          scope[variable] = _contentBuffer.read('modal');
        });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive('getModalContentToScope', controller);
})();
