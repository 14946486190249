(function () {
  'use strict';
  const directive = {
    name: 'scrollToCurrentDate',
  };

  function controller() {
    function link(scope, element) {
      var scrollToCurrentDate = setTimeout(() => {
        element[0].querySelector('.today').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        clearTimeout(scrollToCurrentDate);
      }, 2000);
    }

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
