import '../../../service/gamification/sport-cards/card.js';
import '../../../service/rx/sport-cards$';
(function () {
  'use strict';

  controller.$inject = ['$scope', 'card', 'sportCards$'];

  function controller($scope, _card, _sportCards$) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.category = '';

    let subscription;

    function getCard(options) {
      $scope.preloader = true;
      _card
        .list(options)
        .then((a) => {
          $scope.collection = a.result;
          $scope.card = options.alias ? a.result[0] : null;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    this.$onChanges = function () {
      const config = {};

      $scope.category = this.category;

      config.team = this.team;
      config.squad = this.squad;
      config.alias = this.alias;
      config.category = this.category;
      config.type = this.type;
      config.status = this.status;

      getCard(config);
    };
  }

  const bindings = {
    team: '<',
    squad: '<',
    alias: '<',
    category: '<',
    type: '<',
    status: '<',
  };

  new Controller('sportCardList', controller, bindings);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSportCardList${i}`, 'sportCardList');
  });
})();
