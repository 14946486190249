import '../../../service/domain/sport.js';
import '../../../service/domain/popup.js';
import '../../../service/configs/config.js';
(function () {
  'use strict';

  const component = { name: 'lindaSportField' };

  controller.$inject = ['$scope', 'sport', 'popup', 'config'];

  function controller($scope, _sport, _popup, _config) {
    let BIA = {};
    let loaded = false;

    this.$onChanges = () => {
      if (loaded) {
        BIA.setParam('page', this.vm);
      } else {
        launch(this.vm);
      }
    };

    function launch(vm) {
      _sport.launch({ code: 'sport' }).then(
        (answer) => {
          let opt = answer.result.launch_options;

          opt.page = vm;
          opt.showLoginBoxCallback = showLoginBoxCallback;
          opt.loadCallback = loadCallback;
          opt.mobile = _config.device.mobile;
          opt.fixedBottom = 55;

          const ident = setInterval(function () {
            if (typeof BetinactionAPI === 'function') {
              BIA = new BetinactionAPI('#BIAcc', opt);
              loaded = true;
              clearInterval(ident);
            }

            if (typeof AltenarSportsbook === 'function') {
              BIA = new AltenarSportsbook('#BIAcc', opt);
              loaded = true;
              clearInterval(ident);
            }
          }, 250);
        },
        (answer) => {}
      );
    }

    function loadCallback() {}

    function showLoginBoxCallback() {
      _popup.open({ name: 'login' });
      //--$scope.$apply();
    }
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      vm: '<',
    },
  });
})();
