import '../../../service/domain/game.js'
(function () {
  controller.$inject = ['$scope', 'game'];

  function controller($scope, _game) {
    $scope.collection = [];
    $scope.total_count = 0;
    $scope.preloader = false;

    this.$onChanges = () => {
      $scope.preloader = true;

      const count = this.count || 24;

      _game
        .recentlyPlayed({ count })
        .then((a) => {
          $scope.collection = a.result.filter((item) => item.alias !== 'sport');
          $scope.total_count = a.total_count;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  new Controller('recentlyPlayedList', controller, { count: '<' });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanRecentlyPlayedList${i}`, 'recentlyPlayedList');
  });
})();
