(function () {
  'use strict';

  const directive = { name: 'scrollToTopOnTap' };

  controller.$inject = ['$document'];

  function controller($document) {
    function link(scope, element, attrs) {
      element.on('tap', () => {
        const targetElement = $document[0].querySelector(attrs[directive.name]);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
