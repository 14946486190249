
































module.exports = {
  "ar": () => import("./ar.js"),
  "bg": () => import("./bg.js"),
  "ca": () => import("./ca.js"),
  "cs": () => import("./cs.js"),
  "da": () => import("./da.js"),
  "de": () => import("./de.js"),
  "el": () => import("./el.js"),
  "en": () => import("./en.js"),
  "es": () => import("./es.js"),
  "eu": () => import("./eu.js"),
  "fi": () => import("./fi.js"),
  "fr": () => import("./fr.js"),
  "hr": () => import("./hr.js"),
  "hu": () => import("./hu.js"),
  "id": () => import("./id.js"),
  "it": () => import("./it.js"),
  "ja": () => import("./ja.js"),
  "ko": () => import("./ko.js"),
  "nb": () => import("./nb.js"),
  "nl": () => import("./nl.js"),
  "pl": () => import("./pl.js"),
  "pt-BR": () => import("./pt-BR.js"),
  "pt": () => import("./pt.js"),
  "ro": () => import("./ro.js"),
  "ru": () => import("./ru.js"),
  "si": () => import("./si.js"),
  "sk": () => import("./sk.js"),
  "sl": () => import("./sl.js"),
  "sv": () => import("./sv.js"),
  "th": () => import("./th.js"),
  "tr": () => import("./tr.js"),
  "uk": () => import("./uk.js"),
  "zh": () => import("./zh.js")
}