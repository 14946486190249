(function () {
  'use strict';

  const directive = { name: 'maskPtPostcode' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs, ngModel) => {
      const inputMask = new IMask(element[0], {
        mask: '0000-000',
        lazy: false,
        placeholderChar: 'X',
      });

      inputMask.on('accept', () => {
        ngModel.$setViewValue(parseInt(inputMask.unmaskedValue, 10) || '');
      });

      ngModel.$formatters.push((modelValue) => {
        if (!modelValue) {
          return '';
        }
        inputMask.value = modelValue;
        return modelValue;
      });

      ngModel.$parsers.push(() => {
        return inputMask.value;
      });

      ngModel.$validators['postcode'] = (modelValue) => {
        return !modelValue || inputMask.masked.isComplete;
      };
    };

    return { restrict: 'A', require: '?ngModel', link };
  }

  app.directive(directive.name, controller);
})();
