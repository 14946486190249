import '../../service/domain/http.js'
import '../../service/domain/user.js'
import '../../service/rx/user$.js'
import { Subject } from 'rxjs';

(function () {
  controller.$inject = [];

  function controller() {
    return new Subject();
  }

  app.factory('verificationInfo$', controller);
})();

(function () {
  controller.$inject = ['http', '$q', 'user', 'user$', 'verificationInfo$'];

  function controller(_http, $q, _user, _user$, _verificationInfo$) {
    let verificationStatus = _user.profile.verificationStatus;

    _user$.subscribe((user) => {
      if (user.profile.verificationStatus !== verificationStatus) {
        verificationStatus = user.profile.verificationStatus;
        _verificationInfo$.next({ action: 'verificationStatusUpdated' });
      }
    });

    return {
      getDocuments: {
        list() {
          return this.getData('documentVerificationRequestedDocumentsList');
        },

        collection() {
          return this.getData('documentVerificationDocuments');
        },

        getData(alias) {
          const defer = $q.defer();
          const config = {
            meta: { alias },
          };

          _http.get(alias, config).then(
            (answer) => {
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },

      setDocuments: {
        upload: {
          options(requestedDocumentId) {
            const data = {
              requestedDocumentId,
            };
            return this.setData('documentVerificationUploadOptions', data);
          },

          file(files, documentsId) {
            const data = files;
            return this.setData('documentVerificationUploadFile', data, documentsId);
          },

          done(data) {
            return this.setData('documentVerificationUploadDone', data);
          },

          setData(alias, data, documentsId) {
            const defer = $q.defer();

            if (documentsId) {
              alias = {
                name: alias,
                param: documentsId,
              };
              const formData = new FormData();
              for (let i = 0; i < data.length; i++) {
                if (Object.keys(data[i].errors).length === 0) {
                  formData.append(data[i].name, data[i]);
                }
              }
              data = formData;
            }

            const config = {
              meta: { alias },
            };

            if (documentsId) {
              config.headers = { 'Content-Type': undefined };
            }

            _http
              .post(alias, data, config)
              .then(
                (answer) => {
                  defer.resolve(answer.data);
                },
                (answer) => {
                  defer.reject(answer.data);
                }
              )
              .catch((error) => {
                defer.reject(error);
              });
            return defer.promise;
          },
        },
      },
    };
  }

  app.factory('documentVerification', controller);
})();
