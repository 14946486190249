class OgUrlService {
  static $inject = ['$document', '$location'];

  constructor($document, $location) {
    this.$document = $document[0];
    this.$location = $location;
    this.ogUrlRef = this.$document.head.querySelector('meta[property="og:url"]');
    this.targetNode = this.$document.querySelector('meta[property="og:description"]');
    if (this.ogUrlRef === null) {
      this.create();
    }
  }
  create() {
    this.ogUrlRef = this.$document.createElement('meta');
    this.ogUrlRef.setAttribute('property', 'og:url');
    return this.targetNode.after(this.ogUrlRef);
  }

  update() {
    this.ogUrlRef.setAttribute('content', `${$_CDN.canonical}${this.$location.path()}`);
  }
}

app.service('ogUrlService', OgUrlService);
