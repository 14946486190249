import '../service/domain/seo.js';
import { filter } from 'rxjs/operators'

(function () {
  'use strict';

  const directive = { name: 'animateTitleLeave' };

  controller.$inject = ['$translate', 'seo'];

  function controller($translate, _seo) {
    async function link(scope, element, attrs) {
      let listener;
      let interval = null;
      let originalTitle = document.title;
      const { titleKey = '' } = scope.$eval(attrs[directive.name]) || {};
      const titleEmoji = await $translate(titleKey);
      const inactiveTitle = titleEmoji.replace(/(&\#\d+\S)/g, replaceEmoji);
      const inactiveTitleArr = inactiveTitle.split('/');

      function replaceEmoji(match, offset, string) {
        return String.fromCodePoint(match.replace(/[^\d;]/g, ''));
      }

      function changeTitle() {
        if (!document.hidden) {
          clearInterval(interval);
          document.title = originalTitle;
          return;
        }

        let index = 0;
        interval = setInterval(() => {
          document.title = inactiveTitleArr[index];
          if (++index === inactiveTitleArr.length) {
            index = 0;
          }
        }, 300);
      }

      _seo.meta$
        .pipe(filter((data) => data.event === 'title updated'))
        .subscribe((data) => {
          originalTitle = data.title || '';
          if (!listener) {
            listener = addListener();
          }
        });

      function addListener() {
        return document.addEventListener('visibilitychange', changeTitle);
      }

      if (originalTitle) {
        listener = addListener();
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
