import '../../service/mobile-app/firebase-plugin.js';
import '../../service/domain/popup.js';
import '../../service/domain/state.js';
import '../../service/configs/config.js';
(function () {
  'use strict';

  const directive = { name: 'firebaseNotifications' };

  controller.$inject = ['firebasePlugin', '$state', 'popup', 'state', 'config'];

  function controller(_firebasePlugin, $state, _popup, _statem, _config) {
    const link = (scope, element, attrs) => {
      _firebasePlugin.hasPermission().then((answer) => {
        _firebasePlugin.grantPermission();
      });

      _firebasePlugin.onNotificationOpen((data) => {
        let params = JSON.parse(data.params);
        switch (data.type) {
          case 'state':
            if (!data.tap) break;
            _popup.close();
            $state.go(data.state, params);
            break;
          case 'popup':
            if (!data.tap) break;
            _popup.open({ name: data.popup, content: params });
            break;
          case 'alias':
            if (!data.tap) break;
            _popup.close();
            _state.goto(data.alias);
            break;
          default:
            break;
        }
      });
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
