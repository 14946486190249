export const sentry = 'sentry';

class Sentry {
  static $inject = ['$window'];

  constructor($window) {
    this.$window = $window;
  }
  sendMessage(message, data) {
    this.$window.Sentry?.captureMessage(message, data);
  }
  sendException(message, data) {
    this.$window.Sentry?.captureException(new Error(message), data);
  }
}

app.service(sentry, Sentry);
