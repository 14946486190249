(function () {
  'use strict';

  const directive = { name: 'mDatepicker' };

  controller.$inject = [];

  function controller() {
    const link = (scope, element, attrs, ctrl) => {
      const params = scope.$eval(attrs[directive.name]) || {};

      const dateLimitation = {
        min: attrs.min ? new Date(attrs.min) : null,
        max: attrs.max ? new Date(attrs.max) : null,
      };

      if (params.minAge) {
        dateLimitation.max = new Date().removeYears(params.minAge);
        element[0].setAttribute('max', dateLimitation.max.toISODate());
      }

      if (params.maxAge) {
        dateLimitation.min = new Date().removeYears(params.maxAge);
        element[0].setAttribute('min', dateLimitation.min.toISODate());
      }

      ctrl.$parsers.push((d) => {
        const userBirthdayAsDate = new Date(d);

        if (
          (dateLimitation.max && userBirthdayAsDate > dateLimitation.max) ||
          (dateLimitation.min && userBirthdayAsDate < dateLimitation.min)
        ) {
          ctrl.$setViewValue('', false);
          return null;
        }

        return element[0].value;
      });

      ctrl.$formatters.push((d) => new Date(d));
    };

    return {
      require: 'ngModel',
      link,
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(directive.name, controller);
})();
