(function () {
  'use strict';

  app.factory('birthdayDatepicker', controller);

  function controller() {
    const calculateAdultAge = (age) => {
      const timestampAdultAge = new Date().setFullYear(new Date().getFullYear() - age);
      return new Date(timestampAdultAge);
    };

    const minDateValue = '1900-01-01';
    const maxDateValue = calculateAdultAge(18).toISOString().split('T')[0];

    return {
      minDateValue,
      maxDateValue,
    };
  }
})();
