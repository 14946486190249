(function () {
  'use strict';

  const directive = { name: 'scrollIntoViewOnEvent' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs[directive.name]);
      const scrollToElementName = document.querySelector(config.element);
      const scrollIntoViewOptions = config.scrollIntoViewOptions || { behavior: 'smooth' };
      const event = config.event || 'click';
      if (!scrollToElementName) {
        return;
      }

      element.on(event, () => scrollToElementName.scrollIntoView(scrollIntoViewOptions));
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
