(function () {
  'use strict';

  const directive = { name: 'deferredRender' };

  controller.$inject = ['$timeout', '$compile'];

  function controller($timeout, $compile) {
    const link = (scope, element, attrs) => {
      const skeleton = angular.element(attrs[directive.name]);
      scope.isRendered = false;

      $timeout(function () {
        scope.$apply(function () {
          scope.isRendered = true;
        });
        skeleton.remove();
      }, 0);
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
