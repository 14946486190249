import { BehaviorSubject } from 'rxjs';

(function () {
  'use strict';

  controller.$inject = ['$transitions'];

  function controller($transitions) {
    let activeTab = '';
    const tabOpened$ = new BehaviorSubject(activeTab);

    function getActiveTab() {
      return activeTab;
    }

    function setActiveTab(tabName) {
      activeTab = tabName;
      tabOpened$.next(activeTab);
    }

    function resetActiveTab() {
      activeTab = '';
      tabOpened$.next(activeTab);
    }

    $transitions.onSuccess({}, function (transition) {
      if (transition.from() !== transition.to()) {
        resetActiveTab();
      }
    });

    return {
      tabOpened$,
      getActiveTab,
      setActiveTab,
      resetActiveTab,
    };
  }

  app.factory('gamePageShop', controller);
})();
