import '../../service/domain/http.js';
import { Subject } from 'rxjs';
(function () {
  'use strict';

  controller.$inject = ['$q', 'http'];

  function controller($q, _http) {
    const cancelWithdrawal$ = new Subject();

    return {
      cancelWithdrawal$,
      cancel(options) {
        const defer = $q.defer(),
          alias = 'pWCancel',
          data = options,
          config = {
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },
    };
  }

  app.factory('withdraw', controller);
})();
