import angular from 'angular';
import '../../service/configs/config.js';
import '../../service/domain/popup.js';
import '../../service/domain/remembered.js';
import { Subject } from 'rxjs';
/**
 *
 *
 *
 *
 */

(function () {
  'use strict';

  controller.$inject = ['$document', 'config', '$state', 'popup', 'remembered'];

  function controller($document, _config, $state, _popup, _remembered) {
    const readyToRedirectAfterAuth$ = new Subject();

    return {
      readyToRedirectAfterAuth$,

      previous: {
        name: null,
        params: null,
      },
      history: [],

      get(alias) {
        return _config.aliases[alias] ? _config.aliases[alias] : null;
      },

      goback() {
        const self = this;
        self.history.pop();
        $state.go(self.history[self.history.length - 1], self.previous.params);
      },

      goToAuthorization() {
        const authAlias = _remembered.login ? 'login' : 'registration';
        this.goto(authAlias);
      },

      goto(alias, params) {
        const aliasData = structuredClone(_config.aliases[alias]);
        if (!aliasData) {
          console.error(`!Alias ${alias}`);
          return;
        }
        if(aliasData.name === 'bankid'){
          const bankIdElement = $document[0].querySelector("bsc-auth-bank-id");
          bankIdElement.openDialog();
          return;
        }
        if (!aliasData.page) {
          const popupContent = params && Object.keys(params).length ? params : aliasData.content;
          _popup.open({
            name: aliasData.name,
            content: popupContent,
          });
          return;
        }
        aliasData.params = params || aliasData.params || null;
        if (angular.isObject(aliasData.params) && !aliasData.params.lang) {
          aliasData.params.lang = _config.currentLang;
        }
        _popup.close();
        $state.go(aliasData.name, aliasData.params);
      },
    };
  }

  app.factory('state', controller);
})();
