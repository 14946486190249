import '../../../service/domain/account.js';
import '../../../service/domain/state.js';
import '../../../service/domain/popup.js';
import '../../../service/domain/modal.js';
import '../../../service/helper.js';
(function () {
  'use strict';

  const component = { name: 'lindaSelfcontrolTimeout' };

  controller.$inject = ['$scope', 'account', 'state', 'popup', 'modal', 'helper'];

  function controller($scope, _account, _state, _popup, _modal, _helper) {
    $scope.preloader = false;

    $scope.formData = {
      frozen_to: '224',
    };

    $scope.submit = _helper.form.validator((form) => {
      _modal.open('selfexclude').then((a) => {
        $scope.preloader = true;
        _account
          .exclude($scope.formData)
          .then(() => {
            _popup.close();
            _state.goto('home');
          })
          .catch(() => {})
          .finally(() => {
            $scope.preloader = false;
          });
      });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
