import '../seo/utils/seo-ranking.js';

class CanonicalService {
  static $inject = ['$document', '$location', 'seoRanking'];

  constructor($document, $location, seoRanking) {
    this.$document = $document[0];
    this.$location = $location;
    this.seoRanking = seoRanking;
    this.targetNode = this.$document.querySelector('meta[name="description"]');
  }

  init() {
    let canonicalNode = this.$document.head.querySelector('link[rel="canonical"]');
    if (canonicalNode === null) {
      canonicalNode = this.$document.createElement('link');
      canonicalNode.setAttribute('rel', 'canonical');
    }
    canonicalNode.setAttribute('href', `${$_CDN.canonical}${this.$location.path()}`);
    return canonicalNode;
  }

  update() {
    const isPageRanged = this.seoRanking.check();
    const canonical = this.init();
    if (!isPageRanged) {
      if (canonical.parentNode) canonical.parentNode.removeChild(canonical);
      return;
    }
    this.targetNode.after(canonical);
  }
}

app.service('canonicalService', CanonicalService);
