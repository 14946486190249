import '../../service/domain/account.js';
import '../../service/domain/popup.js';
import '../../service/domain/modal.js';
import '../../service/analytics/analytics.js';
(function () {
  'use strict';

  const component = { name: 'lindaLoginPopup' };

  controller.$inject = ['$scope', 'account', 'popup', 'modal', 'analytics'];

  function controller($scope, _account, _popup, _modal, analytics) {
    $scope.preloader = false;
    $scope.mode = null;
    $scope.credentials = {};

    this.$onChanges = function () {
      $scope.mode = this.mode;
    };

    $scope.submit = function (form) {
      if (form.$valid) {
        $scope.preloader = true;

        if ($scope.mode) {
          $scope.mode === 'login' ? delete $scope.credentials.email : delete $scope.credentials.login;
        }

        _account.login($scope.credentials).then(
          (answer) => {
            _popup.close();

            if (typeof _popup.cb === 'function') {
              _popup.cb();
              _popup.cb = null;
            }


                        $scope.preloader = false;
                    },
                    answer => {

                        $scope.preloader = false;
                    }
                );

            }else{

                angular.forEach( form.$error, function( type ){
                    angular.forEach( type, function( field ){
                        field.$setTouched();
                    });
                });

            }

        }

    }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      mode: '<',
    },
  });
})();
