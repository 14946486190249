(function () {
  'use strict';

  controller.$inject = [];

  function controller() {

    return {
      customValidators(scope, element, attrs, ngModel) {

        ngModel.$validators.required = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return !!value;
        };

        ngModel.$validators.minlength = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          const filteredValue = value?.trim();
          return filteredValue ? filteredValue.length >= 2 : true;
        };

        ngModel.$validators.maxlength = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? value.length <= 50 : true;
        };

        ngModel.$validators.pattern = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? /^[a-zA-ZÀ-ÖØ-öø-ſȀ-ȳḀ-Ợ0-9\-\s]+$/.test(value) : true;
        };
      },
    };
  }

  app.factory('zipcode', controller);
})();
